import axios from "axios";
import { formatISO } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "../Enquires/ExistingEnquires/datatable.css";

export default function Statement() {
  // Start of datatable props
  const [statement, setStatement] = useState([]);
  // End of datatable props

  // Start of Search filter Value
  const [searchPrincipal, setSearchPrincipal] = useState("");
  const [dateStart, setDateStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [dateEnd, setDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );
  const buttonSearch = useRef(null);
  // End of Search filter Value

  // Start of Dropdown Options
  const [principalDropdown, setPrincipalDropdown] = useState([]);

  useEffect(() => {
    loadPrincipal();
  }, []);

  const loadPrincipal = async (e) => {
    const result_Principal = await axios.get(
      `${process.env.REACT_APP_API_URL}/principal/get_all_principal`
    );
    // Start of sort a to b after pushing "ALL"
    result_Principal.data.push({ code: " ALL" });
    result_Principal.data = result_Principal.data.sort(function (a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    // End of sort a to b after pushing "ALL"
    setPrincipalDropdown(result_Principal.data);
  };
  // End of Dropdown Options

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Start of Load Data to Datatable with filter search
  useEffect(() => {
    loadReport();
    // eslint-disable-next-line
  }, []);

  const loadReport = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/enquires/getStatement`, {
        params: {
          principal: searchPrincipal,
          dateStart: formatISO(dateStart),
          dateEnd: formatISO(dateEnd),
        },
      })
      .then((result) => {
        setStatement(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // End of Load Data to Datatable

  // Start of funtion on key press enter and reset search function
  const handleKeyPress = useCallback((event) => {
    if (event.key === "Enter") {
      buttonSearch.current.click();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const resetSearch = () => {
    window.location.reload();
  };
  // End of funtion on key press enter and reset search function

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Statement</h2>
          </div>
          <div className="card-text text-start">
            <div className="overflow-auto custom-background p-3">
              {/* Start of 1st Row */}
              <div className="container ms-0 ps-0">
                <div className="row row-cols-auto">
                  <div className="col">
                    <div className="p-inputgroup me-5">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-users"></i>
                      </span>
                      <Dropdown
                        value={searchPrincipal}
                        options={principalDropdown}
                        onChange={(event) =>
                          setSearchPrincipal(event.target.value)
                        }
                        optionLabel="code"
                        optionValue="code"
                        placeholder="ALL"
                        className="custom_padding"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* End of 1st Row */}
              {/* Start of 2nd Row */}
              <div className="container ms-0 ps-0">
                <div className="row row-cols-auto mt-3">
                  <div className="d-flex col-12">
                    <Calendar
                      id="icon"
                      showIcon
                      iconPos="left"
                      showButtonBar
                      className="width_12_em"
                      inputClassName="padding-left"
                      clearButtonClassName="d-none"
                      dateFormat="dd/mm/yy"
                      name="dateStart"
                      value={dateStart}
                      onChange={(e) => {
                        setDateStart(e.value);
                      }}
                    />
                    <span className="d-flex align-self-center px-2">to</span>
                    <Calendar
                      id="icon"
                      showIcon
                      iconPos="left"
                      showButtonBar
                      className="width_12_em"
                      inputClassName="padding-left"
                      clearButtonClassName="d-none"
                      dateFormat="dd/mm/yy"
                      name="dateEnd"
                      value={dateEnd}
                      onChange={(e) => setDateEnd(e.value)}
                    />
                  </div>
                </div>
              </div>
              {/* End of 2nd Row */}
              {/* Start of 3rd Row */}
              <div className="table-header mt-3">
                <div className="table-header-2">
                  <div className="container ms-0 ps-0">
                    <Button
                      label="Search"
                      icon="pi pi-search-plus"
                      className="search_clear_button me-4"
                      onClick={loadReport}
                      ref={buttonSearch}
                    />
                    <Button
                      label="Clear"
                      icon="pi pi-replay"
                      className="search_clear_button mt-2"
                      onClick={resetSearch}
                    />
                  </div>
                </div>
              </div>
              {/* End of 3rd Row */}
            </div>
            <table className="commission-table">
              <tr className="table-header-row">
                <th colSpan="2">
                  <b>Total Commission - Motor</b>
                </th>
                <td>{numberWithCommas(statement.total_motor_commission)}</td>
              </tr>
              <tr className="table-header-row">
                <th colSpan="2">
                  <b>Total Commission - Non-Motor</b>
                </th>
                <td>{numberWithCommas(statement.total_non_motor_commission)}</td>
              </tr>
              <tr className="table-header-row">
                <th colSpan="2">
                  <b>Total Revenue - Supporting Services</b>
                </th>
                <td>{numberWithCommas(statement.total_sup_svc_revenue)}</td>
              </tr>
              <tr className="table-header-row">
                <th colSpan="2" className="text-end">
                  <b>Total Income:</b>
                </th>
                <td>
                  <b>{numberWithCommas(statement.total_income)}</b>
                </td>
              </tr>
              <tr className="table-header-row">
                <th colSpan="2">
                  <b>Partner - Commission*</b>
                </th>
                <td>{numberWithCommas(statement.total_partner_commission)}</td>
              </tr>
              <tr className="table-header-row">
                <th colSpan="2">
                  <b>Partner - Discount*</b>
                </th>
                <td>{numberWithCommas(statement.total_partner_discount)}</td>
              </tr>
              <tr className="table-header-row">
                <th colSpan="2" className="text-end">
                  <b>Net Revenue:</b>
                </th>
                <td>
                  <b>{numberWithCommas(statement.total_net_revenue)}</b>
                </td>
              </tr>
            </table>
            * - These fields may not be accurate when used in conjunction with a
            specific principal, but will always accurate when the "ALL"
            principals are shown
          </div>
        </div>
      </div>
    </section>
  );
}
