import { Checkbox } from "primereact/checkbox";
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import ClientVehicleInfoContext from "../../../../../../context/ClientVehicleProvider";
import "../../css/custom.css";
export default function ModalBenefits(props) {
  const modalState = props.toggle;
  const action = props.action;
  const [llopChecked, setLlopChecked] = useState(false);
  const [aonChecked, setAonChecked] = useState(false);
  const [adCompanyChecked, setAdCompanyChecked] = useState(false);
  const [adIndvChecked, setAdIndvChecked] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const {
    premium_Extra_Benefits_Windscreen_Cover_Value,
    premium_Extra_Benefits_Windscreen_Cover_Total,
    premium_Extra_Benefits_Legal_Liability_to_Passengers_Value,
    premium_Extra_Benefits_Legal_Liability_to_Passengers_Total,
    premium_Extra_Benefits_Act_of_Negligence_Total,
    premium_Extra_Benefits_Strike_Commotion_Cover_Total,
    premium_Extra_Benefits_All_Drivers_Total,
    premium_Extra_Benefits_All_Drivers_Inv_Total,
    premium_Extra_Benefits_Convulsion_of_Nature_Total,
    premium_Extra_Benefits_Additional_Driver_Named,
    premium_Extra_Benefits_Additional_Driver_Total,
    premium_Extra_Benefits_RadioCasette_Value,
    premium_Extra_Benefits_RadioCasette_Total,
    premium_Extra_Benefits_Insurance_Protection_Total,
    premium_Extra_Benefits_Inclusion_of_Damage_Total,
    premium_Extra_Benefits_Inclusion_of_Accidental_Total,
    premium_Extra_Benefits_Extension_of_Cover_Total,
    premium_Extra_Benefits_Generali_MCPA_Total,
    premium_Extra_Benefits_Unlimited_Towing_Total,
    premium_Extra_Benefits_Other_1_Value,
    premium_Extra_Benefits_Other_1_Total,
    premium_Extra_Benefits_Other_2_Value,
    premium_Extra_Benefits_Other_2_Total,
    premium_Extra_Benefits_Other_3_Value,
    premium_Extra_Benefits_Other_3_Total,
    premium_Extra_Benefits_Total,
    third_party_premium,
  } = clientVehicleInfo;

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname.includes("view") &&
      clientVehicleInfo.status !== "Draft"
    ) {
      var all = document.querySelectorAll(".form-control");
      for (var i = 0, len = all.length; i < len; i++) {
        all[i].disabled = true;
      }
      setViewOnly(true);
    }
    // eslint-disable-next-line
  }, [modalState]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      premium_Extra_Benefits_Windscreen_Cover_Total: (
        (parseFloat(premium_Extra_Benefits_Windscreen_Cover_Value) * 15) /
        100
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [premium_Extra_Benefits_Windscreen_Cover_Value]);

  useEffect(() => {
    if (premium_Extra_Benefits_Legal_Liability_to_Passengers_Value > 5) {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        premium_Extra_Benefits_Legal_Liability_to_Passengers_Total: (
          parseFloat(third_party_premium) +
          (premium_Extra_Benefits_Legal_Liability_to_Passengers_Value - 5) * 10
        ).toFixed(2),
      });
    }
    // eslint-disable-next-line
  }, [premium_Extra_Benefits_Legal_Liability_to_Passengers_Value]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      premium_Extra_Benefits_Total: (
        parseFloat(premium_Extra_Benefits_Windscreen_Cover_Total) +
        parseFloat(premium_Extra_Benefits_Legal_Liability_to_Passengers_Total) +
        parseFloat(premium_Extra_Benefits_Act_of_Negligence_Total) +
        parseFloat(premium_Extra_Benefits_Strike_Commotion_Cover_Total) +
        parseFloat(premium_Extra_Benefits_All_Drivers_Total) +
        parseFloat(premium_Extra_Benefits_All_Drivers_Inv_Total) +
        parseFloat(premium_Extra_Benefits_Convulsion_of_Nature_Total) +
        parseFloat(premium_Extra_Benefits_Additional_Driver_Total) +
        parseFloat(premium_Extra_Benefits_RadioCasette_Total) +
        parseFloat(premium_Extra_Benefits_Insurance_Protection_Total) +
        parseFloat(premium_Extra_Benefits_Inclusion_of_Damage_Total) +
        parseFloat(premium_Extra_Benefits_Inclusion_of_Accidental_Total) +
        parseFloat(premium_Extra_Benefits_Extension_of_Cover_Total) +
        parseFloat(premium_Extra_Benefits_Generali_MCPA_Total) +
        parseFloat(premium_Extra_Benefits_Unlimited_Towing_Total) +
        parseFloat(premium_Extra_Benefits_Other_1_Total) +
        parseFloat(premium_Extra_Benefits_Other_2_Total) +
        parseFloat(premium_Extra_Benefits_Other_3_Total)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [
    premium_Extra_Benefits_Windscreen_Cover_Total,
    premium_Extra_Benefits_Legal_Liability_to_Passengers_Total,
    premium_Extra_Benefits_Act_of_Negligence_Total,
    premium_Extra_Benefits_Strike_Commotion_Cover_Total,
    premium_Extra_Benefits_All_Drivers_Total,
    premium_Extra_Benefits_All_Drivers_Inv_Total,
    premium_Extra_Benefits_Convulsion_of_Nature_Total,
    premium_Extra_Benefits_Additional_Driver_Total,
    premium_Extra_Benefits_RadioCasette_Total,
    premium_Extra_Benefits_Insurance_Protection_Total,
    premium_Extra_Benefits_Inclusion_of_Damage_Total,
    premium_Extra_Benefits_Inclusion_of_Accidental_Total,
    premium_Extra_Benefits_Extension_of_Cover_Total,
    premium_Extra_Benefits_Generali_MCPA_Total,
    premium_Extra_Benefits_Unlimited_Towing_Total,
    premium_Extra_Benefits_Other_1_Total,
    premium_Extra_Benefits_Other_2_Total,
    premium_Extra_Benefits_Other_3_Total,
  ]);

  return (
    <Modal show={modalState} onHide={action} dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <Modal.Title>Extra Benefits</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table>
          <tbody>
            <tr>
              <td>Windscreen Cover</td>
              <td className="text-end">Value RM</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_8_em"
                  name="premium_Extra_Benefits_Windscreen_Cover_Value"
                  value={premium_Extra_Benefits_Windscreen_Cover_Value}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Windscreen_Cover_Total"
                  value={premium_Extra_Benefits_Windscreen_Cover_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox
                  inputId="cb1"
                  onChange={(e) => {
                    setLlopChecked(e.checked);
                    if (e.checked === true) {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premium_Extra_Benefits_Legal_Liability_to_Passengers_Value: 5,
                        premium_Extra_Benefits_Legal_Liability_to_Passengers_Total:
                          parseFloat(third_party_premium * 0.25).toFixed(2),
                      });
                    } else {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premium_Extra_Benefits_Legal_Liability_to_Passengers_Value: 0,
                        premium_Extra_Benefits_Legal_Liability_to_Passengers_Total:
                          (0).toFixed(2),
                      });
                    }
                  }}
                  checked={llopChecked}
                  disabled={viewOnly}
                ></Checkbox>
                &nbsp; &nbsp;Legal Liability to Passengers
              </td>
              <td className="text-end">Passengers</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={1}
                  className="form-control width_8_em"
                  name="premium_Extra_Benefits_Legal_Liability_to_Passengers_Value"
                  value={
                    premium_Extra_Benefits_Legal_Liability_to_Passengers_Value
                  }
                  onChange={(e) => onInputChange(e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Legal_Liability_to_Passengers_Total"
                  value={
                    premium_Extra_Benefits_Legal_Liability_to_Passengers_Total
                  }
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Checkbox
                  inputId="cb1"
                  onChange={(e) => {
                    setAonChecked(e.checked);
                    if (e.checked === true) {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premium_Extra_Benefits_Act_of_Negligence_Total:
                          (7.5).toFixed(2),
                      });
                    } else {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premium_Extra_Benefits_Act_of_Negligence_Total:
                          (0).toFixed(2),
                      });
                    }
                  }}
                  checked={aonChecked}
                  disabled={viewOnly}
                ></Checkbox>
                &nbsp; &nbsp;Legal Liability of Passengers for Acts of
                Negligence
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Act_of_Negligence_Total"
                  value={premium_Extra_Benefits_Act_of_Negligence_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Checkbox
                  inputId="cb1"
                  onChange={(e) => {
                    setAdCompanyChecked(e.checked);
                    if (e.checked === true) {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premium_Extra_Benefits_All_Drivers_Total: (50).toFixed(
                          2
                        ),
                      });
                    } else {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premium_Extra_Benefits_All_Drivers_Total: (0).toFixed(
                          2
                        ),
                      });
                    }
                  }}
                  checked={adCompanyChecked}
                  disabled={viewOnly}
                ></Checkbox>
                &nbsp; &nbsp;All Drivers (Company/Organisation Policy Only)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_All_Drivers_Total"
                  value={premium_Extra_Benefits_All_Drivers_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Checkbox
                  inputId="cb1"
                  onChange={(e) => {
                    setAdIndvChecked(e.checked);
                    if (e.checked === true) {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premium_Extra_Benefits_All_Drivers_Inv_Total:
                          (20).toFixed(2),
                      });
                    } else {
                      setClientVehicleInfo({
                        ...clientVehicleInfo,
                        premium_Extra_Benefits_All_Drivers_Inv_Total: 0,
                      });
                    }
                  }}
                  checked={adIndvChecked}
                  disabled={viewOnly}
                ></Checkbox>
                &nbsp; &nbsp;All Drivers (Individual Policy Only)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_All_Drivers_Inv_Total"
                  value={premium_Extra_Benefits_All_Drivers_Inv_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Strike, Riot & Civil Commotion Cover</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Strike_Commotion_Cover_Total"
                  value={premium_Extra_Benefits_Strike_Commotion_Cover_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <details>
                  <summary>Convulsion of Nature</summary>
                  <p>
                    - such as Flood, Typhoon, Hurricane, Storm, Tempest,
                    Volcanic Eruption, Earthquake, Landslide, Landslip or other
                  </p>
                </details>
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Convulsion_of_Nature_Total"
                  value={premium_Extra_Benefits_Convulsion_of_Nature_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                Additional Authorised Driver(s) (One Name per line)
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <textarea
                  className="form-control"
                  name="premium_Extra_Benefits_Additional_Driver_Named"
                  value={premium_Extra_Benefits_Additional_Driver_Named}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
              <td></td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Additional_Driver_Total"
                  value={premium_Extra_Benefits_Additional_Driver_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                Radio Casette/Tape Recorder/Air Conditioner (After Market Only)
              </td>
              <td className="text-end">Value RM</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_8_em"
                  name="premium_Extra_Benefits_RadioCasette_Value"
                  value={premium_Extra_Benefits_RadioCasette_Value}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_RadioCasette_Total"
                  value={premium_Extra_Benefits_RadioCasette_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                Insurance Protection for Learner Drivers (Tuition Purpose)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Insurance_Protection_Total"
                  value={premium_Extra_Benefits_Insurance_Protection_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                Inclusion of Damage by Overturning (Only for Mobile
                Crane/Tractor)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Inclusion_of_Damage_Total"
                  value={premium_Extra_Benefits_Inclusion_of_Damage_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                Inclusion of Accidental Damage to the Boom (Only for Mobile
                Crane/Tractor)
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Inclusion_of_Accidental_Total"
                  value={premium_Extra_Benefits_Inclusion_of_Accidental_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Extension of Cover to the Kingdom of Thailand</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Extension_of_Cover_Total"
                  value={premium_Extra_Benefits_Extension_of_Cover_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Generali MCPA / MPA</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Generali_MCPA_Total"
                  value={premium_Extra_Benefits_Generali_MCPA_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Unlimted Towing</td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Unlimited_Towing_Total"
                  value={premium_Extra_Benefits_Unlimited_Towing_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <span className="align-self-center me-2">Other</span>
                  <input
                    type="text"
                    className="form-control"
                    name="premium_Extra_Benefits_Other_1_Value"
                    value={premium_Extra_Benefits_Other_1_Value}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </td>
              <td></td>
              <td></td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Other_1_Total"
                  value={premium_Extra_Benefits_Other_1_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <span className="align-self-center me-2">Other</span>
                  <input
                    type="text"
                    className="form-control"
                    name="premium_Extra_Benefits_Other_2_Value"
                    value={premium_Extra_Benefits_Other_2_Value}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </td>
              <td></td>
              <td></td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Other_2_Total"
                  value={premium_Extra_Benefits_Other_2_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <span className="align-self-center me-2">Other</span>
                  <input
                    type="text"
                    className="form-control"
                    name="premium_Extra_Benefits_Other_3_Value"
                    value={premium_Extra_Benefits_Other_3_Value}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </td>
              <td></td>
              <td></td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control"
                  name="premium_Extra_Benefits_Other_3_Total"
                  value={premium_Extra_Benefits_Other_3_Total}
                  onChange={(e) => onInputChange(e)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <span className="float-end">Total RM</span>
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control text_background_green"
                  name="premium_Extra_Benefits_Total"
                  value={premium_Extra_Benefits_Total}
                  onChange={(e) => onInputChange(e)}
                  readOnly
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={action}>
          Close
        </Button>
        <Button variant="primary" onClick={action}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
