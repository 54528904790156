import axios from "axios";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./css/print.css";

export default function PrintCollectionByBizPartner() {
  const [companyProfile, setCompanyProfile] = useState([]);
  const [clientVehicle, setClientVehicle] = useState([]);
  const [bizPartner, setBizPartner] = useState([]);
  const [or, setOR] = useState([]);

  const {
    balance,
    cash,
    cash_ref,
    cheque,
    cheque_ref,
    credit_card,
    credit_card_ref,
    epayment,
    epayment_ref,
    other_ref,
    other,
    orid,
    total_pay,
    created,
  } = or;

  // Load Data to Datatable
  useEffect(() => {
    loadCompanyProfile();
    loadClientVehicle();
    // eslint-disable-next-line
  }, []);

  const loadCompanyProfile = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/company_profile`
    );
    setCompanyProfile(result.data);
  };

  const { id } = useParams();
  const loadClientVehicle = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/enquires/get_or_report/${id}`
    );
    const result_or = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports/get_id_official_receipts/${id}`
    );

    setClientVehicle(result.data);
    setOR(result_or.data);

    let bP = result_or.data.select_biz_partner;
    const result_bp = await axios.get(
      `${process.env.REACT_APP_API_URL}/biz_partner/get_code_biz_partner/${bP}`
    );
    setBizPartner(result_bp.data[0]);
  };

  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }

  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="main_table">
      <div className="container p-0">
        <div className="row">
          <div className="col">
            <b className="toUpperCase custom_font_25px margin_right_10px">
              {companyProfile.name}
            </b>
            ({companyProfile.business_registration_no})
          </div>
        </div>
        <div className="row">
          <div className="col">{companyProfile.address}</div>
        </div>
        <div className="row">
          <div className="col">
            {companyProfile.postcode} {companyProfile.city}
          </div>
        </div>
        <div className="row">
          <div className="col">{companyProfile.state}</div>
        </div>
        <div className="row row-cols-auto">
          <div className="col">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td>Office No</td>
                  <td className="pe-4">: {companyProfile.phone_number_1}</td>
                  <td>Tel No 1</td>
                  <td>: {companyProfile.phone_number_2}</td>
                </tr>
                <tr>
                  <td>E-mail To</td>
                  <td className="pe-4">: {companyProfile.email}</td>
                  <td>Tel No 2</td>
                  <td>: {companyProfile.phone_number_3}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        <div className="row">
          <div className="col">
            {(bizPartner.over_the_counter === "true") === true && (
              <table className="padding-0">
                <tbody>
                  <tr>
                    <td className="w_30">
                      <b>Received From</b>
                    </td>
                    <td>
                      <div className="d-flex">:&nbsp;{bizPartner.name}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Being payment of</b>
                    </td>
                    <td>
                      <div className="d-flex">:&nbsp;</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {(bizPartner.over_the_counter === "true") !== true && (
              <table className="padding-0">
                <tbody>
                  <tr>
                    <td className="w_30">
                      <b>Received From</b>
                    </td>
                    <td>
                      <div className="d-flex">
                        :&nbsp;{clientVehicle[0] ? clientVehicle[0].name : ""}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Being payment of</b>
                    </td>
                    <td>
                      <div className="d-flex">:&nbsp;</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <div className="col-4 text-end">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <b className="custom_font_20px">OFFICIAL RECEIPT</b>
                  </td>
                </tr>
                <tr>
                  <td className="text-start">
                    <b>No:</b>
                  </td>
                  <td>ORP {orid ? addLeadingZeros(orid) : 0}</td>
                </tr>
                <tr>
                  <td className="text-start">
                    <b>Date:</b>
                  </td>
                  <td>
                    {created ? format(parseISO(created), "dd/MM/yyyy") : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <table className="table-bordered border border-2 border-dark text-center padding-0">
              <thead>
                <tr>
                  <th className="w_10">
                    <b>Item</b>
                  </th>
                  <th className="text-start ps-2">
                    <b>Debit Note No.</b>
                  </th>
                  <th className="text-start ps-2">
                    <b>Cover Note No.</b>
                  </th>
                  <th className="text-start ps-2">
                    <b>Vehicle No.</b>
                  </th>
                  <th className="w_15 text-end pe-3">
                    <b>Amount(RM)</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {clientVehicle.map((cv, index) => (
                  <tr key={index} className="border-top-0 border-bottom-0">
                    <td>{index + 1}</td>
                    <td className="text-start ps-2">
                      DN
                      {cv.debit_notes
                        ? addLeadingZeros(cv.debit_notes[0].dn_id)
                        : 0}
                    </td>
                    <td className="text-start ps-2">
                      {cv.motor_Cover_Note ? cv.motor_Cover_Note : ""}
                      {cv.non_motor_Cover_Note
                        ? " " + cv.non_motor_Cover_Note
                        : ""}
                      {cv.ssn[0]
                        ? " SSN" + addLeadingZeros(cv.ssn[0].ss_id)
                        : ""}
                    </td>
                    <td className="text-start ps-2">{cv.vehicle_no}</td>
                    <td className="text-end pe-3">
                      {(() => {
                        let get_Payment = cv.getpayment;
                        let a;
                        for (let i = 0; i < get_Payment.length; i++) {
                          if (get_Payment[i].orid === id) {
                            if (index + 1 === clientVehicle.length) {
                              a = (
                                parseFloat(get_Payment[i].to_pay) +
                                parseFloat(balance)
                              ).toFixed(2);
                            } else {
                              a = parseFloat(get_Payment[i].to_pay).toFixed(2);
                            }
                          }
                        }
                        return (
                          <React.Fragment>
                            {numberWithCommas(parseFloat(a).toFixed(2))}
                          </React.Fragment>
                        );
                      })()}
                    </td>
                  </tr>
                ))}

                <tr>
                  <td colSpan={4} className="text-end p-2 pe-3">
                    <b>Total RM</b>
                  </td>
                  <td className="text-end pe-3">
                    <b>
                      {total_pay
                        ? numberWithCommas(parseFloat(total_pay).toFixed(2))
                        : 0}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <table className="border border-1 border-dark text-start">
              <tbody>
                <tr>
                  <td className="w_20">
                    <b>Amount Paid:</b>
                  </td>
                  <td className="w-25">
                    <b>
                      RM &nbsp;
                      {total_pay
                        ? numberWithCommas(parseFloat(total_pay).toFixed(2))
                        : 0}
                    </b>
                  </td>
                  <td className="w_10">
                    <b>By: </b>
                  </td>
                  <td>
                    {parseFloat(cash) > 0 && (
                      <React.Fragment>
                        CASH ({cash_ref ? cash_ref.toUpperCase() : ""})
                        <br />
                      </React.Fragment>
                    )}
                    {parseFloat(cheque) > 0 && (
                      <React.Fragment>
                        CHEQUE ({cheque_ref ? cheque_ref.toUpperCase() : ""})
                      </React.Fragment>
                    )}
                    {parseFloat(credit_card) > 0 && (
                      <React.Fragment>
                        CREDIT CARD (
                        {credit_card_ref ? credit_card_ref.toUpperCase() : ""})
                      </React.Fragment>
                    )}
                    {parseFloat(epayment) > 0 && (
                      <React.Fragment>
                        E-PAYMENT (
                        {epayment_ref ? epayment_ref.toUpperCase() : ""})
                      </React.Fragment>
                    )}
                    {parseFloat(other) > 0 && (
                      <React.Fragment>
                        OTHER ({other_ref ? other_ref.toUpperCase() : ""})
                      </React.Fragment>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            This is a computer generated document. Signature is not required.
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
}
