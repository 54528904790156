import { Calendar } from "primereact/calendar";
import { confirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ClientVehicleInfoContext from "../../../../../context/ClientVehicleProvider";

export default function MotorInfo(props) {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const viewOnly = props.view;

  const {
    motor_Cover_Note,
    motor_Datepicker_1,
    motor_Select_Action,
    motor_Datepicker_2,
    motor_Datepicker_3,
    motor_Select_Cover_Type,
    motor_Partner_Commission_Rate,
    motor_Select_Class,
    motor_CC,
    motor_Year,
    motor_Seating,
    motor_Principal_Tariff,
    motor_Make_Model,
    motor_Type_Of_Body,
    motor_HP_Co,
    motor_Remarks,
  } = clientVehicleInfo;

  const prevRef = useRef(null);
  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  const onPrincipalChange = (e) => {
    prevRef.current = motor_Principal_Tariff;

    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Principal Tariff Changed",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        setClientVehicleInfo({
          ...clientVehicleInfo,
          motor_Principal_Tariff: parseFloat(e.target.value).toFixed(2),
        });
      },
      reject: () => {
        setClientVehicleInfo({
          ...clientVehicleInfo,
          motor_Principal_Tariff: parseFloat(prevRef.current).toFixed(2),
        });
      },
    });
  };

  useEffect(() => {
    let get_third_party_premium = 0;
    if (motor_Select_Class === "PRIVATE CAR") {
      if (motor_CC <= 1400) {
        get_third_party_premium = (120.6).toFixed(2);
      } else if (motor_CC <= 1650) {
        get_third_party_premium = (135.0).toFixed(2);
      } else if (motor_CC <= 2200) {
        get_third_party_premium = (151.2).toFixed(2);
      } else if (motor_CC <= 3050) {
        get_third_party_premium = (167.4).toFixed(2);
      } else if (motor_CC <= 4100) {
        get_third_party_premium = (181.8).toFixed(2);
      } else if (motor_CC <= 4250) {
        get_third_party_premium = (198.2).toFixed(2);
      } else if (motor_CC <= 4400) {
        get_third_party_premium = (212.4).toFixed(2);
      } else if (motor_CC >= 4400) {
        get_third_party_premium = (226.8).toFixed(2);
      }
    }

    if (motor_Select_Class === "MOTORCYCLE") {
      if (motor_CC <= 50) {
        get_third_party_premium = (30.6).toFixed(2);
      } else if (motor_CC <= 100) {
        get_third_party_premium = (36.6).toFixed(2);
      } else if (motor_CC <= 125) {
        get_third_party_premium = (41.3).toFixed(2);
      } else if (motor_CC <= 225) {
        get_third_party_premium = (50.7).toFixed(2);
      } else if (motor_CC <= 350) {
        get_third_party_premium = (64.3).toFixed(2);
      } else if (motor_CC <= 500) {
        get_third_party_premium = (73.6).toFixed(2);
      } else if (motor_CC >= 500) {
        get_third_party_premium = (82.6).toFixed(2);
      }
    }

    if (motor_Select_Cover_Type === "THIRD PARTY") {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        premium_Basic_Premium: parseFloat(get_third_party_premium).toFixed(2),
        third_party_premium: parseFloat(get_third_party_premium).toFixed(2),
      });
    } else {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        third_party_premium: parseFloat(get_third_party_premium).toFixed(2),
      });
    }
    // eslint-disable-next-line
  }, [motor_Select_Class, motor_CC, motor_Select_Cover_Type]);

  useEffect(() => {
    const isAfterMarch2024 = new Date(motor_Datepicker_1) > new Date('2024-02-29');
    const premiumServiceTaxPercentage = isAfterMarch2024 ? 8 : 6;

    setClientVehicleInfo({
      ...clientVehicleInfo,
      non_motor_Datepicker_4: motor_Datepicker_1,
      sup_svc_Datepicker_7: motor_Datepicker_1,
      premium_Service_Tax_Percentage: premiumServiceTaxPercentage,
    });
    // eslint-disable-next-line
  }, [motor_Datepicker_1]);

  useEffect(() => {
    const get_motorDatepicker2 = new Date(motor_Datepicker_2);
    get_motorDatepicker2.setHours(0, 0, 0, 0);
    get_motorDatepicker2.setFullYear(get_motorDatepicker2.getFullYear() + 1);
    get_motorDatepicker2.setDate(get_motorDatepicker2.getDate() - 1);

    const updatedMotorDatepicker2 = get_motorDatepicker2;

    setClientVehicleInfo({
      ...clientVehicleInfo,
      motor_Datepicker_3: updatedMotorDatepicker2,
      non_motor_Datepicker_5: motor_Datepicker_2,
    });
    // eslint-disable-next-line
  }, [motor_Datepicker_2]);

  // Dropdown Options Vehicle Class
  const vehicle_class = [
    { name: "PRIVATE CAR", value: "PRIVATE_CAR" },
    { name: "GOODS VEHICLE - C PERMIT", value: "GOODS_VEHICLE_C_PERMIT" },
    { name: "GOODS VEHICLE - A PERMIT", value: "GOODS_VEHICLE_A_PERMIT" },
    { name: "MOTORCYCLE", value: "MOTORCYCLE" },
    { name: "COMMERCIAL MOTORCYCLE", value: "COMMERCIAL_MOTORCYCLE" },
    { name: "MOTORCYCLE WITH SIDECAR", value: "MOTORCYCLE_WITH_SIDECAR" },
    { name: "HIRE CAR", value: "HIRE_CAR" },
    { name: "OMNI FACTORY BUS - PRIVATE", value: "OMNI_FACTORY_BUS_PRIVATE" },
    { name: "OMNI FACTORY BUS - PUBLIC", value: "OMNI_FACTORY_BUS_PUBLIC" },
    { name: "OMNI SCHOOL BUS", value: "OMNI_SCHOOL_BUS" },
    { name: "OMNI PUBLIC BUS", value: "OMNI_PUBLIC_BUS" },
    { name: "TOWN TAXI", value: "TOWN_TAXI" },
    { name: "OUTSTATION TAXI", value: "OUTSTATION_TAXI" },
    { name: "HIRE CAR - HIRER DRIVEN", value: "HIRE_CAR_HIRER_DRIVEN" },
    { name: "SPECIAL TYPE VEHICLE", value: "SPECIAL_TYPE_VEHICLE" },
    { name: "MOTOR TRADE PLATE", value: "MOTOR_TRADE_PLATE" },
  ];

  // Dropdown Options Cover Type
  const cover_type = [
    { name: "COMPREHENSIVE" },
    { name: "THIRD PARTY - FIRE & THEFT" },
    { name: "THIRD PARTY" },
  ];

  // Dropdown Options Action
  const action = [{ name: "NEW" }, { name: "RENEWAL" }, { name: "OTHER" }];

  return (
    <div className="overflow-auto mt-4">
      {prevRef.current}
      <table className="motor_Table">
        <tbody>
          <tr>
            <td>Cover Note No.</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="motor_Cover_Note"
                value={motor_Cover_Note}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>Issue Date</td>
            <td>
              <Calendar
                id="icon"
                name="motor_Datepicker_1"
                value={new Date(motor_Datepicker_1)}
                onChange={(e) => onInputChange(e)}
                showIcon
                showButtonBar
                className="width_full"
                inputClassName="padding-left"
                clearButtonClassName="d-none"
                dateFormat="dd/mm/yy"
                disabled={viewOnly}
              />
            </td>
            <td>Action</td>
            <td>
              <Dropdown
                name="motor_Select_Action"
                value={motor_Select_Action}
                options={action}
                optionLabel="name"
                optionValue="name"
                onChange={(e) => onInputChange(e)}
                placeholder="Select Action"
                className="form-control p-inputtext-custom"
                disabled={viewOnly}
              />
            </td>
          </tr>
          <tr>
            <td>Period From</td>
            <td>
              <div className="d-flex">
                <Calendar
                  id="icon"
                  name="motor_Datepicker_2"
                  value={new Date(motor_Datepicker_2)}
                  onChange={(e) => onInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_full"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  disabled={viewOnly}
                />
                <span className="d-flex align-self-center px-2">to</span>
                <Calendar
                  id="icon"
                  name="motor_Datepicker_3"
                  value={new Date(motor_Datepicker_3)}
                  onChange={(e) => onInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_full"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  disabled={viewOnly}
                />
              </div>
            </td>
            <td>Cover Type</td>
            <td>
              <Dropdown
                name="motor_Select_Cover_Type"
                value={motor_Select_Cover_Type}
                options={cover_type}
                optionLabel="name"
                optionValue="name"
                onChange={(e) => onInputChange(e)}
                placeholder="Select Cover Type"
                className="form-control p-inputtext-custom"
                disabled={viewOnly}
              />
            </td>
            <td>Partner Commission</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control text_background_blue"
                  name="motor_Partner_Commission_Rate"
                  value={motor_Partner_Commission_Rate}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center px-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Class</td>
            <td>
              <Dropdown
                name="motor_Select_Class"
                value={motor_Select_Class}
                options={vehicle_class}
                optionLabel="name"
                optionValue="name"
                onChange={(e) => onInputChange(e)}
                placeholder="Select Class"
                className="form-control p-inputtext-custom"
                disabled={viewOnly}
              />
            </td>
            <td>CC / KG</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control input_percentage"
                  name="motor_CC"
                  value={motor_CC}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center px-2">Year</span>
                <input
                  type="number"
                  min="0"
                  className="form-control input_percentage"
                  name="motor_Year"
                  value={motor_Year}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center px-2">Seating</span>
                <input
                  type="number"
                  min="0"
                  className="form-control input_percentage"
                  name="motor_Seating"
                  value={motor_Seating}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </td>
            <td>Principal Tariff</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control text_background_blue"
                  name="motor_Principal_Tariff"
                  value={motor_Principal_Tariff}
                  onChange={(e) => {
                    onInputChange(e);
                    onPrincipalChange(e);
                  }}
                />
                <span className="align-self-center px-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Make/Model</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="motor_Make_Model"
                value={motor_Make_Model}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>Type of Body</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="motor_Type_Of_Body"
                value={motor_Type_Of_Body}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>HP Co.</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="motor_HP_Co"
                value={motor_HP_Co}
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Remarks</td>
            <td colSpan="5">
              <textarea
                className="form-control"
                name="motor_Remarks"
                value={motor_Remarks}
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
