import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useState } from "react";
import ClientVehicleInfoContext from "../../../../../context/ClientVehicleProvider";

export default function NonMotorInfo(props) {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const viewOnly = props.view;

  const {
    non_motor_Cover_Note,
    non_motor_Datepicker_4,
    non_motor_Select_Action,
    non_motor_Datepicker_5,
    non_motor_Datepicker_6,
    non_motor_Principal_Tariff,
    non_motor_Class_of_Ins,
    non_motor_Partner_Commission,
    non_motor_Description,
    non_motor_RM_Per_Room,
    non_motor_Sum_Insured_RM,
    non_motor_Basic_Premium,
    non_motor_Govt_Tax,
    non_motor_Total,
    non_motor_Remarks,
    non_motor_Stamp_Duty,
    non_motor_Other_Charges,
    non_motor_Total_Premium,
    non_motor_Principal_Commission,
  } = clientVehicleInfo;

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const get_motorDatepicker5 = new Date(non_motor_Datepicker_5);
    get_motorDatepicker5.setHours(0, 0, 0, 0);
    get_motorDatepicker5.setFullYear(get_motorDatepicker5.getFullYear() + 1);
    get_motorDatepicker5.setDate(get_motorDatepicker5.getDate() - 1);

    const updatedMotorDatepicker5 = get_motorDatepicker5;

    setClientVehicleInfo({
      ...clientVehicleInfo,
      non_motor_Datepicker_6: updatedMotorDatepicker5,
    });
    // eslint-disable-next-line
  }, [non_motor_Datepicker_5]);

  useEffect(() => {
    const isAfterMarch2024 = new Date(non_motor_Datepicker_4) > new Date('2024-02-29');
    const premiumServiceTaxPercentage = isAfterMarch2024 ? 8 : 6;

    setClientVehicleInfo({
      ...clientVehicleInfo,
      non_motor_Govt_Tax: premiumServiceTaxPercentage,
    });
    // eslint-disable-next-line
  }, [non_motor_Datepicker_4]);

  const [principal_commission, setPrincipal_commission] = useState(0);
  const [govt_total, setGovt_total] = useState(0);

  useEffect(() => {
    setGovt_total(
      parseFloat(non_motor_Basic_Premium * (non_motor_Govt_Tax / 100)).toFixed(
        2
      )
    );

    setPrincipal_commission(
      (
        parseFloat(non_motor_Basic_Premium) *
        parseFloat(non_motor_Principal_Tariff / 100)
      ).toFixed(2)
    );
    // eslint-disable-next-line
  }, [non_motor_Basic_Premium, non_motor_Govt_Tax, non_motor_Principal_Tariff]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      non_motor_Principal_Commission: principal_commission,
      non_motor_Total: govt_total,

      non_motor_Total_Premium: (
        parseFloat(non_motor_Basic_Premium) +
        parseFloat(govt_total) +
        parseFloat(non_motor_Stamp_Duty) +
        parseFloat(non_motor_Other_Charges)
      ).toFixed(2),

      non_motor_Gross_Premium: (
        parseFloat(non_motor_Basic_Premium) +
        parseFloat(govt_total) +
        parseFloat(non_motor_Stamp_Duty) +
        parseFloat(non_motor_Other_Charges) -
        parseFloat(principal_commission)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [
    govt_total,
    principal_commission,
    non_motor_Stamp_Duty,
    non_motor_Other_Charges,
    non_motor_Principal_Commission,
  ]);

  // Dropdown Options Action
  const action = [{ name: "NEW" }, { name: "RENEWAL" }, { name: "OTHER" }];

  return (
    <div className="overflow-auto mt-4">
      <table className="cover_Table">
        <tbody>
          <tr>
            <td>Cover Note No.</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="non_motor_Cover_Note"
                value={non_motor_Cover_Note}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td className="text-end">Issue Date</td>
            <td>
              <Calendar
                id="icon"
                name="non_motor_Datepicker_4"
                value={new Date(non_motor_Datepicker_4)}
                onChange={(e) => onInputChange(e)}
                showIcon
                showButtonBar
                className="width_full"
                inputClassName="padding-left"
                clearButtonClassName="d-none"
                dateFormat="dd/mm/yy"
                disabled={viewOnly}
              />
            </td>
            <td className="text-end">Action</td>
            <td>
              <Dropdown
                name="non_motor_Select_Action"
                value={non_motor_Select_Action}
                options={action}
                optionLabel="name"
                optionValue="name"
                onChange={(e) => onInputChange(e)}
                placeholder="Select Action"
                className="form-control p-inputtext-custom"
                disabled={viewOnly}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table className="non_motor_Table" id="form2">
        <tbody>
          <tr>
            <td>Period of Insurance</td>
            <td>
              <div className="d-flex">
                <Calendar
                  id="icon"
                  name="non_motor_Datepicker_5"
                  value={new Date(non_motor_Datepicker_5)}
                  onChange={(e) => onInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_full"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  disabled={viewOnly}
                />
                <span className="d-flex align-self-center px-2">to</span>
                <Calendar
                  id="icon"
                  name="non_motor_Datepicker_6"
                  value={new Date(non_motor_Datepicker_6)}
                  onChange={(e) => onInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_full"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  disabled={viewOnly}
                />
              </div>
            </td>
            <td>Partner Commission</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em text_background_blue"
                  name="non_motor_Partner_Commission"
                  value={non_motor_Partner_Commission}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Class of Ins.</td>
            <td className="table_custom_column_2">
              <input
                type="text"
                className="form-control"
                name="non_motor_Class_of_Ins"
                value={non_motor_Class_of_Ins}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>Principal Tariff</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em text_background_blue"
                  name="non_motor_Principal_Tariff"
                  value={non_motor_Principal_Tariff}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan="5" className="align-top">
              Description
            </td>
            <td rowSpan="5">
              <textarea
                className="form-control height_15_em"
                name="non_motor_Description"
                value={non_motor_Description}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>RM Per Room</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="non_motor_RM_Per_Room"
                  value={non_motor_RM_Per_Room}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Sum Insured RM</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="non_motor_Sum_Insured_RM"
                  value={non_motor_Sum_Insured_RM}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Basic Premium</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="non_motor_Basic_Premium"
                  value={non_motor_Basic_Premium}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Govt. Tax</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="non_motor_Govt_Tax"
                  value={non_motor_Govt_Tax}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">%</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>Govt. Tax Total</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control width_12_em text_background_green"
                  name="non_motor_Total"
                  value={non_motor_Total}
                  onChange={(e) => onInputChange(e)}
                  readOnly
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan="3" className="align-top">
              Remarks
            </td>
            <td rowSpan="3">
              <textarea
                className="form-control height_8_em"
                name="non_motor_Remarks"
                value={non_motor_Remarks}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>Stamp Duty</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="non_motor_Stamp_Duty"
                  value={non_motor_Stamp_Duty}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Other Charges</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control width_12_em"
                  name="non_motor_Other_Charges"
                  value={non_motor_Other_Charges}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>
          <tr>
            <td>Total Premium</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control width_12_em text_background_green"
                  name="non_motor_Total_Premium"
                  value={non_motor_Total_Premium}
                  onChange={(e) => onInputChange(e)}
                  readOnly
                />
                <span className="align-self-center p-2 text-right"></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
