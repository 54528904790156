import axios from "axios";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { confirmDialog } from "primereact/confirmdialog";
import { RadioButton } from "primereact/radiobutton";
import React, { useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import ClientVehicleInfoContext from "../../../../../context/ClientVehicleProvider";

export default function BillingInfo() {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );
  const {
    billing_Motor_Premium,
    billing_Discount,
    billing_non_motor_Premium,
    billing_Discount_Non_Motor,
    billing_Supporting_Services,
    billing_Subtotal,
    billing_Total_Payable,
    status,
    debit_notes,
    credit_notes,
    cv_id,
    orpayment,
    get_status,
  } = clientVehicleInfo;

  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      billing_Total_Payable: (
        parseFloat(billing_Subtotal) -
        parseFloat(billing_Discount) -
        parseFloat(billing_Discount_Non_Motor)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [billing_Subtotal, billing_Discount, billing_Discount_Non_Motor]);

  const cancelCase = async (e) => {
    e.preventDefault();

    confirmDialog({
      message: "Are you sure you want to cancel case?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async (e) => {
        // eslint-disable-next-line
        const response_or = await axios.put(
          `${process.env.REACT_APP_API_URL}/enquires/cancel-case`,
          JSON.stringify(clientVehicleInfo),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        window.location.reload();
      },
      reject: () => {},
    });
  };

  return (
    <div className="overflow-auto mt-4">
      <Container>
        <Row>
          <Col sm>
            <table className="billing_Table">
              <tbody>
                <tr>
                  <td colSpan="3"></td>
                  <td>
                    <span className="billing_Table_floar_right_Column">
                      Preset Discount
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>Motor Premium</td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billing_Motor_Premium"
                      value={billing_Motor_Premium}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step={0.01}
                      className="form-control"
                      name="billing_Discount"
                      value={billing_Discount}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>Non-Motor / Medical Premium</td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billing_non_motor_Premium"
                      value={billing_non_motor_Premium}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step={0.01}
                      className="form-control"
                      name="billing_Discount_Non_Motor"
                      value={billing_Discount_Non_Motor}
                      onChange={(e) => onInputChange(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Supporting Services</td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billing_Supporting_Services"
                      value={billing_Supporting_Services}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                  <td hidden>Desired Discounted Amount</td>
                </tr>
                <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billing_Subtotal"
                      value={billing_Subtotal}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Total Payable</td>
                  <td>
                    <span className="premium_Table_last_Column">RM</span>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      step={0.01}
                      className="form-control text_background_green"
                      name="billing_Total_Payable"
                      value={billing_Total_Payable}
                      onChange={(e) => onInputChange(e)}
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col sm>
            {status === "Draft" && (
              <Card className="draft_card mt-4 ms-4">
                <table className="status_Table">
                  <tbody>
                    <tr>
                      <td>Status:</td>
                      <td>
                        <div className="field-radiobutton">
                          <RadioButton
                            inputId="draft"
                            name="get_status"
                            value="Draft"
                            onChange={(e) => onInputChange(e)}
                            checked={get_status === "Draft"}
                          />
                          <label htmlFor="draft">Draft</label>
                        </div>
                      </td>
                      <td>
                        <div className="field-radiobutton">
                          <RadioButton
                            inputId="Issued"
                            name="get_status"
                            value="Issued"
                            onChange={(e) => onInputChange(e)}
                            checked={get_status === "Issued"}
                          />
                          <label htmlFor="Issued">Issued</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colSpan={2} className="text-center">
                        <Button
                          icon="pi pi-save"
                          type="submit"
                          label="Save"
                          className="p-button-custom"
                        ></Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            )}
            {status !== "Draft" && (
              <Card className="issued_card mt-4 ms-4">
                <table className="issued_Table">
                  <tbody>
                    {debit_notes?.length > 0 && status !== "Canceled" && (
                      <tr>
                        <td className="w-75">Debit Note No:</td>
                        <td>
                          DN
                          {debit_notes
                            ? addLeadingZeros(debit_notes[0].dn_id)
                            : 0}
                        </td>
                        <td>
                          <Link
                            target={"_blank"}
                            to={`/eias/enquires/printDN/${cv_id}`}
                          >
                            <Button
                              icon="pi pi-print"
                              type="button"
                              label="Print"
                              className="width_10_em"
                            ></Button>
                          </Link>
                        </td>
                      </tr>
                    )}
                    {credit_notes?.length > 0 && status !== "Canceled" && (
                      <tr>
                        <td>Credit Note No:</td>
                        <td>
                          CN
                          {credit_notes
                            ? addLeadingZeros(credit_notes[0].cn_id)
                            : 0}
                        </td>
                        <td>
                          <Link
                            target={"_blank"}
                            to={`/eias/enquires/printCN/${cv_id}`}
                          >
                            <Button
                              icon="pi pi-print"
                              type="button"
                              label="Print"
                              className="width_10_em"
                            ></Button>
                          </Link>
                        </td>
                      </tr>
                    )}
                    {status === "Issued" && (
                      <React.Fragment>
                        <tr>
                          <td></td>
                          <td colSpan={2}>
                            <Link to={`/eias/enquires/payment/${cv_id}`}>
                              <Button
                                icon="pi pi-money-bill"
                                label="Process to Payment"
                                className="width"
                                type="button"
                              ></Button>
                            </Link>
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                    {orpayment?.length > 0 && status !== "Canceled" && (
                      <tr>
                        <td>Customer Receipt No:</td>
                        <td>
                          ORC
                          {orpayment ? addLeadingZeros(orpayment[0].orid) : 0}
                        </td>
                        <td>
                          <Link
                            target={"_blank"}
                            to={`/eias/enquires/printORC/${cv_id}`}
                          >
                            <Button
                              icon="pi pi-print"
                              type="button"
                              label="Print"
                              className="width_10_em"
                            ></Button>
                          </Link>
                        </td>
                      </tr>
                    )}
                    {orpayment?.length > 0 && status !== "Canceled" && (
                      <React.Fragment>
                        {orpayment.map((or, index) => (
                          <tr key={index}>
                            <td>Official Receipt No {index + 1}: </td>
                            <td>
                              ORP
                              {or.orid ? addLeadingZeros(or.orid) : 0}
                            </td>
                            <td>
                              <Link
                                target={"_blank"}
                                to={`/eias/reports/collection_by_business_partner_view/${or.orid}`}
                              >
                                <Button
                                  icon="pi pi-list"
                                  type="button"
                                  label="View Payment"
                                  className="width_10_em"
                                ></Button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    )}
                    <tr>
                      <td>Status:</td>
                      <td>{status.toUpperCase()}</td>
                    </tr>
                    {status !== "Canceled" && (
                      <React.Fragment>
                        <tr>
                          <td></td>
                          <td colSpan={2}>
                            <Link
                              target={"_blank"}
                              to={`/eias/enquires/printCaseDetail/${cv_id}`}
                            >
                              <Button
                                icon="pi pi-print"
                                label="Print Case Detail"
                                className="width"
                                type="button"
                              ></Button>
                            </Link>
                          </td>
                        </tr>
                        {(status === "Issued" || status === "Closed") && (
                          <tr>
                            <td></td>
                            <td colSpan={2}>
                              <Button
                                icon="pi pi-info-circle"
                                label="Cancel Case"
                                className="width"
                                type="button"
                                onClick={(e) => cancelCase(e)}
                              ></Button>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
