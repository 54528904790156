import axios from "axios";
import { format, formatISO, parseISO } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function CreditNotes() {
  // Start of datatable props
  const [creditNotes, setCreditNotes] = useState([]);
  const dt = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [creditNotesTotal, setCreditNotesTotal] = useState(0);
  // End of datatable props

  // Start of Search filter Value
  const [searchPartner, setSearchPartner] = useState("");
  const [searchCN, setsearchCN] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchVehicleNo, setSearchVehicleNo] = useState("");
  const [dateStart, setDateStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [dateEnd, setDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );
  const buttonSearch = useRef(null);
  const [lazyState, setlazyState] = useState({
    sortField: "motor_Datepicker_1",
    sortOrder: -1,
  });
  // End of Search filter Value

  // Start of Dropdown Options
  const [bizPartnerDropdown, setBizPartnerDropdown] = useState([]);

  useEffect(() => {
    loadBizPartner();
  }, []);

  const loadBizPartner = async (e) => {
    const result_Biz = await axios.get(
      `${process.env.REACT_APP_API_URL}/biz_partner/get_all_biz_partner`
    );
    result_Biz.data.push({ code: " ALL" });
    result_Biz.data = result_Biz.data.sort(function (a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    // End of sort a to b after pushing "ALL"
    setBizPartnerDropdown(result_Biz.data);
  };
  // End of Dropdown Options

  // Start of addLeadingZeros function
  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }
  // End of addLeadingZeros function

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Start of onSort -> set value and retrigger get method
  const onSort = (event) => {
    setlazyState(event);
  };

  useEffect(() => {
    loadReport();
    // eslint-disable-next-line
  }, [lazyState]);
  // End of onSort -> set value and retrigger get method

  // Start of Load Data to Datatable with filter search
  useEffect(() => {
    loadReport();
    // eslint-disable-next-line
  }, [first, rows]);

  const loadReport = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/reports/credit_notes`, {
        params: {
          page: page,
          size: rows,
          partner: searchPartner,
          cn: searchCN.replace("CN100", ""),
          name: searchName.trim(),
          vehicle_no: searchVehicleNo.trim(),
          dateStart: formatISO(dateStart),
          dateEnd: formatISO(dateEnd),
          sort: `${lazyState.sortField},${
            lazyState.sortOrder === -1 ? "desc" : "asc"
          }`,
        },
      })
      .then((result) => {
        let result_data = result.data.content;

        let get_cn_amount = 0;

        for (let i = 0; i < result_data.length; i++) {
          result_data[i].motor_Datepicker_1 = format(
            parseISO(result_data[i].motor_Datepicker_1),
            "dd/MM/yyyy"
          );

          result_data[i].cn = result_data[i].credit_notes[0]
            ? " CN" + addLeadingZeros(result_data[i].credit_notes[0].cn_id)
            : "";

          result_data[i].name =
            result_data[i].name.toUpperCase() +
            " (" +
            result_data[i].nric +
            ")";

          result_data[i].ecv_cover_note = result_data[i].motor_Cover_Note
            ? result_data[i].motor_Cover_Note
            : "";
          result_data[i].ecv_cover_note += result_data[i].non_motor_Cover_Note
            ? " " + result_data[i].non_motor_Cover_Note
            : "";
          result_data[i].ecv_cover_note += result_data[i].ssn[0]
            ? " SSN" + addLeadingZeros(result_data[i].ssn[0].ss_id)
            : "";

          result_data[i].cn_amount = numberWithCommas(
            parseFloat(result_data[i].billing_Discount) +
              parseFloat(result_data[i].billing_Discount_Non_Motor)
          );
          // add total
          get_cn_amount += parseFloat(
            parseFloat(result_data[i].billing_Discount) +
              parseFloat(result_data[i].billing_Discount_Non_Motor)
          );
        }
        setCreditNotesTotal(numberWithCommas(get_cn_amount));
        setCreditNotes(result_data);
        setTotalRecords(result.data.totalElements);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // End of Load Data to Datatable with filter search

  // Start of funtion on key press enter and reset search function
  const handleKeyPress = useCallback((event) => {
    if (event.key === "Enter") {
      buttonSearch.current.click();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const resetSearch = () => {
    window.location.reload();
  };
  // End of funtion on key press enter and reset search function

  // Start of Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // -- Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  // -- Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // -- Datatable Header
  const header = (
    <div className="overflow-auto">
      {/* Start of 1st Row */}
      <div className="container ms-0 ps-0">
        <div className="row row-cols-auto">
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-users"></i>
              </span>
              <Dropdown
                value={searchPartner}
                options={bizPartnerDropdown}
                onChange={(event) => setSearchPartner(event.target.value)}
                optionLabel="code"
                optionValue="code"
                placeholder="Partner"
                className="custom_padding"
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-file-pdf"></i>
              </span>
              <InputText
                placeholder="Credit Notes CN"
                value={searchCN}
                onChange={(event) => setsearchCN(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of 1st Row */}
      {/* Start of 2nd Row */}
      <div className="container ms-0 ps-0 mt-3">
        <div className="row row-cols-auto">
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-user"></i>
              </span>
              <InputText
                placeholder="Name"
                value={searchName}
                onChange={(event) => setSearchName(event.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-car"></i>
              </span>
              <InputText
                placeholder="Vehicle No"
                value={searchVehicleNo}
                onChange={(event) => setSearchVehicleNo(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of 2nd Row */}
      {/* Start of 3rd Row */}
      <div className="container ms-0 ps-0">
        <div className="row row-cols-auto mt-3">
          <div className="d-flex col-12">
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="dateStart"
              value={dateStart}
              onChange={(e) => {
                setDateStart(e.value);
              }}
            />
            <span className="d-flex align-self-center px-2">to</span>
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="dateEnd"
              value={dateEnd}
              onChange={(e) => setDateEnd(e.value)}
            />
          </div>
        </div>
      </div>
      {/* End of 3rd Row */}
      {/* Start of 4th Row */}
      <div className="table-header mt-3">
        <div className="table-header-2">
          <div className="container ms-0 ps-0">
            <Button
              label="Search"
              icon="pi pi-search-plus"
              className="search_clear_button me-4"
              onClick={loadReport}
              ref={buttonSearch}
            />
            <Button
              label="Clear"
              icon="pi pi-replay"
              className="search_clear_button mt-2"
              onClick={resetSearch}
            />
          </div>
        </div>
        <div>
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button width_8_em"
            onClick={exportCSV}
          />
        </div>
      </div>
      {/* End of 4th Row */}
    </div>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={6}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={creditNotesTotal} />
      </Row>
    </ColumnGroup>
  );

  // - Start of Datatable custom body template for
  const cnBody = (rowData) => {
    let id = rowData.cv_id;
    let cn = rowData.cn;
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/printCN/${id}`} target={"_blank"}>
          {cn ? cn : ""}
        </Link>
      </React.Fragment>
    );
  };

  const coverNoteBody = (rowData) => {
    let id = rowData.cv_id;
    let ecv = rowData.ecv_cover_note;
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/${id}/view`} target={"_blank"}>
          {ecv ? ecv : ""}
        </Link>
      </React.Fragment>
    );
  };

  // End of Datatable Options

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Credit Notes</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              dataKey="cv_id"
              ref={dt}
              value={creditNotes}
              lazy={true}
              loading={loading}
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              header={header}
              onPage={onCustomPage}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              size="small"
              footerColumnGroup={footerGroup}
            >
              <Column
                field="motor_Datepicker_1"
                header="Date"
                sortable
              ></Column>
              <Column
                field="select_biz_partner"
                header="Partner"
                sortable
              ></Column>
              <Column field="cn" header="CN No." body={cnBody}></Column>
              <Column field="name" header="Name (NRIC)" sortable></Column>
              <Column field="vehicle_no" header="Vehicle No." sortable></Column>
              <Column
                field="ecv_cover_note"
                header="Cover No."
                body={coverNoteBody}
              ></Column>
              <Column field="cn_amount" header="CN Amount"></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </section>
  );
}
