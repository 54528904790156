import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faBriefcase,
  faCar,
  faDatabase,
  faDownload,
  faEnvelope,
  faFileLines,
  faHouseChimney,
  faSignOut,
  faSquareXmark,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import RequireAuth from "./context/RequireAuth";
import Missing from "./page/Error_Page/Missing";
import Unauthorized from "./page/Error_Page/Unauthorized";
import Login from "./page/Login/Login";
import ExistingClientVehicle from "./page/MYEIAS/Enquires/ExistingEnquires/ExistingClientVehicle";
import NewClientVehicle from "./page/MYEIAS/Enquires/NewClientVehicle/NewClientVehicle";
import Payment from "./page/MYEIAS/Enquires/NewClientVehicle/Payment";
import PrintCN from "./page/MYEIAS/Enquires/NewClientVehicle/PrintCN";
import PrintCaseDetail from "./page/MYEIAS/Enquires/NewClientVehicle/PrintCaseDetail";
import PrintCollectionByBizPartner from "./page/MYEIAS/Enquires/NewClientVehicle/PrintCollectionByBizPartner";
import PrintDN from "./page/MYEIAS/Enquires/NewClientVehicle/PrintDN";
import PrintOR from "./page/MYEIAS/Enquires/NewClientVehicle/PrintOR";
import Home from "./page/MYEIAS/Home";
import CollectionByBizPartner from "./page/MYEIAS/Reports/CollectionByBizPartner";
import CollectionByBizPartnerView from "./page/MYEIAS/Reports/CollectionByBizPartnerView";
import CreditNotes from "./page/MYEIAS/Reports/CreditNotes";
import DebitNotes from "./page/MYEIAS/Reports/DebitNotes";
import PrintSubmissionReceipts from "./page/MYEIAS/Reports/PrintSubmissionReceipts";
import Production from "./page/MYEIAS/Reports/Production";
import RoadTaxByType from "./page/MYEIAS/Reports/RoadTaxByType";
import SavedDraft from "./page/MYEIAS/Reports/SavedDraft";
import Statement from "./page/MYEIAS/Reports/Statement";
import SubmissionReceipts from "./page/MYEIAS/Reports/SubmissionReceipts";
import SubmissionReceiptsView from "./page/MYEIAS/Reports/SubmissionReceiptsView";
import SupportingServices from "./page/MYEIAS/Reports/SupportingServices";
import Settings from "./page/MYEIAS/Settings";
import BusinessPartners from "./page/MYEIAS/Settings/BusinessPartners";
import Client from "./page/MYEIAS/Settings/Client";
import CustRecord from "./page/MYEIAS/Settings/CustRecord";
import Principal from "./page/MYEIAS/Settings/Principal";
import PrincipalPayment from "./page/MYEIAS/Settings/PrincipalPayment";
import Vehicle from "./page/MYEIAS/Settings/Vehicle";
import NavbarLayout from "./page/layout/NavbarLayout";

library.add(
  faCar,
  faAddressBook,
  faDatabase,
  faHouseChimney,
  faBriefcase,
  faEnvelope,
  faFileLines,
  faTools,
  faSignOut,
  faDownload,
  faSquareXmark
);

const ROLES = {
  User: "ROLE_USER",
  Admin: "ROLE_ADMIN",
};

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<NavbarLayout />}>
          <Route exact path="" element={<Home></Home>}></Route>

          <Route
            exact
            path="/eias/enquires/create"
            element={<NewClientVehicle></NewClientVehicle>}
          ></Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route exact path="/home" element={<Home></Home>}></Route>
          </Route>

          {/* <Route element={<RequireAuth allowedRoles={ROLES.Admin} />}> */}
          <Route exact path="/settings" element={<Settings></Settings>}></Route>
          {/* </Route> */}

          <Route
            exact
            path="/eias/enquires/:id/view"
            element={<NewClientVehicle></NewClientVehicle>}
          ></Route>

          <Route
            exact
            path="/eias/enquires/:id/new"
            element={<NewClientVehicle></NewClientVehicle>}
          ></Route>

          <Route
            exact
            path="/eias/enquires/existing_client_vehicle"
            element={<ExistingClientVehicle />}
          ></Route>

          <Route
            exact
            path="/eias/enquires/payment/:id"
            element={<Payment />}
          ></Route>

          <Route path="*" element={<Missing />}></Route>

          {/* Settings Pages */}
          <Route
            exact
            path="/eias/settings/vehicle"
            element={<Vehicle />}
          ></Route>

          <Route
            exact
            path="/eias/settings/business_partners_view"
            element={<BusinessPartners />}
          ></Route>

          <Route
            exact
            path="/eias/settings/principals_view"
            element={<Principal />}
          ></Route>

          <Route
            exact
            path="/eias/settings/principal_payment/:id"
            element={<PrincipalPayment />}
          ></Route>

          <Route
            exact
            path="/eias/settings/clients"
            element={<Client />}
          ></Route>

          <Route
            exact
            path="/eias/settings/cust_record"
            element={<CustRecord />}
          ></Route>
          {/* End of Settings Pages */}

          {/* Report Pages */}
          <Route
            exact
            path="/eias/reports/saved_draft/"
            element={<SavedDraft />}
          ></Route>

          <Route
            exact
            path="/eias/reports/road_tax_by_type/"
            element={<RoadTaxByType />}
          ></Route>

          <Route
            exact
            path="/eias/reports/production/"
            element={<Production />}
          ></Route>

          <Route
            exact
            path="/eias/reports/supporting_services/"
            element={<SupportingServices />}
          ></Route>

          <Route
            exact
            path="/eias/reports/submission_receipts"
            element={<SubmissionReceipts />}
          ></Route>

          <Route
            exact
            path="/eias/reports/submission_receipts_view/:id"
            element={<SubmissionReceiptsView />}
          ></Route>

          <Route
            exact
            path="/eias/reports/collection_by_business_partner"
            element={<CollectionByBizPartner />}
          ></Route>

          <Route
            exact
            path="/eias/reports/collection_by_business_partner_view/:id"
            element={<CollectionByBizPartnerView />}
          ></Route>

          <Route
            exact
            path="/eias/reports/debit_notes"
            element={<DebitNotes />}
          ></Route>

          <Route
            exact
            path="/eias/reports/credit_notes"
            element={<CreditNotes />}
          ></Route>

          <Route
            exact
            path="/eias/reports/statement"
            element={<Statement />}
          ></Route>
          {/* End of Report Pages */}

          {/* <Route exact path="/adduser" element={<AddUser></AddUser>}></Route>

          <Route
            exact
            path="/editUser/:id"
            element={<EditUser></EditUser>}
          ></Route>

          <Route
            exact
            path="/viewUser/:id"
            element={<ViewUser></ViewUser>}
          ></Route> */}
        </Route>

        {/* No Navbar */}
        <Route exact path="/login" element={<Login></Login>}></Route>
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route
          exact
          path="/eias/enquires/printDN/:id"
          element={<PrintDN></PrintDN>}
        ></Route>
        <Route
          exact
          path="/eias/enquires/printCN/:id"
          element={<PrintCN></PrintCN>}
        ></Route>
        <Route
          exact
          path="/eias/enquires/printORC/:id"
          element={<PrintOR />}
        ></Route>
        <Route
          exact
          path="/eias/reports/printORP/:id"
          element={<PrintCollectionByBizPartner />}
        ></Route>
        <Route
          exact
          path="/eias/enquires/printCaseDetail/:id"
          element={<PrintCaseDetail></PrintCaseDetail>}
        ></Route>
        <Route
          exact
          path="/eias/reports/printS/:id"
          element={<PrintSubmissionReceipts />}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
