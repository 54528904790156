import axios from "axios";
import "primeicons/primeicons.css";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "../Enquires/ExistingEnquires/datatable.css";

export default function Vehicle() {
  const [vehicles, setVehicles] = useState([]);
  const [dialogTitle, setdialogTitle] = useState([]);
  const [addVehicleModal, setAddVehicleModal] = useState(false);
  const dt = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const toast = useRef(null);
  const formRef = useRef();
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [loading, setLoading] = useState(true);

  // Load Data to Datatable
  useEffect(() => {
    loadVehicle();
    initFilters1();
  }, []);

  const loadVehicle = async () => {
    setLoading(true);
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/vehicles/get_all_vehicles`);
    setVehicles(result.data);
    setLoading(false);
  };

  // End of Load Data to Datatable

  // Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  // Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // Filter Datatable Global
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value.trim();

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  // Initialize Filter Values
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  // Datatable Header
  const header = (
    <div className="table-header">
      <Button
        label="New"
        icon="pi pi-plus"
        className="p-button-success p-button-rounded p-button-sm mx-0 my-1"
        onClick={() => {
          openAddVehicleModal();
          reset();
          setdialogTitle("Add Vehicle Record");
        }}
      />
      <div className="table-header-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>

        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
          onClick={exportCSV}
        />
      </div>
    </div>
  );

  // Modal's Footer
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Save"
          icon="pi pi-check"
          type="submit"
          form="form1"
          ref={formRef}
        />
      </div>
    );
  };

  // Datatable action column field
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success me-2"
          onClick={() => {
            reset();
            editVehicle(rowData);
            setdialogTitle("Update Vehicle Record");
          }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setValue("vehicle_no", rowData.vehicle_no);
            setValue("v_id", rowData.v_id);
            deleteVehicle();
          }}
        />
      </div>
    );
  };

  // Open Modal for Add Record
  function openAddVehicleModal() {
    setAddVehicleModal(!addVehicleModal);
  }

  // Dropdown Options
  const vehicle_class = [
    { name: "PRIVATE CAR", value: "PRIVATE_CAR" },
    { name: "GOODS VEHICLE - C PERMIT", value: "GOODS_VEHICLE_C_PERMIT" },
    { name: "GOODS VEHICLE - A PERMIT", value: "GOODS_VEHICLE_A_PERMIT" },
    { name: "MOTORCYCLE", value: "MOTORCYCLE" },
    { name: "COMMERCIAL MOTORCYCLE", value: "COMMERCIAL_MOTORCYCLE" },
    { name: "MOTORCYCLE WITH SIDECAR", value: "MOTORCYCLE_WITH_SIDECAR" },
    { name: "HIRE CAR", value: "HIRE_CAR" },
    { name: "OMNI FACTORY BUS - PRIVATE", value: "OMNI_FACTORY_BUS_PRIVATE" },
    { name: "OMNI FACTORY BUS - PUBLIC", value: "OMNI_FACTORY_BUS_PUBLIC" },
    { name: "OMNI SCHOOL BUS", value: "OMNI_SCHOOL_BUS" },
    { name: "OMNI PUBLIC BUS", value: "OMNI_PUBLIC_BUS" },
    { name: "TOWN TAXI", value: "TOWN_TAXI" },
    { name: "OUTSTATION TAXI", value: "OUTSTATION_TAXI" },
    { name: "HIRE CAR - HIRER DRIVEN", value: "HIRE_CAR_HIRER_DRIVEN" },
    { name: "SPECIAL TYPE VEHICLE", value: "SPECIAL_TYPE_VEHICLE" },
    { name: "MOTOR TRADE PLATE", value: "MOTOR_TRADE_PLATE" },
  ];

  // Form's Default Value
  const defaultValues = {
    vehicle_no: "",
    motor_Make_Model: "",
    motor_Select_Class: "PRIVATE CAR",
    motor_CC: 0,
    motor_Year: new Date().getFullYear(),
    motor_Seating: 0,
  };

  // Form's Controller
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  // Submit Form
  const onSubmit = async (data) => {
    // Post Method
    if (data.v_id === undefined) {
      try {
        // eslint-disable-next-line
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/vehicles/create_new_vehicles`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        loadVehicle();
        openAddVehicleModal();
        toast.current.show({
          severity: "success",
          summary: "Vehicle Created",
          detail: (
            <span>
              Added Vehicle Number:-&nbsp;
              <b>"{getValues("vehicle_no").toLocaleUpperCase()}"</b>
            </span>
          ),
          life: 3000,
        });
        reset();
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to create Vehicle's Record",
        });
      }
    }

    // Put Method
    else {
      try {
        // eslint-disable-next-line
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/vehicles/update_id_vehicles/${data.v_id}`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        loadVehicle();
        openAddVehicleModal();
        toast.current.show({
          severity: "success",
          summary: "Vehicle Updated",
          detail: (
            <span>
              Updated Vehicle Number:-&nbsp;
              <b>"{getValues("vehicle_no").toLocaleUpperCase()}"</b>
            </span>
          ),
          life: 3000,
        });
        setTimeout(() => {
          reset();
        }, 200);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to update Vehicle's Record",
        });
      }
    }
  };

  // Form's Controller for validation
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  // Edit Vehicle Record
  const editVehicle = async (vehicleRecord) => {
    setValue("vehicle_no", vehicleRecord.vehicle_no);
    setValue("motor_Make_Model", vehicleRecord.motor_Make_Model);
    setValue("motor_Select_Class", vehicleRecord.motor_Select_Class);
    setValue("motor_CC", vehicleRecord.motor_CC);
    setValue("motor_Year", vehicleRecord.motor_Year);
    setValue("motor_Seating", vehicleRecord.motor_Seating);
    setValue("v_id", vehicleRecord.v_id);
    openAddVehicleModal();
  };

  // Delete Vehicle Record

  // onClick "Yes" button
  const accept = async () => {
    try {
      // eslint-disable-next-line
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/vehicles/delete_id_vehicles/${getValues("v_id")}`,
        JSON.stringify(),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      loadVehicle();
      toast.current.show({
        severity: "info",
        summary: "Vehicle Deleted",
        detail: (
          <span>
            Deleted Vehicle Number:-&nbsp;
            <b>"{getValues("vehicle_no").toLocaleUpperCase()}"</b>
          </span>
        ),
        life: 3000,
      });
      reset();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Failed to delete Vehicle's Record",
      });
    }
  };

  // onClick "No" button
  const reject = () => {
    reset();
  };

  // Open Delete Vehicle Modal
  const deleteVehicle = () => {
    confirmDialog({
      message: (
        <span>
          Do you want to delete Vehicle Number:-&nbsp;
          <b>"{getValues("vehicle_no").toLocaleUpperCase()}"</b>
        </span>
      ),
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  // End of Delete Vehicle Record

  return (
    <section className="pt-2 pb-5 bg-theme">
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Vehicles Management</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={vehicles}
              loading={loading}
              dataKey="v_id"
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              onPage={onCustomPage}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              filters={filters1}
              header={header}
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              size="small"
            >
              <Column
                field="vehicle_no"
                header="Vehicle No"
                filter
                sortable
              ></Column>
              <Column
                field="motor_Make_Model"
                header="Make & Type"
                sortable
              ></Column>
              <Column
                field="motor_Select_Class"
                header="Purposes"
                sortable
              ></Column>
              <Column field="motor_CC" header="CC/KG" sortable></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                exportable={false}
                style={{ width: "10%" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="form1">
        <Dialog
          onKeyUp={(e) => {
            const ENTER = 13;

            if (e.keyCode === ENTER) {
              e.preventDefault();
              formRef.current.click();
            }
          }}
          header={dialogTitle}
          visible={addVehicleModal}
          style={{ width: "35vw" }}
          onHide={openAddVehicleModal}
          footer={renderFooter()}
        >
          <div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="vehicle_no"
                  control={control}
                  rules={{ required: "Vehicle is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="vehicle_no"
                  className={classNames({ "p-error": errors.name })}
                >
                  Vehicle No *
                </label>
              </span>
              {getFormErrorMessage("vehicle_no")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="motor_Make_Model"
                  control={control}
                  rules={{ required: "Make & Type is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="motor_Make_Model"
                  className={classNames({ "p-error": errors.name })}
                >
                  Make & Type *
                </label>
              </span>
              {getFormErrorMessage("motor_Make_Model")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="motor_Select_Class"
                  control={control}
                  rules={{ required: "Class is required." }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      options={vehicle_class}
                      optionLabel="name"
                      optionValue="name"
                      id={field.name}
                      {...field}
                      className={classNames("width_full", "padding-normal", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="motor_Select_Class"
                  className={classNames({ "p-error": errors.name })}
                >
                  Vehicle Class *
                </label>
              </span>
              {getFormErrorMessage("motor_Select_Class")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="motor_CC"
                  control={control}
                  rules={{ required: "CC/KG is required." }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                      ref={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      showButtons
                      min={0}
                    />
                  )}
                />
                <label
                  htmlFor="motor_CC"
                  className={classNames({ "p-error": errors.name })}
                >
                  Vehicle CC/KG *
                </label>
              </span>
              {getFormErrorMessage("motor_CC")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="motor_Year"
                  control={control}
                  rules={{ required: "Year is required." }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                      ref={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      showButtons
                      useGrouping={false}
                      min={0}
                    />
                  )}
                />
                <label
                  htmlFor="motor_Year"
                  className={classNames({ "p-error": errors.name })}
                >
                  Vehicle Year *
                </label>
              </span>
              {getFormErrorMessage("motor_Year")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="motor_Seating"
                  control={control}
                  rules={{ required: "Seating is required.", min: 0 }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                      ref={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      showButtons
                      useGrouping={false}
                      min={0}
                    />
                  )}
                />
                <label
                  htmlFor="motor_Seating"
                  className={classNames({ "p-error": errors.name })}
                >
                  Vehicle Seating *
                </label>
              </span>
              {getFormErrorMessage("motor_Seating")}
            </div>
          </div>
        </Dialog>
      </form>
    </section>
  );
}
