import axios from "axios";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import Modal from "react-bootstrap/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import ClientVehicleInfoContext from "../../../../../../context/ClientVehicleProvider";

export default function ModalVehicle(props) {
  const [vehicles, setVehicles] = useState([]);
  const dt = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const modalState = props.toggle;
  const action = props.action;
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  useEffect(() => {
    loadVehicle();
    initFilters1();
  }, []);

  const loadVehicle = async () => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/vehicles/get_all_vehicles`);
    setVehicles(result.data);
  };

  // End of Load Data to Datatable

  // Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  // Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // Filter Datatable Global
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value.trim();

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  // Initialize Filter Values
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  // Datatable Header
  const header = (
    <div className="table-header">
      <span></span>
      <div className="table-header-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    </div>
  );

  // Datatable action column field
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          type="button"
          label="Select"
          icon="pi pi-check-circle"
          onClick={() => {
            setClientVehicleInfo({
              ...clientVehicleInfo,
              vehicle_no: rowData.vehicle_no,
              motor_Select_Class: rowData.motor_Select_Class,
              motor_CC: rowData.motor_CC,
              motor_Seating: rowData.motor_Seating,
              motor_Year: rowData.motor_Year,
              motor_Make_Model: rowData.motor_Make_Model,
            });
            action();
          }}
        />
      </div>
    );
  };
  return (
    <Modal show={modalState} onHide={action} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Search:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          ref={dt}
          value={vehicles}
          dataKey="v_id"
          paginator
          paginatorTemplate={template}
          first={first}
          rows={rows}
          onPage={onCustomPage}
          paginatorClassName="justify-content-end"
          responsiveLayout="scroll"
          filters={filters1}
          header={header}
          resizableColumns
          columnResizeMode="fit"
          showGridlines
          size="small"
        >
          <Column
            field="vehicle_no"
            header="Vehicle No"
            filter
            sortable
          ></Column>
          <Column
            field="motor_Make_Model"
            header="Make & Type"
            sortable
          ></Column>
          <Column
            field="motor_Select_Class"
            header="Purposes"
            sortable
          ></Column>
          <Column field="motor_CC" header="CC/KG" sortable></Column>
          <Column
            header="Action"
            body={actionBodyTemplate}
            exportable={false}
            style={{ width: "10%" }}
          ></Column>
        </DataTable>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={action}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
