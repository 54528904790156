import axios from "axios";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./css/print.css";

export default function PrintCaseDetail() {
  const [clientVehicleInfo, setClientVehicleInfo] = useState([]);

  const {
    // Client Details
    name,
    address,
    nric,
    phone_number_1,
    phone_number_2,
    phone_number_3,
    email,
    select_ins_principal,
    select_biz_partner,
    vehicle_no,

    // Motor Details
    motor_Cover_Note,
    motor_Datepicker_1,
    motor_Select_Action,
    motor_Datepicker_2,
    motor_Datepicker_3,
    motor_Select_Cover_Type,
    motor_Partner_Commission_Rate,
    motor_Select_Class,
    motor_CC,
    motor_Year,
    motor_Seating,
    motor_Principal_Tariff,
    motor_Make_Model,
    motor_Type_Of_Body,
    motor_HP_Co,

    // Non motor Details
    non_motor_Cover_Note,
    non_motor_Datepicker_4,
    non_motor_Datepicker_5,
    non_motor_Datepicker_6,
    non_motor_Principal_Tariff,
    non_motor_Class_of_Ins,
    non_motor_Partner_Commission,
    non_motor_Description,
    non_motor_Sum_Insured_RM,
    non_motor_Basic_Premium,
    non_motor_Govt_Tax,
    non_motor_Total,
    non_motor_Stamp_Duty,
    non_motor_Other_Charges,
    non_motor_Total_Premium,

    //Motor Premium
    premium_Sum_Insured,
    premium_Excess,
    premium_Basic_Premium,
    premium_Trailer_Schedule_Premium,
    premium_All_Rider_Percentage,
    premium_All_Rider,
    premium_Loading_Charge_Percentage,
    premium_Loading_Charge,
    premium_NCD_Percentage,
    premium_NCD,
    premium_Service_Tax_Percentage,
    premium_Service_Tax,
    premium_Stamp_Duty,
    premium_Transfer_Fees,
    premium_Total_Motor_Premium,

    //Sup Svc
    sup_svc_Datepicker_7,
    sup_svc_Road_Tax_Charges,
    sup_svc_Road_Tax_JPJ_Runner,
    sup_svc_Road_Tax_Revenue,
    sup_svc_License_Years,
    sup_svc_License_Charges,
    sup_svc_License_JPJ_Runner,
    sup_svc_License_Revenue,
    sup_svc_JPJ_Renewal_Charges,
    sup_svc_JPJ_Renewal_JPJ_Runner,
    sup_svc_JPJ_Renewal_Revenue,
    sup_svc_Others_1,
    sup_svc_Others_1_Charges,
    sup_svc_Others_1_JPJ_Runner,
    sup_svc_Others_1_Revenue,
    sup_svc_Others_2,
    sup_svc_Others_2_Charges,
    sup_svc_Others_2_JPJ_Runner,
    sup_svc_Others_2_Revenue,
    sup_svc_Others_3,
    sup_svc_Others_3_Charges,
    sup_svc_Others_3_JPJ_Runner,
    sup_svc_Others_3_Revenue,
    sup_svc_Total_Charges,
    sup_svc_Total_JPJ_Runner,
    sup_svc_Total_Revenue,

    //Benefits
    premium_Extra_Benefits_Windscreen_Cover_Value,
    premium_Extra_Benefits_Windscreen_Cover_Total,
    premium_Extra_Benefits_Legal_Liability_to_Passengers_Total,
    premium_Extra_Benefits_Act_of_Negligence_Total,
    premium_Extra_Benefits_Strike_Commotion_Cover_Total,
    premium_Extra_Benefits_All_Drivers_Total,
    premium_Extra_Benefits_All_Drivers_Inv_Total,
    premium_Extra_Benefits_Convulsion_of_Nature_Total,
    premium_Extra_Benefits_Additional_Driver_Total,
    premium_Extra_Benefits_RadioCasette_Total,
    premium_Extra_Benefits_Insurance_Protection_Total,
    premium_Extra_Benefits_Inclusion_of_Damage_Total,
    premium_Extra_Benefits_Inclusion_of_Accidental_Total,
    premium_Extra_Benefits_Extension_of_Cover_Total,
    premium_Extra_Benefits_Generali_MCPA_Total,
    premium_Extra_Benefits_Unlimited_Towing_Total,
    premium_Extra_Benefits_Other_1_Value,
    premium_Extra_Benefits_Other_1_Total,
    premium_Extra_Benefits_Other_2_Value,
    premium_Extra_Benefits_Other_2_Total,
    premium_Extra_Benefits_Other_3_Value,
    premium_Extra_Benefits_Other_3_Total,

    //Billing
    billing_Motor_Premium,
    billing_Discount,
    billing_non_motor_Premium,
    billing_Discount_Non_Motor,
    billing_Supporting_Services,
    billing_Subtotal,
    // billing_Subtotal_Discount,
    billing_Total_Payable,
    status,
    debit_notes,
    credit_notes,
    orpayment,
  } = clientVehicleInfo;

  // Load Data to Datatable
  useEffect(() => {
    loadClientVehicle();
    // eslint-disable-next-line
  }, []);

  const { id } = useParams();
  const loadClientVehicle = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/enquires/get_id_enquires/${id}`
    );
    setClientVehicleInfo(result.data);
  };

  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }

  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="main_table">
      <div className="container">
        {/* Case Details */}
        <div className="row">
          <div className="col text-center">
            <b className="custom_font_20px">
              CASE DETAILS (STATUS: {status ? status.toUpperCase() : ""})
            </b>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="padding-0 custom_font_14px">
              <tbody>
                <tr>
                  <td className="w-15">Name</td>
                  <td>
                    <div className="d-flex">
                      :&nbsp;
                      <span>{name}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-top">Address</td>
                  <td>
                    <div className="d-flex">
                      :&nbsp;
                      <span>{address}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>NRIC No.</td>
                  <td>: {nric}</td>
                </tr>
                {phone_number_1 !== null && (
                  <tr>
                    <td>Phone No.</td>
                    <td>
                      : {phone_number_1} - {phone_number_2} - {phone_number_3}
                    </td>
                  </tr>
                )}
                {email !== null && (
                  <tr>
                    <td>Email</td>
                    <td>: {email}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        {/* Detail */}
        <div className="row mt-2">
          <div className="col text-center">
            <b className="custom_font_20px">DETAILS</b>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="padding-0 custom_font_14px">
              <tbody>
                <tr>
                  <td className="w_15">
                    <b>Principal</b>
                  </td>
                  <td>
                    :&nbsp;
                    {select_ins_principal
                      ? select_ins_principal.toUpperCase()
                      : ""}
                  </td>
                  <td className="w_15">
                    <b>Partner</b>
                  </td>
                  <td>
                    :{" "}
                    {select_biz_partner ? select_biz_partner.toUpperCase() : ""}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Vehicle No</b>
                  </td>
                  <td>: {vehicle_no}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        {/* Motor Schedule */}
        {motor_Cover_Note !== null && (
          <span>
            <div className="row mt-2">
              <div className="col text-center">
                <b className="custom_font_20px">MOTOR SCHEDULE</b>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <table className="padding-0 custom_font_14px">
                  <tbody>
                    <tr>
                      <td className="w_15">
                        <b>Cover Note No</b>
                      </td>
                      <td className="w_30">: {motor_Cover_Note}</td>
                      <td className="w_20">
                        <b>Issue Date</b>
                      </td>
                      <td>
                        :&nbsp;
                        {motor_Datepicker_1
                          ? format(parseISO(motor_Datepicker_1), "dd/MM/yyyy")
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Action</b>
                      </td>
                      <td>: {motor_Select_Action}</td>
                      <td>
                        <b>Period</b>
                      </td>
                      <td>
                        :&nbsp;
                        {motor_Datepicker_2
                          ? format(parseISO(motor_Datepicker_2), "dd/MM/yyyy")
                          : ""}
                        &nbsp;to&nbsp;
                        {motor_Datepicker_3
                          ? format(parseISO(motor_Datepicker_3), "dd/MM/yyyy")
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>CC/KG</b>
                      </td>
                      <td>: {motor_CC}</td>
                      <td>
                        <b>Year</b>
                      </td>
                      <td>: {motor_Year}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Seating</b>
                      </td>
                      <td>: {motor_Seating}</td>
                      <td>
                        <b>HP Co.</b>
                      </td>
                      <td>: {motor_HP_Co}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Sum Insured</b>
                      </td>
                      <td>
                        :&nbsp;
                        {premium_Sum_Insured
                          ? numberWithCommas(premium_Sum_Insured)
                          : 0}
                      </td>
                      <td>
                        <b>Cover Type</b>
                      </td>
                      <td>: {motor_Select_Cover_Type}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Excess</b>
                      </td>
                      <td>: {premium_Excess}</td>
                      <td>
                        <b>Type of Body</b>
                      </td>
                      <td>: {motor_Type_Of_Body}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Principal Tariff</b>
                      </td>
                      <td>: {motor_Principal_Tariff}%</td>
                      <td>
                        <b>Class Name of Vehicle</b>
                      </td>
                      <td>
                        <div className="d-flex">
                          :&nbsp;
                          <span>{motor_Select_Class}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Partner Commission</b>
                      </td>
                      <td className="align-top">
                        : {motor_Partner_Commission_Rate}%
                      </td>
                      <td className="align-top">
                        <b>Make/Model</b>
                      </td>
                      <td>
                        <div className="d-flex">
                          :&nbsp;
                          <span>{motor_Make_Model}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <table className="padding-0">
                  <tbody>
                    <tr>
                      <td className="w_80">
                        <b>Premium Pricing</b>
                      </td>
                      <td className="text-end">
                        <b>RM</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Basic Premium</td>
                      <td className="text-end">
                        {premium_Basic_Premium
                          ? numberWithCommas(premium_Basic_Premium)
                          : 0}
                      </td>
                    </tr>
                    {premium_Trailer_Schedule_Premium > 0 && (
                      <tr>
                        <td>+ Trailer Schedule Premium</td>
                        <td className="text-end">
                          {premium_Trailer_Schedule_Premium
                            ? numberWithCommas(premium_Trailer_Schedule_Premium)
                            : 0}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        + All Rider Premium ({premium_All_Rider_Percentage}%)
                      </td>
                      <td className="text-end">
                        {premium_All_Rider
                          ? numberWithCommas(premium_All_Rider)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Loading ({premium_Loading_Charge_Percentage}%)</td>
                      <td className="text-end">
                        {premium_Loading_Charge
                          ? numberWithCommas(premium_Loading_Charge)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>- NCD ({premium_NCD_Percentage}%)</td>
                      <td className="text-end">
                        {premium_NCD ? numberWithCommas(premium_NCD) : 0}
                      </td>
                    </tr>

                    {premium_Extra_Benefits_Windscreen_Cover_Total > 0 && (
                      <tr>
                        <td>
                          Windscreen Cover (RM
                          {premium_Extra_Benefits_Windscreen_Cover_Value
                            ? numberWithCommas(
                                premium_Extra_Benefits_Windscreen_Cover_Value
                              )
                            : 0}
                          )
                        </td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Windscreen_Cover_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Windscreen_Cover_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Legal_Liability_to_Passengers_Total >
                      0 && (
                      <tr>
                        <td>Legal Liability to Passengers</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Legal_Liability_to_Passengers_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Legal_Liability_to_Passengers_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Act_of_Negligence_Total > 0 && (
                      <tr>
                        <td>
                          Legal Liability of Passengers for Acts of Negligence
                        </td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Act_of_Negligence_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Act_of_Negligence_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Strike_Commotion_Cover_Total >
                      0 && (
                      <tr>
                        <td>Strike, Riot & Civil Commotion Cover</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Strike_Commotion_Cover_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Strike_Commotion_Cover_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_All_Drivers_Total > 0 && (
                      <tr>
                        <td>All Drivers (Organization Policy Only)</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_All_Drivers_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_All_Drivers_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_All_Drivers_Inv_Total > 0 && (
                      <tr>
                        <td>All Drivers (Individual Policy Only)</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_All_Drivers_Inv_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_All_Drivers_Inv_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Convulsion_of_Nature_Total > 0 && (
                      <tr>
                        <td>Convulsion of Nature</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Convulsion_of_Nature_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Convulsion_of_Nature_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Additional_Driver_Total > 0 && (
                      <tr>
                        <td>Additional Driver</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Additional_Driver_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Additional_Driver_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_RadioCasette_Total > 0 && (
                      <tr>
                        <td>Radio Casette/Tape Recorder/Air Conditioner</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_RadioCasette_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_RadioCasette_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Insurance_Protection_Total > 0 && (
                      <tr>
                        <td>Insurance Protection for Learner Drivers</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Insurance_Protection_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Insurance_Protection_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Inclusion_of_Damage_Total > 0 && (
                      <tr>
                        <td>Inclusion of Damage by Overturning</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Inclusion_of_Damage_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Inclusion_of_Damage_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Inclusion_of_Accidental_Total >
                      0 && (
                      <tr>
                        <td>Inclusion of Accidental Damage to the Boom</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Inclusion_of_Accidental_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Inclusion_of_Accidental_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Extension_of_Cover_Total > 0 && (
                      <tr>
                        <td>Extension of Cover to the Kingdom of Thailand</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Extension_of_Cover_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Extension_of_Cover_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Generali_MCPA_Total > 0 && (
                      <tr>
                        <td>Extension of Cover to the Kingdom of Thailand</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Generali_MCPA_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Generali_MCPA_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Unlimited_Towing_Total > 0 && (
                      <tr>
                        <td>Extension of Cover to the Kingdom of Thailand</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Unlimited_Towing_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Unlimited_Towing_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Other_1_Total > 0 && (
                      <tr>
                        <td>Other ({premium_Extra_Benefits_Other_1_Value})</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Other_1_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Other_1_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Other_2_Total > 0 && (
                      <tr>
                        <td>Other ({premium_Extra_Benefits_Other_2_Value})</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Other_2_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Other_2_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    {premium_Extra_Benefits_Other_3_Total > 0 && (
                      <tr>
                        <td>Other ({premium_Extra_Benefits_Other_3_Value})</td>
                        <td className="text-end">
                          {premium_Extra_Benefits_Other_3_Total
                            ? numberWithCommas(
                                premium_Extra_Benefits_Other_3_Total
                              )
                            : 0}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        Authorised Driver(s)
                        <br />
                        POLICYHOLDER
                      </td>
                      <td className="text-end">
                        {premium_Extra_Benefits_Additional_Driver_Total
                          ? numberWithCommas(
                              premium_Extra_Benefits_Additional_Driver_Total
                            )
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Service Tax ({premium_Service_Tax_Percentage}%)</td>
                      <td className="text-end">
                        {premium_Service_Tax
                          ? numberWithCommas(premium_Service_Tax)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Stamp Duty</td>
                      <td className="text-end">
                        {premium_Stamp_Duty
                          ? numberWithCommas(premium_Stamp_Duty)
                          : 0}
                      </td>
                    </tr>
                    {premium_Transfer_Fees > 0 && (
                      <tr>
                        <td>+ Transfer Fees</td>
                        <td className="text-end">
                          {premium_Transfer_Fees
                            ? numberWithCommas(premium_Transfer_Fees)
                            : 0}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td className="text-end">
                        <div className="equal_line"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Total Motor Premium</b>
                      </td>
                      <td className="text-end">
                        <b>
                          {premium_Total_Motor_Premium
                            ? numberWithCommas(premium_Total_Motor_Premium)
                            : 0}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="break_line"></div>
            </div>
          </span>
        )}
        {/* Non Motor Schedule */}
        {non_motor_Cover_Note !== null && (
          <span>
            <div className="row mt-2">
              <div className="col text-center">
                <b className="custom_font_20px">NON MOTOR SCHEDULE</b>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <table className="padding-0 custom_font_14px">
                  <tbody>
                    <tr>
                      <td className="w_20">
                        <b>Cover Note No</b>
                      </td>
                      <td className="w_20">: {non_motor_Cover_Note}</td>
                      <td className="w_20">
                        <b>Issue Date</b>
                      </td>
                      <td>
                        :&nbsp;
                        {non_motor_Datepicker_4
                          ? format(
                              parseISO(non_motor_Datepicker_4),
                              "dd/MM/yyyy"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Sum Insured</b>
                      </td>
                      <td>
                        :&nbsp;
                        {non_motor_Sum_Insured_RM
                          ? numberWithCommas(non_motor_Sum_Insured_RM)
                          : 0}
                      </td>
                      <td>
                        <b>Period</b>
                      </td>
                      <td>
                        :&nbsp;
                        {non_motor_Datepicker_5
                          ? format(
                              parseISO(non_motor_Datepicker_5),
                              "dd/MM/yyyy"
                            )
                          : ""}
                        &nbsp;to&nbsp;
                        {non_motor_Datepicker_6
                          ? format(
                              parseISO(non_motor_Datepicker_6),
                              "dd/MM/yyyy"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Principal Tariff</b>
                      </td>
                      <td className="align-top">
                        : {non_motor_Principal_Tariff}%
                      </td>
                      <td className="align-top">
                        <b>className of Insurance</b>
                      </td>
                      <td>
                        <div className="d-flex">
                          :&nbsp;
                          <span>{non_motor_Class_of_Ins}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Partner Commission</b>
                      </td>
                      <td className="align-top">
                        : {non_motor_Partner_Commission}%
                      </td>
                      <td className="align-top">
                        <b>Description</b>
                      </td>
                      <td>
                        <div className="d-flex">
                          :&nbsp;
                          <span>{non_motor_Description}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <table className="padding-0">
                  <tbody>
                    <tr>
                      <td className="w_80">
                        <b>Premium Pricing</b>
                      </td>
                      <td className="text-end">
                        <b>RM</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Basic Premium</td>
                      <td className="text-end">
                        {non_motor_Basic_Premium
                          ? numberWithCommas(non_motor_Basic_Premium)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Stamp Duty</td>
                      <td className="text-end">
                        {non_motor_Stamp_Duty
                          ? numberWithCommas(non_motor_Stamp_Duty)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Service Tax ({non_motor_Govt_Tax}%)</td>
                      <td className="text-end">
                        {non_motor_Total
                          ? numberWithCommas(non_motor_Total)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>+ Other Charges</td>
                      <td className="text-end">
                        {non_motor_Other_Charges
                          ? numberWithCommas(non_motor_Other_Charges)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="text-end">
                        <div className="equal_line"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Total Non Motor Premium</b>
                      </td>
                      <td className="text-end">
                        <b>
                          {non_motor_Total_Premium
                            ? numberWithCommas(non_motor_Total_Premium)
                            : 0}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="break_line"></div>
            </div>
          </span>
        )}
        {/* Sup Svc Schedule */}
        {sup_svc_Total_Charges > 0 && (
          <span>
            <div className="row mt-2">
              <div className="col text-center">
                <b className="custom_font_20px">SUPPORTING SERVICES SCHEDULE</b>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <table className="padding-0 custom_font_14px">
                  <tbody>
                    <tr>
                      <td className="w-25">
                        <b>SS NO</b>
                      </td>
                      <td className="w_20">: SSN00001</td>
                      <td className="w_15">
                        <b>Issue Date</b>
                      </td>
                      <td>
                        :&nbsp;
                        {sup_svc_Datepicker_7
                          ? format(parseISO(sup_svc_Datepicker_7), "dd/MM/yyyy")
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Road Tax Expiry Date</b>
                      </td>
                      <td>
                        :&nbsp;
                        {motor_Datepicker_3
                          ? format(parseISO(motor_Datepicker_3), "dd/MM/yyyy")
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <table className="padding-0 custom_font_14px">
                  <tbody>
                    <tr>
                      <td></td>
                      <td className="text-end">
                        <b>Charges (RM)</b>
                      </td>
                      <td className="text-end">
                        <b>JPJ/Runner (RM)</b>
                      </td>
                      <td className="text-end">
                        <b>Revenue (RM)</b>
                      </td>
                    </tr>
                    {sup_svc_Road_Tax_Charges > 0 && (
                      <tr>
                        <td>Road Tax for (12) Month(s)</td>
                        <td className="text-end">
                          {sup_svc_Road_Tax_Charges
                            ? numberWithCommas(sup_svc_Road_Tax_Charges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_Road_Tax_JPJ_Runner
                            ? numberWithCommas(sup_svc_Road_Tax_JPJ_Runner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_Road_Tax_Revenue
                            ? numberWithCommas(sup_svc_Road_Tax_Revenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {sup_svc_License_Charges > 0 && (
                      <tr>
                        <td>License for {sup_svc_License_Years} Year(s)</td>
                        <td className="text-end">
                          {sup_svc_License_Charges
                            ? numberWithCommas(sup_svc_License_Charges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_License_JPJ_Runner
                            ? numberWithCommas(sup_svc_License_JPJ_Runner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_License_Revenue
                            ? numberWithCommas(sup_svc_License_Revenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {sup_svc_JPJ_Renewal_Charges > 0 && (
                      <tr>
                        <td>JPJ Renewal Charges</td>
                        <td className="text-end">
                          {sup_svc_JPJ_Renewal_Charges
                            ? numberWithCommas(sup_svc_JPJ_Renewal_Charges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_JPJ_Renewal_JPJ_Runner
                            ? numberWithCommas(sup_svc_JPJ_Renewal_JPJ_Runner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_JPJ_Renewal_Revenue
                            ? numberWithCommas(sup_svc_JPJ_Renewal_Revenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {sup_svc_Others_1_Charges > 0 && (
                      <tr>
                        <td>Others ({sup_svc_Others_1})</td>
                        <td className="text-end">
                          {sup_svc_Others_1_Charges
                            ? numberWithCommas(sup_svc_Others_1_Charges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_Others_1_JPJ_Runner
                            ? numberWithCommas(sup_svc_Others_1_JPJ_Runner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_Others_1_Revenue
                            ? numberWithCommas(sup_svc_Others_1_Revenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {sup_svc_Others_2_Charges > 0 && (
                      <tr>
                        <td>Others ({sup_svc_Others_2})</td>
                        <td className="text-end">
                          {sup_svc_Others_2_Charges
                            ? numberWithCommas(sup_svc_Others_2_Charges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_Others_2_JPJ_Runner
                            ? numberWithCommas(sup_svc_Others_2_JPJ_Runner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_Others_2_Revenue
                            ? numberWithCommas(sup_svc_Others_2_Revenue)
                            : 0}
                        </td>
                      </tr>
                    )}

                    {sup_svc_Others_3_Charges > 0 && (
                      <tr>
                        <td>Others ({sup_svc_Others_3})</td>
                        <td className="text-end">
                          {sup_svc_Others_3_Charges
                            ? numberWithCommas(sup_svc_Others_3_Charges)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_Others_3_JPJ_Runner
                            ? numberWithCommas(sup_svc_Others_3_JPJ_Runner)
                            : 0}
                        </td>
                        <td className="text-end">
                          {sup_svc_Others_3_Revenue
                            ? numberWithCommas(sup_svc_Others_3_Revenue)
                            : 0}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td colSpan={3}>
                        <div className="equal_line_sup_svc"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Supporting Services Premium</b>
                      </td>
                      <td className="text-end">
                        <b>
                          {sup_svc_Total_Charges
                            ? numberWithCommas(sup_svc_Total_Charges)
                            : 0}
                        </b>
                      </td>
                      <td className="text-end">
                        <b>
                          {sup_svc_Total_JPJ_Runner
                            ? numberWithCommas(sup_svc_Total_JPJ_Runner)
                            : 0}
                        </b>
                      </td>
                      <td className="text-end">
                        <b>
                          {sup_svc_Total_Revenue
                            ? numberWithCommas(sup_svc_Total_Revenue)
                            : 0}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="break_line"></div>
            </div>
          </span>
        )}
        {/* Billing */}
        <div className="row mt-2">
          <div className="col text-center">
            <b className="custom_font_20px">BILLING</b>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <table className="padding-0 custom_font_14px">
              <tbody>
                <tr>
                  <td></td>
                  <td className="text-end">
                    <b>Total (RM)</b>
                  </td>
                  <td className="text-end">
                    <b>Discount (RM)</b>
                  </td>
                </tr>
                <tr>
                  <td>+ Motor Premium</td>
                  <td className="text-end">
                    {billing_Motor_Premium
                      ? numberWithCommas(billing_Motor_Premium)
                      : 0}
                  </td>
                  <td className="text-end">
                    ({billing_Discount ? numberWithCommas(billing_Discount) : 0}
                    )
                  </td>
                </tr>
                <tr>
                  <td>+ Non Motor Premium</td>
                  <td className="text-end">
                    {billing_non_motor_Premium
                      ? numberWithCommas(billing_non_motor_Premium)
                      : 0}
                  </td>
                  <td className="text-end">
                    (
                    {billing_Discount_Non_Motor
                      ? numberWithCommas(billing_Discount_Non_Motor)
                      : 0}
                    )
                  </td>
                </tr>
                <tr>
                  <td>+ Supporting Services Premium</td>
                  <td className="text-end">
                    {billing_Supporting_Services
                      ? numberWithCommas(billing_Supporting_Services)
                      : 0}
                  </td>
                  <td className="text-end"></td>
                </tr>
                <tr>
                  <td>
                    <b>Subtotal</b>
                  </td>
                  <td className="text-end">
                    <b>
                      {billing_Subtotal
                        ? numberWithCommas(billing_Subtotal)
                        : 0}
                    </b>
                  </td>
                  <td className="text-end"></td>
                </tr>
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <div className="equal_line_sup_svc"></div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Total</b>
                  </td>
                  <td className="text-end">
                    <b>
                      {billing_Total_Payable
                        ? numberWithCommas(billing_Total_Payable)
                        : 0}
                    </b>
                  </td>
                  <td className="text-end"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        {/* Tracking Information */}
        <div className="row mt-2">
          <div className="col text-center">
            <b className="custom_font_20px">TRACKING INFORMATION</b>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <table className="padding-0 custom_font_14px">
              <tbody>
                <tr>
                  <td className="w_30">
                    <b>Debit Note No</b>
                  </td>
                  <td className="w-25">
                    DN {debit_notes ? addLeadingZeros(debit_notes[0].dn_id) : 0}
                  </td>
                  <td>
                    RM&nbsp;
                    {billing_Total_Payable
                      ? numberWithCommas(billing_Total_Payable)
                      : 0}
                  </td>
                </tr>
                {credit_notes?.length > 0 && (
                  <tr>
                    <td>
                      <b>Credit Note No</b>
                    </td>
                    <td>
                      CN&nbsp;
                      {credit_notes !== []
                        ? addLeadingZeros(credit_notes[0].cn_id)
                        : 0}
                    </td>
                    <td>
                      RM&nbsp;
                      {billing_Discount
                        ? numberWithCommas(billing_Discount)
                        : 0}
                    </td>
                  </tr>
                )}
                {orpayment?.length > 0 && (
                  <tr>
                    <td>Customer Receipt No:</td>
                    <td>
                      ORC
                      {orpayment ? addLeadingZeros(orpayment[0].orid) : 0}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}
