import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import "primeicons/primeicons.css";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { default as React, useContext, useEffect, useState } from "react";
import ClientVehicleInfoContext from "../../../../../context/ClientVehicleProvider";
import ModalClient from "./Modal/ModalClient";
import ModalVehicle from "./Modal/ModalVehicle";

export default function ClientInfo(props) {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const viewOnly = props.view;

  const [nameModal, setNameModal] = useState(false);
  const [vehicleModal, setVehicleModal] = useState(false);

  function openNameModal() {
    setNameModal(!nameModal);
  }

  function openVehicleModal() {
    setVehicleModal(!vehicleModal);
  }

  const {
    name,
    nric,
    address,
    phone_number_1,
    phone_number_2,
    email,
    phone_number_3,
    select_ins_principal,
    select_biz_partner,
    vehicle_no,
  } = clientVehicleInfo;

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });
  };

  // Dropdown Options
  const [principalDropdown, setPrincipalDropdown] = useState([]);
  const [bizPartnerDropdown, setBizPartnerDropdown] = useState([]);

  useEffect(() => {
    loadPrincipal_BizPartner();
  }, []);

  const loadPrincipal_BizPartner = async (e) => {
    const result_principal = await axios.get(
      `${process.env.REACT_APP_API_URL}/principal/get_all_principal`
    );
    setPrincipalDropdown(result_principal.data);

    const result_Biz = await axios.get(
      `${process.env.REACT_APP_API_URL}/biz_partner/get_all_biz_partner`
    );
    setBizPartnerDropdown(result_Biz.data);
  };

  useEffect(() => {
    if (select_ins_principal !== null && principalDropdown.length > 0) {
      for (let i = 0; i < principalDropdown.length; i++) {
        if (select_ins_principal === principalDropdown[i].code) {
          setClientVehicleInfo({
            ...clientVehicleInfo,
            motor_Principal_Tariff: parseFloat(
              principalDropdown[i].tariff_motor
            ).toFixed(2),
            non_motor_Principal_Tariff: parseFloat(
              principalDropdown[i].tariff_non_motor
            ).toFixed(2),
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [select_ins_principal, principalDropdown]);

  useEffect(() => {
    for (let i = 0; i < bizPartnerDropdown.length; i++) {
      if (select_biz_partner === bizPartnerDropdown[i].code) {
        setClientVehicleInfo({
          ...clientVehicleInfo,
          motor_Partner_Commission_Rate: parseFloat(
            bizPartnerDropdown[i].commission_motor
          ).toFixed(2),
          non_motor_Partner_Commission: parseFloat(
            bizPartnerDropdown[i].commission_non_motor
          ).toFixed(2),
        });
      }
    }
    // eslint-disable-next-line
  }, [select_biz_partner]);
  return (
    <div className="overflow-auto mt-4">
      <table className="name_Table">
        <tbody>
          <tr>
            <td>
              Name
              <FontAwesomeIcon
                icon="address-book"
                className="ms-2 icon"
                onClick={openNameModal}
              />
            </td>

            <td>
              <input
                type="text"
                className="form-control name_upper"
                name="name"
                value={name}
                onChange={(e) => onInputChange(e)}
              />
            </td>

            <td className="text-right">&nbsp; NRIC No.</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="nric"
                value={nric}
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>

          <tr>
            <td rowSpan="2">Address</td>

            <td rowSpan="2">
              <textarea
                className="form-control"
                name="address"
                value={address}
                onChange={(e) => onInputChange(e)}
              />
            </td>

            <td className="text-right">&nbsp; Phone No. 1</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="phone_number_1"
                value={phone_number_1}
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>

          <tr>
            <td className="text-right">&nbsp; Phone No. 2</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="phone_number_2"
                value={phone_number_2}
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>

          <tr>
            <td>Email</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="email"
                value={email}
                onChange={(e) => onInputChange(e)}
              />
            </td>

            <td className="text-right">&nbsp; Phone No. 3</td>

            <td>
              <input
                type="text"
                className="form-control"
                name="phone_number_3"
                value={phone_number_3}
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <table className="vehicle_Table">
        <tbody>
          <tr>
            <td>Ins. Principal &nbsp;</td>

            <td>
              <Dropdown
                name="select_ins_principal"
                value={select_ins_principal}
                options={principalDropdown}
                optionLabel="code"
                optionValue="code"
                onChange={(e) => onInputChange(e)}
                placeholder="Select Principal"
                className="form-control p-inputtext-custom"
                disabled={viewOnly}
              />
            </td>

            <td className="text-right">&nbsp;Biz Partner &nbsp;</td>
            <td>
              <Dropdown
                name="select_biz_partner"
                value={select_biz_partner}
                options={bizPartnerDropdown}
                optionLabel="code"
                optionValue="code"
                onChange={(e) => {
                  onInputChange(e);
                }}
                placeholder="Select Biz Partner"
                className="form-control p-inputtext-custom"
                disabled={viewOnly}
              />
            </td>

            <td className="text-right">
              &nbsp; Vehicle No.
              <FontAwesomeIcon
                icon="car"
                className="ms-2 icon"
                onClick={openVehicleModal}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name="vehicle_no"
                value={vehicle_no}
                onChange={(e) => onInputChange(e)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {viewOnly !== true && (
        <React.Fragment>
          <ModalClient toggle={nameModal} action={openNameModal} />
          <ModalVehicle toggle={vehicleModal} action={openVehicleModal} />
        </React.Fragment>
      )}
    </div>
  );
}
