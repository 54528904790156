import axios from "axios";
import { format, formatISO, parseISO } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Row } from "primereact/row";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function Production() {
  // Start of Motor datatable props
  const [motorReport, setMotorReport] = useState([]);
  const dt = useRef(null);
  const [motorTotalRecords, setMotorTotalRecords] = useState(0);
  const [motorLoading, setMotorLoading] = useState(true);
  const [motorReportTotal, setMotorReportTotal] = useState([]);
  // End of Motor datatable props

  // Start of Non-Motor datatable props
  const [nonMotorReport, setNonMotorReport] = useState([]);
  const dt2 = useRef(null);
  const [nonMotorTotalRecords, setNonMotorTotalRecords] = useState(0);
  const [nonMotorLoading, setNonMotorLoading] = useState(true);
  const [nonMotorReportTotal, setNonMotorReportTotal] = useState([]);
  // End of Non-Motor datatable props

  // Start of Search filter Value
  const [searchPrincipal, setSearchPrincipal] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [dateStart, setDateStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [dateEnd, setDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );
  const buttonSearch = useRef(null);

  const [lazyState, setlazyState] = useState({
    sortField: "motor_Datepicker_1",
    sortOrder: -1,
  });
  // End of Search filter Value

  // Start of Dropdown Options
  const statusDropdown = [
    { name: "ALL" },
    { name: "Issued" },
    { name: "Closed" },
    { name: "Submitted" },
  ];
  const [principalDropdown, setPrincipalDropdown] = useState([]);

  useEffect(() => {
    loadPrincipal_BizPartner();
  }, []);

  const loadPrincipal_BizPartner = async (e) => {
    const result_principal = await axios.get(
      `${process.env.REACT_APP_API_URL}/principal/get_all_principal`
    );

    // Start of sort a to b after pushing "ALL"
    result_principal.data.push({ code: " ALL" });
    result_principal.data = result_principal.data.sort(function (a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    // End of sort a to b after pushing "ALL"

    setPrincipalDropdown(result_principal.data);
  };
  // End of Dropdown Options

  // Load Data to Datatable

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Start of onSort -> set value and retrigger get method for both motor and non-motor
  const onSort = (event) => {
    setlazyState(event);
  };

  useEffect(() => {
    searchData();
    // eslint-disable-next-line
  }, [lazyState]);
  // End of onSort -> set value and retrigger get method for both motor and non-motor

  // Start of funtion on key press enter and reset search function
  const handleKeyPress = useCallback((event) => {
    if (event.key === "Enter") {
      buttonSearch.current.click();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const resetSearch = () => {
    window.location.reload();
  };
  // End of funtion on key press enter and reset search function

  // Start of Load Data to Datatable with filter search
  const searchData = async () => {
    // Start of axios get motor data
    setMotorLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/reports/production_motor_filter`, {
        params: {
          page: 0,
          size: 99999,
          principal: searchPrincipal,
          dateStart: formatISO(dateStart),
          dateEnd: formatISO(dateEnd),
          status: searchStatus,
          sort: `${lazyState.sortField},${
            lazyState.sortOrder === -1 ? "desc" : "asc"
          }`,
        },
      })
      .then((result) => {
        let result_data = result.data.content;
        for (let i = 0; i < result_data.length; i++) {
          result_data[i].premium_Gross_Premium = numberWithCommas(
            result_data[i].premium_Gross_Premium
          );

          result_data[i].premium_Stamp_Duty = numberWithCommas(
            result_data[i].premium_Stamp_Duty
          );

          result_data[i].premium_Transfer_Fees = numberWithCommas(
            result_data[i].premium_Transfer_Fees
          );

          result_data[i].premium_Service_Tax = numberWithCommas(
            result_data[i].premium_Service_Tax
          );

          result_data[i].premium_Principal_Commission = numberWithCommas(
            result_data[i].premium_Principal_Commission
          );

          result_data[i].premium_Net_Premium = numberWithCommas(
            result_data[i].premium_Net_Premium
          );

          result_data[i].motor_Datepicker_1 = format(
            parseISO(result_data[i].motor_Datepicker_1),
            "dd/MM/yyyy"
          );
        }

        setMotorReport(result_data);
        setMotorTotalRecords(result.data.totalElements);
        setMotorLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/reports/get_total_production_motor_filter`,
        {
          params: {
            page: 0,
            size: 99999,
            principal: searchPrincipal,
            status: searchStatus,
            dateStart: formatISO(dateStart),
            dateEnd: formatISO(dateEnd),
            sort: `${lazyState.sortField},${
              lazyState.sortOrder === -1 ? "desc" : "asc"
            }`,
          },
        }
      )
      .then((result) => {
        setMotorReportTotal(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // End of axios get motor data

    // Start of axios get non-motor data
    setNonMotorLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/reports/production_non_motor_filter`,
        {
          params: {
            page: 0,
            size: 99999,
            principal: searchPrincipal,
            status: searchStatus,
            dateStart: formatISO(dateStart),
            dateEnd: formatISO(dateEnd),
            sort: `${lazyState.sortField},${
              lazyState.sortOrder === -1 ? "desc" : "asc"
            }`,
          },
        }
      )
      .then((result) => {
        let result_data = result.data.content;
        for (let i = 0; i < result_data.length; i++) {
          result_data[i].non_motor_Basic_Premium = numberWithCommas(
            result_data[i].non_motor_Basic_Premium
          );

          result_data[i].non_motor_Stamp_Duty = numberWithCommas(
            result_data[i].non_motor_Stamp_Duty
          );

          result_data[i].non_motor_Other_Charges = numberWithCommas(
            result_data[i].non_motor_Other_Charges
          );

          result_data[i].non_motor_Total = numberWithCommas(
            result_data[i].non_motor_Total
          );

          result_data[i].non_motor_Principal_Commission = numberWithCommas(
            result_data[i].non_motor_Principal_Commission
          );

          result_data[i].non_motor_Gross_Premium = numberWithCommas(
            result_data[i].non_motor_Gross_Premium
          );

          result_data[i].non_motor_Datepicker_4 = format(
            parseISO(result_data[i].non_motor_Datepicker_4),
            "dd/MM/yyyy"
          );
        }
        setNonMotorReport(result_data);
        setNonMotorTotalRecords(result.data.totalElements);
        setNonMotorLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/reports/get_total_production_non_motor_filter`,
        {
          params: {
            page: 0,
            size: 99999,
            principal: searchPrincipal,
            status: searchStatus,
            dateStart: formatISO(dateStart),
            dateEnd: formatISO(dateEnd),
            sort: `${lazyState.sortField},${
              lazyState.sortOrder === -1 ? "desc" : "asc"
            }`,
          },
        }
      )
      .then((result) => {
        setNonMotorReportTotal(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // End of axios get non-motor data
  };
  // End of Load Data to Datatable with filter search

  // Start of Motor Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // -- Datatable Header
  const header = (
    <div className=" overflow-auto">
      <div className="container ms-0 ps-0 mt-4">
        <div className="row row-cols-auto">
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-briefcase"></i>
              </span>
              <Dropdown
                value={searchPrincipal}
                options={principalDropdown}
                onChange={(event) => setSearchPrincipal(event.target.value)}
                optionLabel="code"
                optionValue="code"
                placeholder="Principal"
                className="custom_padding"
              />
            </div>
          </div>
        </div>
        <div className="row row-cols-auto mt-3">
          <div className="col">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className="pi pi-file"></i>
              </span>
              <Dropdown
                value={searchStatus}
                options={statusDropdown}
                onChange={(event) => setSearchStatus(event.target.value)}
                optionLabel="name"
                optionValue="name"
                placeholder="Status"
                className="custom_padding"
              />
            </div>
          </div>
        </div>
        <div className="row row-cols-auto mt-3">
          <div className="d-flex col-12">
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="dateStart"
              value={dateStart}
              onChange={(e) => {
                setDateStart(e.value);
              }}
            />
            <span className="d-flex align-self-center px-2">to</span>
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="dateEnd"
              value={dateEnd}
              onChange={(e) => setDateEnd(e.value)}
            />
          </div>
        </div>
      </div>
      <div className="table-header-2 mt-3">
        <div className="container ms-0 ps-0">
          <Button
            label="Search"
            icon="pi pi-search-plus"
            className="search_clear_button me-4"
            onClick={searchData}
            ref={buttonSearch}
          />
          <Button
            label="Clear"
            icon="pi pi-replay"
            className="search_clear_button mt-2"
            onClick={resetSearch}
          />
        </div>
      </div>
      <div className="table-header">
        <h5>
          <b>Motor:</b>
        </h5>
        <div className="table-header-2">
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
            onClick={exportCSV}
          />
        </div>
      </div>
    </div>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={6}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={numberWithCommas(motorReportTotal.premium_Gross_Premium)}
        />
        <Column
          footer={numberWithCommas(motorReportTotal.premium_Stamp_Duty)}
        />
        <Column
          footer={numberWithCommas(motorReportTotal.premium_Transfer_Fees)}
        />
        <Column
          footer={numberWithCommas(motorReportTotal.premium_Service_Tax)}
        />
        <Column
          footer={numberWithCommas(
            motorReportTotal.premium_Principal_Commission
          )}
        />
        <Column
          footer={numberWithCommas(motorReportTotal.premium_Net_Premium)}
        />
      </Row>
    </ColumnGroup>
  );
  // End of Motor Datatable Options

  // Start of Non-Motor Datatable Options
  // -- Export Datatable to CSV
  const exportCSV2 = () => {
    dt2.current.exportCSV();
  };

  // Datatable Header
  const header2 = (
    <div className="table-header">
      <h5>
        <b>Non-motor:</b>
      </h5>
      <div className="table-header-2">
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
          onClick={exportCSV2}
        />
      </div>
    </div>
  );

  // Datatable Footer
  const footerGroup2 = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={6}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={numberWithCommas(nonMotorReportTotal.non_motor_Basic_Premium)}
        />
        <Column
          footer={numberWithCommas(nonMotorReportTotal.non_motor_Stamp_Duty)}
        />
        <Column
          footer={numberWithCommas(nonMotorReportTotal.non_motor_Other_Charges)}
        />
        <Column
          footer={numberWithCommas(nonMotorReportTotal.non_motor_Total)}
        />
        <Column
          footer={numberWithCommas(
            nonMotorReportTotal.non_motor_Principal_Commission
          )}
        />
        <Column
          footer={numberWithCommas(nonMotorReportTotal.non_motor_Gross_Premium)}
        />
      </Row>
    </ColumnGroup>
  );
  // End of Non-Motor Datatable Options

  // Start of Datatable custom body template for both motor and non-motor
  const nameBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.name.toUpperCase()} ({rowData.nric})
      </div>
    );
  };
  // End of Datatable custom body template for both motor and non-motor

  // Start of Datatable custom body template for motor
  const motorCNBody = (rowData) => {
    let id = rowData.cv_id;
    let cn = rowData.motor_Cover_Note;
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/${id}/view`} target={"_blank"}>
          {cn ? cn : ""}
        </Link>
      </React.Fragment>
    );
  };
  // Start of Datatable custom body template for motor

  // Start of Datatable custom body template for non motor
  const nonMotorCNBody = (rowData) => {
    let id = rowData.cv_id;
    let cn = rowData.non_motor_Cover_Note;
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/${id}/view`} target={"_blank"}>
          {cn ? cn : ""}
        </Link>
      </React.Fragment>
    );
  };
  // End of Datatable custom body template for non motor

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Production</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              dataKey="cv_id"
              ref={dt}
              value={motorReport}
              responsiveLayout="scroll"
              header={header}
              columnResizeMode="fit"
              showGridlines
              size="small"
              footerColumnGroup={footerGroup}
              totalRecords={motorTotalRecords}
              lazy={true}
              loading={motorLoading}
              onPage={searchData}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
            >
              <Column
                field="motor_Datepicker_1"
                header="Date"
                sortable
              ></Column>
              <Column
                field="select_ins_principal"
                header="Principal"
                sortable
              ></Column>
              <Column
                header="Name (NRIC)"
                field="name"
                sortable
                body={nameBodyTemplate}
              ></Column>
              <Column field="vehicle_no" header="Vehicle No." sortable></Column>
              <Column
                field="motor_Cover_Note"
                header="Cover Note No."
                body={motorCNBody}
              ></Column>
              <Column field="status" header="Status"></Column>
              <Column
                field="premium_Gross_Premium"
                header="Gross Prem."
              ></Column>
              <Column field="premium_Stamp_Duty" header="Stamp Duty"></Column>
              <Column
                field="premium_Transfer_Fees"
                header="Transfer Fees"
              ></Column>
              <Column field="premium_Service_Tax" header="Govt Tax" />
              <Column
                field="premium_Principal_Commission"
                header="Principal Comm."
              />
              <Column field="premium_Net_Premium" header="Net Prem." />
            </DataTable>
          </div>
          <br />
          <div className="div_bottom_line_title"></div>
          <br />
          <div className="card-text text-start">
            <DataTable
              dataKey="cv_id"
              ref={dt2}
              value={nonMotorReport}
              paginatorClassName="justify-content-end"
              className="mb-3"
              responsiveLayout="scroll"
              header={header2}
              columnResizeMode="fit"
              showGridlines
              size="small"
              footerColumnGroup={footerGroup2}
              totalRecords={nonMotorTotalRecords}
              lazy={true}
              loading={nonMotorLoading}
              onPage={searchData}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
            >
              <Column
                field="non_motor_Datepicker_4"
                header="Date"
                sortable
              ></Column>
              <Column
                field="select_ins_principal"
                header="Principal"
                sortable
              ></Column>
              <Column
                header="Name (NRIC)"
                field="name"
                body={nameBodyTemplate}
                sortable
              ></Column>
              <Column field="vehicle_no" header="Vehicle No." sortable></Column>
              <Column
                field="non_motor_Cover_Note"
                header="Cover Note No."
                body={nonMotorCNBody}
              ></Column>
              <Column field="status" header="Status"></Column>
              <Column
                field="non_motor_Basic_Premium"
                header="Gross Prem."
              ></Column>
              <Column field="non_motor_Stamp_Duty" header="Stamp Duty"></Column>
              <Column
                field="non_motor_Other_Charges"
                header="Other Charges"
              ></Column>
              <Column field="non_motor_Total" header="Govt Tax" />
              <Column
                field="non_motor_Principal_Commission"
                header="Principal Comm."
              />
              <Column field="non_motor_Gross_Premium" header="Net Prem." />
            </DataTable>
          </div>
        </div>
      </div>
    </section>
  );
}
