import axios from "axios";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./css/print.css";

export default function PrintCN() {
  const [companyProfile, setCompanyProfile] = useState([]);
  const [bizPartner, setBizPartner] = useState([]);
  const [clientVehicleInfo, setClientVehicleInfo] = useState([]);

  const {
    name,
    address,
    phone_number_1,
    phone_number_2,
    phone_number_3,
    vehicle_no,
    motor_Cover_Note,
    motor_Datepicker_1,
    non_motor_Cover_Note,
    credit_notes,
    billing_Discount,
    billing_Discount_Non_Motor,
    ssn,
  } = clientVehicleInfo;

  // Load Data to Datatable
  useEffect(() => {
    loadCompanyProfile();
    loadClientVehicle();
    // eslint-disable-next-line
  }, []);

  const loadCompanyProfile = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/company_profile`
    );
    setCompanyProfile(result.data);
  };

  const { id } = useParams();
  const loadClientVehicle = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/enquires/get_id_enquires/${id}`
    );
    let bP = result.data.select_biz_partner;

    const result_bp = await axios.get(
      `${process.env.REACT_APP_API_URL}/biz_partner/get_code_biz_partner/${bP}`
    );
    setBizPartner(result_bp.data[0]);
    setClientVehicleInfo(result.data);
  };

  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }

  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="main_table">
      <div className="container">
        <div className="row">
          <div className="col">
            <b className="toUpperCase custom_font_25px margin_right_10px">
              {companyProfile.name}
            </b>
            ({companyProfile.business_registration_no})
          </div>
        </div>
        <div className="row">
          <div className="col">{companyProfile.address}</div>
        </div>
        <div className="row">
          <div className="col">
            {companyProfile.postcode} {companyProfile.city}
          </div>
        </div>
        <div className="row">
          <div className="col">{companyProfile.state}</div>
        </div>
        <div className="row row-cols-auto">
          <div className="col">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td>Office No:</td>
                  <td className="pe-4 ps-2">{companyProfile.phone_number_1}</td>
                  <td>Tel No 1:</td>
                  <td className="ps-2">{companyProfile.phone_number_2}</td>
                </tr>
                <tr>
                  <td>To E-mail:</td>
                  <td className="pe-4 ps-2">{companyProfile.email}</td>
                  <td>Tel No 2:</td>
                  <td className="ps-2">{companyProfile.phone_number_3}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        <div className="row">
          <div className="col">
            {(bizPartner.over_the_counter === "true") === true && (
              <table className="padding-0">
                <tbody>
                  <tr>
                    <td className="width_5_em">
                      <b>Name</b>
                    </td>
                    <td>
                      <div className="d-flex">
                        :&nbsp;
                        <span>{bizPartner.name}</span>
                      </div>
                    </td>
                  </tr>
                  {bizPartner.address !== "" && (
                    <tr>
                      <td className="align-top">
                        <b>Address</b>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex">
                          :&nbsp;
                          <span>{bizPartner.address}</span>
                        </div>
                      </td>
                    </tr>
                  )}
                  {bizPartner.phone_number_1 !== "" && (
                    <tr>
                      <td>
                        <b>Tel No 1</b>
                      </td>
                      <td>: {bizPartner.phone_number_1}</td>
                    </tr>
                  )}
                  {bizPartner.phone_number_2 !== "" && (
                    <tr>
                      <td>
                        <b>Tel No 2</b>
                      </td>
                      <td>: {bizPartner.phone_number_2}</td>
                    </tr>
                  )}
                  {bizPartner.phone_number_3 !== "" && (
                    <tr>
                      <td>
                        <b>Tel No 3</b>
                      </td>
                      <td>: {bizPartner.phone_number_3}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {(bizPartner.over_the_counter === "true") !== true && (
              <table className="padding-0">
                <tbody>
                  <tr>
                    <td className="width_5_em">
                      <b>Name</b>
                    </td>
                    <td>
                      <div className="d-flex">
                        :&nbsp;
                        <span>{name}</span>
                      </div>
                    </td>
                  </tr>
                  {address !== null && (
                    <tr>
                      <td className="align-top">
                        <b>Address</b>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex">
                          :&nbsp;
                          <span>{address}</span>
                        </div>
                      </td>
                    </tr>
                  )}
                  {phone_number_1 !== null && (
                    <tr>
                      <td>
                        <b>Tel No 1</b>
                      </td>
                      <td>: {phone_number_1}</td>
                    </tr>
                  )}
                  {phone_number_2 !== null && (
                    <tr>
                      <td>
                        <b>Tel No 2</b>
                      </td>
                      <td>: {phone_number_2}</td>
                    </tr>
                  )}
                  {phone_number_3 !== null && (
                    <tr>
                      <td>
                        <b>Tel No 3</b>
                      </td>
                      <td>: {phone_number_3}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="col-3 text-end">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <b className="custom_font_20px">CREDIT NOTE</b>
                  </td>
                </tr>
                <tr>
                  <td className="text-start">
                    <b>No:</b>
                  </td>
                  <td>
                    CN
                    {credit_notes ? addLeadingZeros(credit_notes[0].cn_id) : 0}
                  </td>
                </tr>
                <tr>
                  <td className="text-start">
                    <b>Date:</b>
                  </td>
                  <td>
                    {credit_notes
                      ? format(parseISO(credit_notes[0].created), "dd/MM/yyyy")
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <table className="table-bordered border border-2 border-dark text-center padding-0">
              <thead>
                <tr>
                  <th>
                    <b>Date</b>
                  </th>
                  <th>
                    <b>Reference No.</b>
                  </th>
                  <th>
                    <b>Vehicle No.</b>
                  </th>
                  <th>
                    <b>Description</b>
                  </th>
                  <th>
                    <b>Amount(RM)</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">
                    {motor_Datepicker_1
                      ? format(parseISO(motor_Datepicker_1), "dd/MM/yyyy")
                      : ""}
                  </td>
                  <td>
                    {motor_Cover_Note} <br />
                    {non_motor_Cover_Note ? (
                      <div>
                        {non_motor_Cover_Note}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {ssn?.length > 0 && (
                      <span>
                        {ssn ? "SSN" + addLeadingZeros(ssn[0].ss_id) : ""}
                      </span>
                    )}
                  </td>
                  <td>{vehicle_no}</td>
                  <td>Rebate</td>
                  <td>
                    {billing_Discount
                      ? numberWithCommas(
                          (
                            parseFloat(billing_Discount) +
                            parseFloat(billing_Discount_Non_Motor)
                          ).toFixed(2)
                        )
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} className="text-end p-2 pe-3">
                    <b>Grand Total RM</b>
                  </td>
                  <td>
                    <b>
                      {billing_Discount
                        ? numberWithCommas(
                            (
                              parseFloat(billing_Discount) +
                              parseFloat(billing_Discount_Non_Motor)
                            ).toFixed(2)
                          )
                        : 0}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            This is a computer generated document. Signature is not required.
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
}
