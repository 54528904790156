import axios from "axios";
import { format, parseISO } from "date-fns";
import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function PrincipalPayment() {
  // Start of datatable props
  const [clientVehicle, setClientVehicle] = useState([]);
  const dt = useRef(null);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [totals, setTotals] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  // End of datatable props

  // Start of params props
  const { id } = useParams();
  const scrollRef = useRef(null);
  const [getPrincipal, setGetPrincipal] = useState([]);
  const [searchType, setSearchType] = useState("Motor");
  const [selectedCV, setSelectedCV] = useState([
    {
      cv_id: parseInt(id),
    },
  ]);

  const typeDropdown = [{ name: "Motor" }, { name: "Non-Motor" }];
  // End of params props

  // Start of payment
  const [payment, setPayment] = useState({
    cash: 0.0,
    cash_ref: "",
    cheque: 0.0,
    cheque_ref: "",
    credit_card: 0.0,
    credit_card_ref: "",
    epayment: 0.0,
    epayment_ref: "",
    other_ref: "",
    other: 0.0,
    total_pay: 0.0,
    balance: 0.0,
    select_ins_principal: "",
    type: "Motor",
  });

  const {
    cash,
    cash_ref,
    cheque,
    cheque_ref,
    credit_card,
    credit_card_ref,
    epayment,
    epayment_ref,
    other_ref,
    other,
    total_pay,
    balance,
  } = payment;

  // End of payment

  // Start of addLeadingZeros && numberWithCommas function
  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }

  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of addLeadingZeros && numberWithCommas function

  // Load Data to Datatable
  useEffect(() => {
    loadClientVehicle();
    initFilters1();
    // eslint-disable-next-line
  }, [searchType]);

  const loadClientVehicle = async () => {
    setLoading(true);
    let get_principal;
    get_principal = id;
    setGetPrincipal(get_principal);

    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/enquires/get_principal_payment/${get_principal}/${searchType}`
    );

    let result_data = result.data;
    let get_tb = 0.0;
    for (let i = 0; i < result_data.length; i++) {
      if (searchType === "Motor") {
        get_tb += parseFloat(result_data[i].premium_Net_Premium);
      } else {
        get_tb += parseFloat(result_data[i].non_motor_Gross_Premium);
      }
    }
    setTotals({
      total_billing: numberWithCommas(get_tb),
    });

    setPayment({
      ...payment,
      select_ins_principal: get_principal,
      type: searchType,
    });
    setClientVehicle(result_data);

    setTimeout(
      () =>
        scrollRef.current.scrollIntoView({
          inline: "center",
          behavior: "smooth",
        }),
      777
    );
    setLoading(false);
  };
  // End of Load Data to Datatable

  // Start of Motor Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // -- Filter Datatable Global
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value.trim();

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  // -- Initialize Filter Values
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      test: {
        value: null,
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue1("");
  };

  // -- Datatable Header
  const header = (
    <div className="table-header">
      <div className="p-inputgroup me-5">
        <span className="p-inputgroup-addon">
          <i className="pi pi-clone"></i>
        </span>
        <Dropdown
          value={searchType}
          options={typeDropdown}
          onChange={(event) => setSearchType(event.target.value)}
          optionLabel="name"
          optionValue="name"
          placeholder="Type"
          className="custom_padding"
        />
      </div>
      <div className="table-header-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>

        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
          onClick={exportCSV}
        />
      </div>
    </div>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={4}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={totals.total_billing}
          footerStyle={{ textAlign: "right" }}
        />
        <Column />
      </Row>
    </ColumnGroup>
  );

  // -- Start of Custom Body Templates
  const debitNotesTemplates = (rowData) => {
    let a = rowData.debit_notes[0].dn_id;
    return <React.Fragment>DN {a ? addLeadingZeros(a) : 0}</React.Fragment>;
  };

  const coverNoteTemplates = (rowData) => {
    return (
      <React.Fragment>
        {rowData.motor_Cover_Note ? rowData.motor_Cover_Note : ""}
        {rowData.non_motor_Cover_Note ? " " + rowData.non_motor_Cover_Note : ""}
        {rowData.ssn[0] ? " SSN" + addLeadingZeros(rowData.ssn[0].ss_id) : ""}
      </React.Fragment>
    );
  };

  const totalAmountPayableTemplates = (rowData) => {
    if (searchType === "Motor") {
      return numberWithCommas(rowData.premium_Net_Premium);
    } else {
      return numberWithCommas(rowData.non_motor_Gross_Premium);
    }
  };

  const dateTemplate = (rowData) => {
    return (
      <div>
        {format(parseISO(rowData.debit_notes[0].created), "dd/MM/yyyy")}
      </div>
    );
  };
  // -- End of Custom Body Templates

  // End of Motor Datatable Optionsbalance

  // Start of Function for Cell Editable
  function onTotalPay() {
    let total = 0.0;
    for (let i = 0; i < clientVehicle.length; i++) {
      if (selectedCV.some((e) => e.cv_id === clientVehicle[i].cv_id)) {
        if (searchType === "Motor") {
          total -= parseFloat(clientVehicle[i].premium_Net_Premium);
        } else {
          total -= parseFloat(clientVehicle[i].non_motor_Gross_Premium);
        }
      }
    }
    let balance_after = parseFloat(total) + parseFloat(total_pay);
    setPayment({
      ...payment,
      balance: parseFloat(balance_after).toFixed(2),
    });
  }

  useEffect(() => {
    onTotalPay();
    // eslint-disable-next-line
  }, [selectedCV]);

  // End of Function for Cell Editable

  // Start of onInputChange && onInputNumberChange
  const onInputChange = (e) => {
    setPayment({
      ...payment,
      [e.target.name]: e.target.value,
    });
  };

  const onInputNumberChange = (e) => {
    if (e.originalEvent.target.value === "") {
      e.originalEvent.target.value = parseFloat(0.0);
    }
    setPayment({
      ...payment,
      [e.originalEvent.target.name]: parseFloat(
        e.originalEvent.target.value.replace(/,/g, "")
      ).toFixed(2),
    });
  };

  useEffect(() => {
    setPayment({
      ...payment,
      total_pay:
        parseFloat(cash) +
        parseFloat(cheque) +
        parseFloat(credit_card) +
        parseFloat(epayment) +
        parseFloat(other),
    });
    // eslint-disable-next-line
  }, [cash, cheque, credit_card, epayment, other]);

  useEffect(() => {
    onTotalPay();
    // eslint-disable-next-line
  }, [total_pay]);
  // End of onInputChange && onInputNumberChange

  // Start of onSubmit function
  let navigate = useNavigate();
  const onSubmit = async (e) => {
    e.preventDefault();
    if (parseFloat(balance) === 0) {
      confirmDialog({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: async (e) => {
          const response_or = await axios.post(
            `${process.env.REACT_APP_API_URL}/reports/create_new_submission_receipts`,
            JSON.stringify(payment),
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );
          let get_sid = response_or.data.sid;

          for (let i = 0; i < clientVehicle.length; i++) {
            if (selectedCV.some((e) => e.cv_id === clientVehicle[i].cv_id)) {
              var object = clientVehicle[i];
              var format_ClientVehicleInfo = {};
              // eslint-disable-next-line
              Object.keys(object).forEach(function (key) {
                if (object[key] !== "") {
                  format_ClientVehicleInfo[key] = object[key];
                }
              });

              format_ClientVehicleInfo["status"] = "Submitted";

              // eslint-disable-next-line
              const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/enquires/update-enquires/${format_ClientVehicleInfo["cv_id"]}`,
                JSON.stringify(format_ClientVehicleInfo),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
                }
              );

              // eslint-disable-next-line
              const response_cv_or = await axios.put(
                `${process.env.REACT_APP_API_URL}/enquires/update_s_cv/${format_ClientVehicleInfo["cv_id"]}/submission_receipts/${get_sid}`
              );
            }
          }
          navigate(`/eias/reports/submission_receipts_view/${get_sid}`);
        },
        reject: () => {},
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: `Balance Must Be 0 ${balance}`,
        detail: (
          <span>
            There is still some <b>Balance</b> remaining.
          </span>
        ),
        life: 3000,
      });
    }
  };
  // End of onSubmit function

  return (
    <section className="pt-2 pb-5 bg-theme">
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Payment Settlement Principal</h2>
            <h4 className="text-start">Principal: {getPrincipal}</h4>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={clientVehicle}
              dataKey="cv_id"
              responsiveLayout="scroll"
              filters={filters1}
              header={header}
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              size="small"
              footerColumnGroup={footerGroup}
              loading={loading}
              globalFilterFields={[
                "debit_notes.0.dn_id",
                "vehicle_no",
                "motor_Cover_Note",
                "non_motor_Cover_Note",
                "billing_Total_Payable",
                "ssn.0.ss_id",
                "debit_notes.0.created",
              ]}
              stateStorage="session"
              // stateKey="dt-state-demo-session"
              selectionMode="checkbox"
              selection={selectedCV}
              onSelectionChange={(e) => {
                setSelectedCV(e.value);
              }}
            >
              <Column
                field="debit_notes.0.dn_id"
                body={debitNotesTemplates}
                header="Debit Note No"
                sortable
              ></Column>
              <Column
                field="debit_notes.0.created"
                body={dateTemplate}
                header="Debit Note Date"
                sortable
              ></Column>
              <Column field="vehicle_no" header="Vehicle No" sortable></Column>
              <Column
                field="motor_Cover_Note"
                body={coverNoteTemplates}
                header="Cover Note No"
                sortable
              ></Column>
              <Column
                body={totalAmountPayableTemplates}
                header="Total Payable (RM)"
                className="text-end pe-3"
              ></Column>
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3em" }}
              ></Column>
            </DataTable>
            <br />
            <form onSubmit={(e) => onSubmit(e)}>
              <table
                className="bg-white table-bordered"
                id="payment_table"
                ref={scrollRef}
              >
                <thead>
                  <tr>
                    <th className="p-2">
                      <b>Collection Mode</b>
                    </th>
                    <th className="p-2">
                      <b>Reference</b>
                    </th>
                    <th className="p-2">
                      <b>Amount (RM)</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CASH</td>
                    <td>
                      <InputText
                        placeholder="Enter Cash's Reference"
                        className="w-100"
                        name="cash_ref"
                        value={cash_ref}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="cash"
                        value={cash}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CHEQUE</td>
                    <td>
                      <InputText
                        placeholder="Enter Cheque's Reference"
                        className="w-100"
                        name="cheque_ref"
                        value={cheque_ref}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="cheque"
                        value={cheque}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>DIRECTORS CREDIT CARD</td>
                    <td>
                      <InputText
                        placeholder="Enter Credit Card's Reference"
                        className="w-100"
                        name="credit_card_ref"
                        value={credit_card_ref}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="credit_card"
                        value={credit_card}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>ePAYMENT</td>
                    <td>
                      <InputText
                        placeholder="Enter ePayment's Reference"
                        className="w-100"
                        name="epayment_ref"
                        value={epayment_ref}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="epayment"
                        value={epayment}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>OTHER</td>
                    <td>
                      <InputText
                        placeholder="Enter Other's Reference"
                        className="w-100"
                        name="other_ref"
                        value={other_ref}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="other"
                        value={other}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Total Payment RM</td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="total_pay"
                        value={total_pay}
                        readOnly={true}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Balance</td>
                    <td>
                      <InputNumber
                        mode="decimal"
                        minFractionDigits={2}
                        className="w-100"
                        name="balance"
                        value={balance}
                        onChange={(e) => onInputNumberChange(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button
                type="submit"
                className="float-end mt-3"
                label="Submit"
                icon="pi pi-check"
                iconPos="right"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
