import React, { useContext } from "react";
import ClientVehicleInfoContext from "../../../../../context/ClientVehicleProvider";

export default function AlterClientInfo() {
  const { clientVehicleInfo } = useContext(ClientVehicleInfoContext);
  return (
    <div className="overflow-auto">
      <table className="client_detail_Table">
        <tbody>
          <tr>
            <td>
              <label>Client: </label>
            </td>
            <td>
              <input
                type="text"
                className="form-control name_upper"
                name={clientVehicleInfo.name}
                value={clientVehicleInfo.name}
                readOnly
              />
            </td>
            <td>
              <label>Vehicle No: </label>
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name={clientVehicleInfo.vehicle_no}
                value={clientVehicleInfo.vehicle_no}
                readOnly
              />
            </td>
            <td>
              <label>Ins. Principal: </label>
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name={clientVehicleInfo.select_ins_principal}
                value={clientVehicleInfo.select_ins_principal}
                readOnly
              />
            </td>
            <td>
              <label>Biz Partner: </label>
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                name={clientVehicleInfo.select_biz_partner}
                value={clientVehicleInfo.select_biz_partner}
                readOnly
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
