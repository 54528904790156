import axios from "axios";
import { format, parseISO } from "date-fns";
import "primeicons/primeicons.css";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Row } from "primereact/row";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function CollectionByBizPartnerView() {
  // Start of datatable props
  const [clientVehicle, setClientVehicle] = useState([]);
  const dt = useRef(null);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [totals, setTotals] = useState([]);
  const [or, setOR] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    balance,
    cash,
    cash_ref,
    cheque,
    cheque_ref,
    credit_card,
    credit_card_ref,
    epayment,
    epayment_ref,
    other_ref,
    other,
    orid,
  } = or;
  // End of datatable props

  // Start of addLeadingZeros function
  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }
  // End of addLeadingZeros function

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Load Data to Datatable
  useEffect(() => {
    loadReport();
    initFilters1();
    // eslint-disable-next-line
  }, []);

  const { id } = useParams();

  const loadReport = async () => {
    setLoading(true);
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/enquires/get_or_report/${id}`
    );
    const result_or = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports/get_id_official_receipts/${id}`
    );
    let result_data = result.data;

    let get_total_payable = 0.0;
    let get_total_payment = 0.0;

    for (let i = 0; i < result_data.length; i++) {
      // add DN 100 for dn_id
      result_data[i].debit_notes[0].dn_id =
        "DN " + addLeadingZeros(result_data[i].debit_notes[0].dn_id);

      get_total_payable += parseFloat(result_data[i].billing_Total_Payable);

      let getPayment = result_data[i].getpayment;
      for (let j = 0; j < getPayment.length; j++) {
        if (getPayment[j].orid === id) {
          get_total_payment += parseFloat(getPayment[j].to_pay);
          getPayment[j].to_pay = numberWithCommas(getPayment[j].to_pay);
        }
      }

      result_data[i].ecv_cover_note = result_data[i].motor_Cover_Note
        ? result_data[i].motor_Cover_Note
        : "";
      result_data[i].ecv_cover_note += result_data[i].non_motor_Cover_Note
        ? " " + result_data[i].non_motor_Cover_Note
        : "";
      result_data[i].ecv_cover_note += result_data[i].ssn[0]
        ? " SSN" + addLeadingZeros(result_data[i].ssn[0].ss_id)
        : "";

      result_data[i].debit_notes[0].date = format(
        parseISO(result_data[i].debit_notes[0].created),
        "dd/MM/yyyy"
      );

      result_data[i].billing_Total_Payable = numberWithCommas(
        result_data[i].billing_Total_Payable
      );
    }
    setTotals({
      total_payable: numberWithCommas(get_total_payable),
      total_payment: numberWithCommas(get_total_payment),
    });

    setClientVehicle(result_data);
    setOR(result_or.data);
    setLoading(false);
  };

  // End of Load Data to Datatable

  // Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // Filter Datatable Global
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value.trim();

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  // Initialize Filter Values
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  // Datatable Header
  const header = (
    <div className="table-header">
      <Link to={`/eias/reports/collection_by_business_partner`}>
        <Button
          icon="pi pi-angle-left"
          label="Back"
          className="width"
          type="button"
        ></Button>
      </Link>
      <div className="table-header-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>

        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
          onClick={exportCSV}
        />
      </div>
    </div>
  );

  // Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={4}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={totals.total_payable} />
        <Column footer={totals.total_payment} />
        <Column />
      </Row>
    </ColumnGroup>
  );

  // - Start of Datatable custom body template for
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Link target={"_blank"} to={`/eias/enquires/printORC/${rowData.cv_id}`}>
          <Button
            icon="pi pi-print"
            type="button"
            label="Print Customer Receipt"
          ></Button>
        </Link>
      </div>
    );
  };

  const coverNoteBody = (rowData) => {
    let id = rowData.cv_id;
    let ecv = rowData.ecv_cover_note;
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/${id}/view`} target={"_blank"}>
          {ecv ? ecv : ""}
        </Link>
      </React.Fragment>
    );
  };

  // End of Datatable Options

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">
              Collection By Business Partner Report
            </h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={clientVehicle}
              loading={loading}
              dataKey="v_id"
              responsiveLayout="scroll"
              filters={filters1}
              header={header}
              footerColumnGroup={footerGroup}
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              size="small"
              globalFilterFields={[
                "debit_notes.0.dn_id",
                "vehicle_no",
                "motor_Cover_Note",
                "non_motor_Cover_Note",
                "billing_Total_Payable",
                "ssn.0.ss_id",
                "debit_notes.0.date",
              ]}
            >
              <Column
                field="debit_notes.0.dn_id"
                header="Debit Note No"
                sortable
              ></Column>
              <Column
                field="debit_notes.0.date"
                header="Debit Note Date"
                sortable
              ></Column>
              <Column field="vehicle_no" header="Vehicle No" sortable></Column>
              <Column
                field="ecv_cover_note"
                header="Cover No."
                body={coverNoteBody}
              ></Column>
              <Column
                field="billing_Total_Payable"
                header="Total Payable (RM)"
                sortable
                className="pe-3"
              ></Column>
              <Column
                header="Payment (RM)"
                sortable
                className="pe-3"
                field="getpayment.0.to_pay"
              ></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                exportable={false}
                style={{ width: "10%" }}
              ></Column>
            </DataTable>
            <br />
            <table className="bg-white table-bordered w-50">
              <thead>
                <tr>
                  <th className="p-2">
                    <b>Collection Mode</b>
                  </th>
                  <th className="p-2">
                    <b>Reference</b>
                  </th>
                  <th className="p-2">
                    <b>Amount (RM)</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {parseFloat(cash) > 0 && (
                  <tr>
                    <td>Cash</td>
                    <td>{cash_ref}</td>
                    <td className="text-end">
                      {cash ? numberWithCommas(parseFloat(cash).toFixed(2)) : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(cheque) > 0 && (
                  <tr>
                    <td>Cheque</td>
                    <td>{cheque_ref}</td>
                    <td className="text-end">
                      {cash
                        ? numberWithCommas(parseFloat(cheque).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(credit_card) > 0 && (
                  <tr>
                    <td>DIRECTORS CREDIT CARD</td>
                    <td>{credit_card_ref}</td>
                    <td className="text-end">
                      {cash
                        ? numberWithCommas(parseFloat(credit_card).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(epayment) > 0 && (
                  <tr>
                    <td>ePAYMENT</td>
                    <td>{epayment_ref}</td>
                    <td className="text-end">
                      {cash
                        ? numberWithCommas(parseFloat(epayment).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(other) > 0 && (
                  <tr>
                    <td>OTHER</td>
                    <td>{other_ref}</td>
                    <td className="text-end">
                      {cash
                        ? numberWithCommas(parseFloat(other).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                )}
                {parseFloat(balance) !== 0 && (
                  <tr>
                    <td>Balance</td>
                    <td>{balance > 0 ? "Surplus" : "Shortfall"}</td>
                    <td className="text-end">
                      {balance
                        ? numberWithCommas(
                            parseFloat(Math.abs(balance)).toFixed(2)
                          )
                        : 0}
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={2} className="text-end">
                    <b>Total Payment RM</b>
                  </td>
                  <td className="text-end">
                    <b>{totals.total_payment}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <Card className="or_table w_40">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Official Receipt No.</b>
                    </td>
                    <td>
                      <b>ORP {orid ? addLeadingZeros(orid) : 0}</b>
                    </td>
                    <td>
                      <Link
                        target={"_blank"}
                        to={`/eias/reports/printORP/${orid}`}
                      >
                        <Button
                          icon="pi pi-print"
                          type="button"
                          label="Print"
                          className="width_8_em"
                        ></Button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}
