import axios from "axios";
import { addYears, subDays } from "date-fns";
import { getDocument } from "pdfjs-dist";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { Toast } from "primereact/toast";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClientVehicleInfoContext from "../../../../context/ClientVehicleProvider";
import AlterClientInfo from "./Tab/AlterClientInfo";
import BillingInfo from "./Tab/BillingInfo";
import ClientInfo from "./Tab/ClientInfo";
import MotorInfo from "./Tab/MotorInfo";
import NonMotorInfo from "./Tab/NonMotorInfo";
import PremiumInfo from "./Tab/PremiumInfo";
import SupSvcInfo from "./Tab/SupSvcInfo";
import "./css/custom.css";
import "./css/nav.css";
import "./css/table.css";

export default function NewClientVehicle() {
  const toast = useRef(null);
  const [key, setKey] = useState("client");

  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const { id } = useParams();
  const location = useLocation();
  const [viewOnly, setViewOnly] = useState(false);

  const handleKeyPress = useCallback((event) => {
    let keysPressed = {};

    keysPressed[event.key] = true;
    if (keysPressed["Alt"] && event.key === "c") {
      setKey("client");
    }
    if (keysPressed["Alt"] && event.key === "o") {
      setKey("motor");
    }
    if (keysPressed["Alt"] && event.key === "n") {
      setKey("non_motor_medical");
    }
    if (keysPressed["Alt"] && event.key === "u") {
      setKey("sup_svc");
    }
    if (keysPressed["Alt"] && event.key === "p") {
      setKey("premium");
    }
    if (keysPressed["Alt"] && event.key === "b") {
      setKey("billing");
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (location.pathname !== "/eias/enquires/create") {
      axios
        .get(`${process.env.REACT_APP_API_URL}/enquires/get_id_enquires/${id}`)
        .then((result) => {
          var object_ori = result.data;
          var object = {};

          Object.keys(object_ori).forEach(function (key) {
            if (object_ori[key] !== null) {
              object[key] = object_ori[key];
            } else {
              object[key] = "";
            }
          });

          object.get_status = object.status;

          var all = document.querySelectorAll(".form-control");

          if (
            location.pathname.includes("view") &&
            result.data.status !== "Draft"
          ) {
            for (var i = 0, len = all.length; i < len; i++) {
              all[i].disabled = true;
            }
            setKey("billing");
            setViewOnly(true);
          } else if (
            location.pathname.includes("view") &&
            result.data.status === "Draft"
          ) {
            setViewOnly(false);
          } else {
            // for (var y = 0, get_len_y = all.length; y < get_len_y; y++) {
            //   all[i].disabled = false;
            // }
            object.motor_Cover_Note = "";
            object.non_motor_Cover_Note = "";
            object.motor_Datepicker_1 = new Date();

            if (new Date() > object.motor_Datepicker_2) {
              object.motor_Datepicker_2 = new Date();
              object.motor_Datepicker_3 = subDays(
                addYears(new Date(object.motor_Datepicker_2), 1),
                1
              );
            } else {
              object.motor_Datepicker_2 = addYears(
                new Date(object.motor_Datepicker_2),
                1
              );
              object.motor_Datepicker_3 = subDays(
                addYears(new Date(object.motor_Datepicker_2), 1),
                1
              );
            }

            object.non_motor_Datepicker_4 = new Date();
            object.non_motor_Datepicker_5 = addYears(
              new Date(object.non_motor_Datepicker_5),
              1
            );
            object.non_motor_Datepicker_6 = subDays(
              addYears(new Date(object.non_motor_Datepicker_5), 1),
              1
            );

            object.sup_svc_Datepicker_7 = new Date();
            setViewOnly(false);

            if (location.pathname.includes("new")) {
              object.get_status = "Draft";
              object.status = "Draft";
              object.credit_notes = "";
              object.debit_notes = "";
              object.getpayment = "";
              object.orpayment = "";
              object.spayment = "";
              object.ssn = "";
            }
          }
          setClientVehicleInfo(object);
        });
    } else {
      setKey("client");
      setClientVehicleInfo({
        // Clients Detail
        name: "",
        nric: "",
        address: "",
        phone_number_1: "",
        phone_number_2: "",
        email: "",
        phone_number_3: "",
        select_ins_principal: "LIBERTY",
        select_biz_partner: "YST",
        vehicle_no: "",

        // Motor Detail
        motor_Cover_Note: "",
        motor_Datepicker_1: new Date(),
        motor_Select_Action: "NEW",
        motor_Datepicker_2: new Date(),
        motor_Datepicker_3: new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).setFullYear(
            new Date().getFullYear() + 1
          )
        ).setDate(new Date().getDate() - 1),
        motor_Select_Cover_Type: "COMPREHENSIVE",
        motor_Partner_Commission_Rate: "0",
        motor_Select_Class: "PRIVATE CAR",
        motor_CC: "",
        motor_Year: "",
        motor_Seating: "",
        motor_Principal_Tariff: "0",
        motor_Make_Model: "",
        motor_Type_Of_Body: "",
        motor_HP_Co: "",
        motor_Remarks: "",

        // Non-Motor Detail
        non_motor_Cover_Note: "",
        non_motor_Datepicker_4: new Date(),
        non_motor_Select_Action: "NEW",
        non_motor_Datepicker_5: new Date(),
        non_motor_Datepicker_6: new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).setFullYear(
            new Date().getFullYear() + 1
          )
        ).setDate(new Date().getDate() - 1),
        non_motor_Class_of_Ins: "",
        non_motor_Principal_Tariff: "0.00",
        non_motor_Partner_Commission: "0.00",
        non_motor_Description: "",
        non_motor_RM_Per_Room: "0.00",
        non_motor_Sum_Insured_RM: "0.00",
        non_motor_Basic_Premium: "0.00",
        non_motor_Govt_Tax: "8",
        non_motor_Total: "0.00",
        non_motor_Stamp_Duty: "0.00",
        non_motor_Remarks: "",
        non_motor_Other_Charges: "0.00",
        non_motor_Total_Premium: "0.00",
        non_motor_Principal_Commission: "0.00",
        non_motor_Gross_Premium: "0.00",

        // Supporting Services
        sup_svc_Datepicker_7: new Date(),
        sup_svc_Road_Tax_Months: "12",
        sup_svc_Road_Tax_Charges: "0.00",
        sup_svc_Road_Tax_JPJ_Runner: "0.00",
        sup_svc_Road_Tax_Revenue: "0.00",
        sup_svc_License_Years: "1",
        sup_svc_License_Charges: "0.00",
        sup_svc_License_JPJ_Runner: "0.00",
        sup_svc_License_Revenue: "0.00",
        sup_svc_JPJ_Renewal: "ROAD TAX RENEWAL CHARGE",
        sup_svc_JPJ_Renewal_Charges: "0.00",
        sup_svc_JPJ_Renewal_JPJ_Runner: "0.00",
        sup_svc_JPJ_Renewal_Revenue: "0.00",
        sup_svc_Others_1: "",
        sup_svc_Others_1_Charges: "0.00",
        sup_svc_Others_1_JPJ_Runner: "0.00",
        sup_svc_Others_1_Revenue: "0.00",
        sup_svc_Others_2: "",
        sup_svc_Others_2_Charges: "0.00",
        sup_svc_Others_2_JPJ_Runner: "0.00",
        sup_svc_Others_2_Revenue: "0.00",
        sup_svc_Others_3: "",
        sup_svc_Others_3_Charges: "0.00",
        sup_svc_Others_3_JPJ_Runner: "0.00",
        sup_svc_Others_3_Revenue: "0.00",
        sup_svc_Total_Charges: "0.00",
        sup_svc_Total_JPJ_Runner: "0.00",
        sup_svc_Total_Revenue: "0.00",

        // Premium
        // Motor Premium
        premium_Sum_Insured: "0",
        premium_Excess: "0",
        premium_Basic_Premium: "0.00",
        premium_Trailer_Schedule_Premium: "0.00",
        premium_All_Rider_Percentage: "0",
        premium_All_Rider: "0.00",
        premium_Sub_Total_1: "0.00",
        premium_Loading_Charge_Percentage: "0",
        premium_Loading_Charge: "0.00",
        premium_Sub_Total_2: "0.00",
        premium_NCD_Percentage: "0",
        premium_NCD: "0.00",
        premium_Extra_Benefits: "0.00",
        premium_Service_Tax_Percentage: "8",
        premium_Service_Tax: "0.00",
        premium_Stamp_Duty: "10.00",
        premium_Transfer_Fees: "0.00",
        premium_Total_Motor_Premium: "0.00",

        // Supporting Services Premium
        premium_Roadtax_Total: "0.00",
        premium_License_Total: "0.00",
        premium_JPJ_Renewal: "0.00",
        premium_Others_Total: "0.00",
        premium_Total_Supporting_Services: "0.00",

        // Total Amount Payable
        premium_Total_Amount_Payable: "0.00",
        premium_Gross_Premium: "0.00",
        premium_Principal_Commission: "0.00",
        premium_Net_Premium: "0.00",

        //Extra Benefits
        premium_Extra_Benefits_Windscreen_Cover_Value: "0.00",
        premium_Extra_Benefits_Windscreen_Cover_Total: "0.00",
        premium_Extra_Benefits_Legal_Liability_to_Passengers_Checkbox: "",
        premium_Extra_Benefits_Legal_Liability_to_Passengers_Value: "0",
        premium_Extra_Benefits_Legal_Liability_to_Passengers_Total: "0.00",
        premium_Extra_Benefits_Act_of_Negligence_Checkbox: "",
        premium_Extra_Benefits_Act_of_Negligence_Total: "0.00",
        premium_Extra_Benefits_Strike_Commotion_Cover_Checkbox: "",
        premium_Extra_Benefits_Strike_Commotion_Cover_Total: "0.00",
        premium_Extra_Benefits_All_Drivers_Checkbox: "",
        premium_Extra_Benefits_All_Drivers_Total: "0.00",
        premium_Extra_Benefits_All_Drivers_Inv_Checkbox: "",
        premium_Extra_Benefits_All_Drivers_Inv_Total: "0.00",
        premium_Extra_Benefits_Convulsion_of_Nature_Checkbox: "",
        premium_Extra_Benefits_Convulsion_of_Nature_Total: "0.00",
        premium_Extra_Benefits_Additional_Driver_Named: "",
        premium_Extra_Benefits_Additional_Driver_Total: "0.00",
        premium_Extra_Benefits_RadioCasette_Value: "0.00",
        premium_Extra_Benefits_RadioCasette_Total: "0.00",
        premium_Extra_Benefits_Insurance_Protection_Total: "0.00",
        premium_Extra_Benefits_Inclusion_of_Damage_Total: "0.00",
        premium_Extra_Benefits_Inclusion_of_Accidental_Total: "0.00",
        premium_Extra_Benefits_Extension_of_Cover_Total: "0.00",
        premium_Extra_Benefits_Generali_MCPA_Total: "0.00",
        premium_Extra_Benefits_Unlimited_Towing_Total: "0.00",
        premium_Extra_Benefits_Other_1_Value: "",
        premium_Extra_Benefits_Other_1_Total: "0.00",
        premium_Extra_Benefits_Other_2_Value: "",
        premium_Extra_Benefits_Other_2_Total: "0.00",
        premium_Extra_Benefits_Other_3_Value: "",
        premium_Extra_Benefits_Other_3_Total: "0.00",
        premium_Extra_Benefits_Total: "0.00",

        //Billing
        billing_Motor_Premium: "0.00",
        billing_Discount: "0.00",
        billing_non_motor_Premium: "0.00",
        billing_Discount_Non_Motor: "0.00",
        billing_Supporting_Services: "0.00",
        billing_Subtotal: "0.00",
        billing_Subtotal_Discount: "0.00",
        billing_Total_Payable: "0.00",
        orpayment: "",
        status: "Draft",
        get_status: "Draft",
        ssn: [],
        third_party_premium: "0.00",
      });
      var all = document.querySelectorAll(".form-control");
      for (var i = 0, len = all.length; i < len; i++) {
        all[i].disabled = false;
      }
      setViewOnly(false);
    }
    // eslint-disable-next-line
  }, [id, location]);

  let navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (clientVehicleInfo.name === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Name Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (clientVehicleInfo.nric === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>NRIC Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (clientVehicleInfo.vehicle_no === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Vehicle No. Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.motor_Cover_Note === "" &&
      clientVehicleInfo.non_motor_Cover_Note === "" &&
      clientVehicleInfo.sup_svc_Total_Charges === "0.00"
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Motor Cover No Field</b> or &nbsp;
            <b>Non Motor Cover No Field</b> or &nbsp;
            <b>Sup Svc Charges Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.select_ins_principal === "" &&
      clientVehicleInfo.sup_svc_Total_Charges === "0.00"
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Principal Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (clientVehicleInfo.select_biz_partner === "") {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Biz Partner Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.motor_Cover_Note === "" &&
      Math.abs(clientVehicleInfo.premium_Total_Motor_Premium) > 0
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Motor Cover No Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.non_motor_Cover_Note === "" &&
      Math.abs(clientVehicleInfo.non_motor_Total_Premium) > 0
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Field",
        detail: (
          <span>
            <b>Non Motor Cover No Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else if (
      clientVehicleInfo.billing_Total_Payable === "" ||
      isNaN(clientVehicleInfo.billing_Total_Payable) === true ||
      clientVehicleInfo.billing_Total_Payable === "0.00"
    ) {
      toast.current.show({
        severity: "error",
        summary: "Empty Total Payable",
        detail: (
          <span>
            <b>Total Payable Field</b> is Empty
          </span>
        ),
        life: 3000,
      });
    } else {
      createClientVehicle();
    }
  };

  const createClientVehicle = async (e) => {
    var object = clientVehicleInfo;
    var format_ClientVehicleInfo = {};

    Object.keys(object).forEach(function (key) {
      if (object[key] !== "" && object[key] !== undefined) {
        const value = object[key];
        if (typeof value === "string") {
          format_ClientVehicleInfo[key] = value.trim();
        } else {
          format_ClientVehicleInfo[key] = value;
        }
      }
    });

    format_ClientVehicleInfo["name"] =
      format_ClientVehicleInfo["name"].toUpperCase();

    format_ClientVehicleInfo["status"] = format_ClientVehicleInfo["get_status"];

    format_ClientVehicleInfo["balance"] =
      format_ClientVehicleInfo["billing_Total_Payable"];

    if (format_ClientVehicleInfo.status === "Issued") {
      format_ClientVehicleInfo["debit_notes"] = [
        {
          motor_datepicker_1: format_ClientVehicleInfo.motor_Datepicker_1,
          non_motor_datepicker_4:
            format_ClientVehicleInfo.non_motor_Datepicker_4,
        },
      ];
    }

    if (
      format_ClientVehicleInfo.status === "Issued" &&
      (format_ClientVehicleInfo.billing_Discount > 0 ||
        format_ClientVehicleInfo.billing_Discount_Non_Motor > 0)
    ) {
      format_ClientVehicleInfo["credit_notes"] = [
        {
          motor_datepicker_1: format_ClientVehicleInfo.motor_Datepicker_1,
          non_motor_datepicker_4:
            format_ClientVehicleInfo.non_motor_Datepicker_4,
        },
      ];
    }

    if (
      format_ClientVehicleInfo.status === "Issued" &&
      format_ClientVehicleInfo.sup_svc_Total_Charges > 0
    ) {
      format_ClientVehicleInfo["ssn"] = [
        {
          sup_svc_Datepicker_7: format_ClientVehicleInfo.sup_svc_Datepicker_7,
        },
      ];
    }

    if (location.pathname.includes("new")) {
      delete format_ClientVehicleInfo.cv_id;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/enquires/create_new_enquires`,
        JSON.stringify(format_ClientVehicleInfo),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const cv_id = response?.data.cv_id;
      const new_client = response?.data.name;
      const new_vehicle = response?.data.vehicle_no;
      const err = response?.data.error;

      if (err === undefined) {
        toast.current.show({
          severity: "success",
          summary: "New Client/Vehicle Created",
          detail: (
            <span>
              New Client: <b>{new_client}</b>
              <br></br>
              New Vehicle: <b>{new_vehicle}</b>
              <br></br> Records Created Successfully
            </span>
          ),
          life: 3000,
        });
        navigate(`/eias/enquires/${cv_id}/view`);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed to Create Client/Vehicle",
          detail: (
            <span>
              <b>{err.replace(/_/g, " ")}</b>
            </span>
          ),
          life: 3000,
        });
      }
    } else if (location.pathname.includes("view")) {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/enquires/update-enquires/${id}`,
        JSON.stringify(format_ClientVehicleInfo),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const cv_id = response?.data.cv_id;
      const new_client = response?.data.name;
      const new_vehicle = response?.data.vehicle_no;
      const err = response?.data.error;

      if (err === undefined) {
        toast.current.show({
          severity: "info",
          summary: "Client/Vehicle Updated",
          detail: (
            <span>
              Client: <b>{new_client}</b>
              <br></br>
              Vehicle: <b>{new_vehicle}</b>
              <br></br> Records Updated Successfully
            </span>
          ),
          life: 3000,
        });
        navigate(`/eias/enquires/${cv_id}/view`);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed to Create Client/Vehicle",
          detail: (
            <span>
              <b>{err.replace(/_/g, " ")}</b>
            </span>
          ),
          life: 3000,
        });
      }
    } else {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/enquires/create_new_enquires`,
        JSON.stringify(format_ClientVehicleInfo),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const cv_id = response?.data.cv_id;
      const new_client = response?.data.name;
      const new_vehicle = response?.data.vehicle_no;
      const err = response?.data.error;

      if (err === undefined) {
        toast.current.show({
          severity: "success",
          summary: "New Client/Vehicle Created",
          detail: (
            <span>
              New Client: <b>{new_client}</b>
              <br></br>
              New Vehicle: <b>{new_vehicle}</b>
              <br></br> Records Created Successfully
            </span>
          ),
          life: 3000,
        });
        navigate(`/eias/enquires/${cv_id}/view`);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed to Create Client/Vehicle",
          detail: (
            <span>
              <b>{err.replace(/_/g, " ")}</b>
            </span>
          ),
          life: 3000,
        });
      }
    }

    // eslint-disable-next-line
    const responseClient = await axios.post(
      `${process.env.REACT_APP_API_URL}/clients/create_new_clients`,
      JSON.stringify(clientVehicleInfo),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    // eslint-disable-next-line
    const responseVehicle = await axios.post(
      `${process.env.REACT_APP_API_URL}/vehicles/create_new_vehicles`,
      JSON.stringify(clientVehicleInfo),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
  };

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchPdfText = async () => {
      if (!selectedFile) return;

      try {
        const reader = new FileReader();
        reader.onload = async function (event) {
          const fileName = selectedFile.name;
          const pdfData = new Uint8Array(event.target.result);
          const pdf = await getDocument({ data: pdfData }).promise;

          const pageNumber = 1;
          const page = await pdf.getPage(pageNumber);
          const textContent = await page.getTextContent();
          const pdfContent = textContent.items;
          if (fileName.includes("KNMA")) {
            setClientVehicleInfo({
              ...clientVehicleInfo,
              // Clients Detail
              name: pdfContent[52].str,
              nric: pdfContent[60].str,
              address:
                pdfContent[68].str +
                "\n" +
                pdfContent[70].str +
                " " +
                pdfContent[72].str +
                "\n" +
                pdfContent[74].str,
              vehicle_no: pdfContent[80].str,

              // Motor Detail
              motor_Cover_Note: pdfContent[8].str,
              motor_Datepicker_2: new Date(pdfContent[142].str),
              motor_Datepicker_3: new Date(pdfContent[146].str),
              motor_Select_Cover_Type: pdfContent[120].str,
              motor_CC: pdfContent[104].str,
              motor_Year: pdfContent[126].str,
              motor_Seating: pdfContent[100].str,
              motor_Make_Model: pdfContent[96].str,
              motor_Type_Of_Body: pdfContent[116].str,

              // Non-Motor Detail
              non_motor_Datepicker_5: new Date(pdfContent[142].str),
              non_motor_Datepicker_6: new Date(pdfContent[146].str),
              // Premium
              // Motor Premium
              premium_Sum_Insured: pdfContent[84].str.replace(/,/g, ""),
              premium_Basic_Premium: pdfContent[170].str.replace(/,/g, ""),
            });
          } else if (fileName.includes("AEKG")) {
            setClientVehicleInfo({
              ...clientVehicleInfo,
              // Clients Detail
              name: pdfContent[73].str,
              nric: pdfContent[177].str,
              address:
                pdfContent[75].str +
                "\n" +
                pdfContent[76].str +
                "\n" +
                pdfContent[77].str,
              vehicle_no: pdfContent[163].str,

              // Motor Detail
              motor_Cover_Note: pdfContent[39].str,
              motor_Datepicker_2: new Date(pdfContent[139].str),
              motor_Datepicker_3: new Date(pdfContent[142].str),
              motor_Select_Cover_Type: pdfContent[115].str,
              motor_CC: pdfContent[113].str.replace(" CC", ""),
              motor_Year: pdfContent[85].str,
              motor_Seating: pdfContent[83].str,
              motor_Make_Model: pdfContent[116].str,
              motor_Type_Of_Body: pdfContent[116].str,

              // Non-Motor Detail
              non_motor_Datepicker_5: new Date(pdfContent[146].str),
              non_motor_Datepicker_6: new Date(pdfContent[149].str),
              // Premium
              // Motor Premium
              premium_Sum_Insured: pdfContent[105].str.replace(/,/g, ""),
              premium_Basic_Premium: pdfContent[110].str.replace(/,/g, ""),
            });
          } else if (fileName.includes("JVE")) {
            setClientVehicleInfo({
              ...clientVehicleInfo,
              // Clients Detail
              name: pdfContent[25].str,
              nric: pdfContent[68].str,
              address:
                pdfContent[115].str +
                "\n" +
                pdfContent[116].str +
                "\n" +
                pdfContent[117].str,
              vehicle_no: pdfContent[31].str,

              // Motor Detail
              motor_Cover_Note: pdfContent[134].str,
              motor_Datepicker_2: new Date(pdfContent[75].str),
              motor_Datepicker_3: new Date(pdfContent[81].str),
              motor_Select_Cover_Type: pdfContent[122].str,
              motor_CC: pdfContent[57].str.replace(" CC", ""),
              motor_Year: pdfContent[59].str,
              motor_Seating: pdfContent[64].str,
              motor_Make_Model: pdfContent[61].str + pdfContent[63].str,
              motor_Type_Of_Body: pdfContent[61].str + pdfContent[63].str,

              // Non-Motor Detail
              non_motor_Datepicker_5: new Date(pdfContent[75].str),
              non_motor_Datepicker_6: new Date(pdfContent[81].str),
              // Premium
              // Motor Premium
              premium_Sum_Insured: pdfContent[229].str.replace(/,/g, ""),
              premium_Basic_Premium: pdfContent[269].str.replace(/,/g, ""),
            });
          }
        };
        reader.readAsArrayBuffer(selectedFile);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };

    fetchPdfText();
  }, [selectedFile]);

  const onUpload = (event) => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });

    setSelectedFile(event.target.files[0]);
  };

  return (
    <section className="pt-2 pb-5 bg-theme">
      <b>
        <div className="card m-4 bg-theme-light">
          <div className="card-body">
            <div className="card-title">
              <Toast ref={toast} />
              <ConfirmDialog />
              <h2 className="text-start">Client</h2>
            </div>
            <div className="card-text text-start">
              <form onSubmit={(e) => onSubmit(e)}>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3 nav nav-tabs tabs-dark bg-dark"
                >
                  <Tab
                    eventKey="client"
                    title={
                      <span>
                        <u>C</u>lient
                      </span>
                    }
                  >
                    <AlterClientInfo />
                    <ClientInfo view={viewOnly} />
                    <div className="file-input float-start mt-3">
                      <input
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"
                        onChange={onUpload}
                      />
                      <label className="file-input__label" htmlFor="file-input">
                        <span>
                          Upload PDF <i className="pi pi-file-pdf"></i>
                        </span>
                      </label>
                    </div>
                    <Button
                      type="button"
                      className="float-end mt-3"
                      label="Next"
                      icon="pi pi-angle-double-right"
                      iconPos="right"
                      onClick={() => setKey("motor")}
                    />
                    {/* pi-angle-double-right */}
                  </Tab>
                  {/* Start of Motor Tab */}
                  {(() => {
                    if (
                      location.pathname.includes("view") &&
                      clientVehicleInfo.motor_Cover_Note === "" &&
                      clientVehicleInfo.status !== "Draft"
                    ) {
                      return <React.Fragment></React.Fragment>;
                    } else {
                      return (
                        <Tab
                          eventKey="motor"
                          title={
                            <span>
                              M<u>o</u>tor
                            </span>
                          }
                        >
                          <AlterClientInfo />
                          <MotorInfo view={viewOnly} />
                          {/* Start of Get Next Tab */}
                          {(() => {
                            if (
                              location.pathname.includes("view") &&
                              clientVehicleInfo.non_motor_Cover_Note === "" &&
                              clientVehicleInfo.sup_svc_Total_Charges ===
                                "0.00" &&
                              clientVehicleInfo.status !== "Draft"
                            ) {
                              return (
                                <React.Fragment>
                                  <Button
                                    type="button"
                                    className="float-end mt-3"
                                    label="Next"
                                    icon="pi pi-angle-double-right"
                                    iconPos="right"
                                    onClick={() => setKey("premium")}
                                  />
                                </React.Fragment>
                              );
                            } else if (
                              location.pathname.includes("view") &&
                              clientVehicleInfo.non_motor_Cover_Note !== "" &&
                              clientVehicleInfo.sup_svc_Total_Charges ===
                                "0.00" &&
                              clientVehicleInfo.status !== "Draft"
                            ) {
                              return (
                                <React.Fragment>
                                  <Button
                                    type="button"
                                    className="float-end mt-3"
                                    label="Next"
                                    icon="pi pi-angle-double-right"
                                    iconPos="right"
                                    onClick={() => setKey("non_motor_medical")}
                                  />
                                </React.Fragment>
                              );
                            } else if (
                              location.pathname.includes("view") &&
                              clientVehicleInfo.non_motor_Cover_Note === "" &&
                              clientVehicleInfo.sup_svc_Total_Charges !==
                                "0.00" &&
                              clientVehicleInfo.status !== "Draft"
                            ) {
                              return (
                                <React.Fragment>
                                  <Button
                                    type="button"
                                    className="float-end mt-3"
                                    label="Next"
                                    icon="pi pi-angle-double-right"
                                    iconPos="right"
                                    onClick={() => setKey("sup_svc")}
                                  />
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <React.Fragment>
                                  <Button
                                    type="button"
                                    className="float-end mt-3"
                                    label="Next"
                                    icon="pi pi-angle-double-right"
                                    iconPos="right"
                                    onClick={() => setKey("non_motor_medical")}
                                  />
                                </React.Fragment>
                              );
                            }
                          })()}
                          {/* End of Get Next Tab */}
                        </Tab>
                      );
                    }
                  })()}
                  {/* End of Motor Tab */}

                  {/* Start of Non Motor Tab */}
                  {(() => {
                    if (
                      location.pathname.includes("view") &&
                      clientVehicleInfo.non_motor_Cover_Note === "" &&
                      clientVehicleInfo.status !== "Draft"
                    ) {
                      return <React.Fragment></React.Fragment>;
                    } else {
                      return (
                        <Tab
                          eventKey="non_motor_medical"
                          title={
                            <span>
                              <u>N</u>on-Motor/Medical
                            </span>
                          }
                        >
                          <AlterClientInfo />
                          <NonMotorInfo view={viewOnly} />
                          {/* Start of Get Next Tab */}
                          {(() => {
                            if (
                              location.pathname.includes("view") &&
                              clientVehicleInfo.sup_svc_Total_Charges ===
                                "0.00" &&
                              clientVehicleInfo.status !== "Draft"
                            ) {
                              return (
                                <React.Fragment>
                                  <div className="w-75">
                                    <Button
                                      type="button"
                                      className="float-end mt-3"
                                      label="Next"
                                      icon="pi pi-angle-double-right"
                                      iconPos="right"
                                      onClick={() => setKey("premium")}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <React.Fragment>
                                  <div className="w-75">
                                    <Button
                                      type="button"
                                      className="float-end mt-3"
                                      label="Next"
                                      icon="pi pi-angle-double-right"
                                      iconPos="right"
                                      onClick={() => setKey("sup_svc")}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            }
                          })()}
                          {/* End of Get Next Tab */}
                        </Tab>
                      );
                    }
                  })()}
                  {/* End of Non Motor Tab */}

                  {/* Start of Sup Svc Tab */}
                  {(() => {
                    if (
                      location.pathname.includes("view") &&
                      clientVehicleInfo.sup_svc_Total_Charges === "0.00" &&
                      clientVehicleInfo.status !== "Draft"
                    ) {
                      return <React.Fragment></React.Fragment>;
                    } else {
                      return (
                        <Tab
                          eventKey="sup_svc"
                          title={
                            <span>
                              S<u>u</u>p.Svc
                            </span>
                          }
                        >
                          <AlterClientInfo />
                          <SupSvcInfo view={viewOnly} />
                          <div className="width_80">
                            <Button
                              type="button"
                              className="float-end mt-3"
                              label="Next"
                              icon="pi pi-angle-double-right"
                              iconPos="right"
                              onClick={() => setKey("premium")}
                            />
                          </div>
                        </Tab>
                      );
                    }
                  })()}
                  {/* End of Sup Svc Tab */}
                  <Tab
                    eventKey="premium"
                    title={
                      <span>
                        <u>P</u>remium
                      </span>
                    }
                  >
                    <AlterClientInfo />
                    <PremiumInfo />
                    <div className="w-75">
                      <Button
                        type="button"
                        className="float-end mt-3"
                        label="Next"
                        icon="pi pi-angle-double-right"
                        iconPos="right"
                        onClick={() => setKey("billing")}
                      />
                    </div>
                  </Tab>
                  <Tab
                    eventKey="billing"
                    title={
                      <span>
                        <u>B</u>illing
                      </span>
                    }
                  >
                    <AlterClientInfo />
                    <BillingInfo />
                  </Tab>
                </Tabs>
              </form>
            </div>
          </div>
        </div>
      </b>
    </section>
  );
}
