import axios from "axios";
import { format, parseISO } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import "./datatable.css";

export default function ExistingClientVehicle() {
  // Start of datatable props
  const [clientVehicle, setClientVehicle] = useState([]);
  const dt = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  // End of datatable props

  // Start of Search filter Value
  const [searchName, setSearchName] = useState("");
  const [searchNRIC, setSearchNRIC] = useState("");
  const [searchVehicleNo, setSearchVehicleNo] = useState("");
  const [searchPrincipal, setSearchPrincipal] = useState("");
  const [searchPartner, setSearchPartner] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchCoverNote, setSearchCoverNote] = useState("");
  const [searchSSN, setSearchSSN] = useState("");
  const buttonSearch = useRef(null);
  const [lazyState, setlazyState] = useState({
    sortField: "name",
    sortOrder: 1,
  });
  // End of Search filter Value

  // Start of Dropdown Options
  const [principalDropdown, setPrincipalDropdown] = useState([]);
  const [bizPartnerDropdown, setBizPartnerDropdown] = useState([]);

  const statusDropdown = [
    { name: "ALL" },
    { name: "Draft" },
    { name: "Issued" },
    { name: "Closed" },
    { name: "Submitted" },
    { name: "Canceled" },
  ];

  useEffect(() => {
    loadPrincipal_BizPartner();
  }, []);

  const loadPrincipal_BizPartner = async (e) => {
    const result_principal = await axios.get(
      `${process.env.REACT_APP_API_URL}/principal/get_all_principal`
    );

    // Start of sort a to b after pushing "ALL"
    result_principal.data.push({ code: " ALL" });
    result_principal.data = result_principal.data.sort(function (a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });

    const result_Biz = await axios.get(
      `${process.env.REACT_APP_API_URL}/biz_partner/get_all_biz_partner`
    );
    result_Biz.data.push({ code: " ALL" });
    result_Biz.data = result_Biz.data.sort(function (a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    // End of sort a to b after pushing "ALL"
    setBizPartnerDropdown(result_Biz.data);
    setPrincipalDropdown(result_principal.data);
  };
  // End of Dropdown Options

  // Start of addLeadingZeros function
  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }
  // End of addLeadingZeros function

  // Start of onSort -> set value and retrigger get method
  const onSort = (event) => {
    setlazyState(event);
  };

  useEffect(() => {
    loadClientVehicle();
    // eslint-disable-next-line
  }, [lazyState]);
  // End of onSort -> set value and retrigger get method

  // Start of Load Data to Datatable with filter search
  useEffect(() => {
    loadClientVehicle();
    // eslint-disable-next-line
  }, [first, rows]);

  const loadClientVehicle = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/enquires/get_all_enquires_pages`, {
        params: {
          page: page,
          size: rows,
          name: searchName.trim(),
          nric: searchNRIC.trim(),
          vehicle_no: searchVehicleNo.trim(),
          principal: searchPrincipal.trim(),
          partner: searchPartner,
          status: searchStatus,
          phone: searchPhone.trim(),
          cover_note: searchCoverNote.trim(),
          ssn: searchSSN.replace("SSN100", ""),
          sort: `${lazyState.sortField},${lazyState.sortOrder === -1 ? "desc" : "asc"}`,
        },
      })
      .then((result) => {
        let result_data = result.data.content;
        for (let i = 0; i < result_data.length; i++) {
          if (result_data[i].motor_Cover_Note !== null) {
            result_data[i].motor_Datepicker_1 = format(
              parseISO(result_data[i].motor_Datepicker_1),
              "dd/MM/yyyy"
            );
          } else if (result_data[i].non_motor_Cover_Note !== null) {
            result_data[i].motor_Datepicker_1 = format(
              parseISO(result_data[i].non_motor_Datepicker_4),
              "dd/MM/yyyy"
            );
          } else if (result_data[i].sup_svc_Total_Charges > 0) {
            result_data[i].motor_Datepicker_1 = format(
              parseISO(result_data[i].sup_svc_Datepicker_7),
              "dd/MM/yyyy"
            );
          }

          result_data[i].ecv_cover_note = result_data[i].motor_Cover_Note
            ? result_data[i].motor_Cover_Note
            : "";
          result_data[i].ecv_cover_note += result_data[i].non_motor_Cover_Note
            ? " " + result_data[i].non_motor_Cover_Note
            : "";
          result_data[i].ecv_cover_note += result_data[i].ssn[0]
            ? " SSN" + addLeadingZeros(result_data[i].ssn[0].ss_id)
            : "";

          result_data[i].name =
            result_data[i].name.toUpperCase() +
            " (" +
            result_data[i].nric +
            ")";
        }
        setClientVehicle(result_data);
        setTotalRecords(result.data.totalElements);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // End of Load Data to Datatable with filter search

  // Start of funtion on key press enter and reset search function
  const handleKeyPress = useCallback((event) => {
    if (event.key === "Enter") {
      buttonSearch.current.click();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const resetSearch = () => {
    window.location.reload();
  };
  // End of funtion on key press enter and reset search function

  // Start of Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // -- Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  // -- Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // -- Datatable Header
  const header = (
    <div className="overflow-auto">
      {/* Start of 1st Row */}
      <div className="container ms-0 ps-0">
        <div className="row row-cols-auto">
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-user"></i>
              </span>
              <InputText
                placeholder="Name"
                value={searchName}
                onChange={(event) => setSearchName(event.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-id-card"></i>
              </span>
              <InputText
                placeholder="NRIC"
                value={searchNRIC}
                onChange={(event) => setSearchNRIC(event.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-car"></i>
              </span>
              <InputText
                placeholder="Vehicle No"
                value={searchVehicleNo}
                onChange={(event) => setSearchVehicleNo(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of 1st Row */}
      {/* Start of 2nd Row */}
      <div className="container ms-0 ps-0 mt-4">
        <div className="row row-cols-auto">
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-briefcase"></i>
              </span>
              <Dropdown
                value={searchPrincipal}
                options={principalDropdown}
                onChange={(event) => setSearchPrincipal(event.target.value)}
                optionLabel="code"
                optionValue="code"
                placeholder="Principal"
                className="custom_padding"
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-users"></i>
              </span>
              <Dropdown
                value={searchPartner}
                options={bizPartnerDropdown}
                onChange={(event) => setSearchPartner(event.target.value)}
                optionLabel="code"
                optionValue="code"
                placeholder="Partner"
                className="custom_padding"
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className="pi pi-file"></i>
              </span>
              <Dropdown
                value={searchStatus}
                options={statusDropdown}
                onChange={(event) => setSearchStatus(event.target.value)}
                optionLabel="name"
                optionValue="name"
                placeholder="Status"
                className="custom_padding"
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of 2nd Row */}
      {/* Start of 3rd Row */}
      <div className="container ms-0 ps-0 mt-4">
        <div className="row row-cols-auto">
        <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-file-pdf"></i>
              </span>
              <InputText
                placeholder="Phone No."
                value={searchPhone}
                onChange={(event) => setSearchPhone(event.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-file-pdf"></i>
              </span>
              <InputText
                placeholder="Cover Note"
                value={searchCoverNote}
                onChange={(event) => setSearchCoverNote(event.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="p-inputgroup me-5">
              <span className="p-inputgroup-addon">
                <i className="pi pi-file-pdf"></i>
              </span>
              <InputText
                placeholder="Sup Svc SSN"
                value={searchSSN}
                onChange={(event) => setSearchSSN(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of 3rd Row */}
      {/* Start of 4th Row */}
      <div className="table-header mt-3">
        <div className="table-header-2">
          <div className="container ms-0 ps-0">
            <Button
              label="Search"
              icon="pi pi-search-plus"
              className="search_clear_button me-4"
              onClick={loadClientVehicle}
              ref={buttonSearch}
            />
            <Button
              label="Clear"
              icon="pi pi-replay"
              className="search_clear_button mt-2"
              onClick={resetSearch}
            />
          </div>
        </div>
        <div>
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button width_8_em"
            onClick={exportCSV}
          />
        </div>
      </div>
      {/* End of 4th Row */}
    </div>
  );

  // -- Start of Datatable custom body template
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Link to={`/eias/enquires/${rowData.cv_id}/view`}>
          <Button
            icon="pi pi-file-edit"
            label="View"
            className="p-button-rounded p-button-info me-2"
          />
        </Link>
        <Link to={`/eias/enquires/${rowData.cv_id}/new`}>
          <Button
            icon="pi pi-plus"
            label="New"
            className="p-button-rounded p-button-success"
          />
        </Link>
      </div>
    );
  };
  // -- End of Datatable custom body template
  // End of Datatable Options

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Existing Client/Vehicle</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              dataKey="cv_id"
              ref={dt}
              value={clientVehicle}
              lazy={true}
              loading={loading}
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              header={header}
              onPage={onCustomPage}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              size="small"
            >
              <Column
                field="name"
                header="Name (NRIC)"
                sortable
                className="w-25"
              ></Column>
              <Column field="vehicle_no" header="Vehicle No" sortable></Column>
              <Column
                field="phone_number_1"
                header="Contact No."
                sortable
              ></Column>
              <Column
                field="motor_Datepicker_1"
                header="Date of Issue"
                sortable
              ></Column>
              <Column
                field="select_ins_principal"
                header="Principal"
                sortable
              ></Column>
              <Column
                field="select_biz_partner"
                header="Biz Partner"
                sortable
              ></Column>
              <Column field="ecv_cover_note" header="Cover No."></Column>
              <Column
                field="billing_Total_Payable"
                header="Amount (RM)"
              ></Column>
              <Column field="status" header="Status" sortable></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                exportable={false}
                style={{ width: "15%" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </section>
  );
}
