import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ClientVehicleInfoContext from "../../../../../context/ClientVehicleProvider";
import ModalBenefits from "./Modal/ModalBenefits";

export default function PremiumInfo() {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const [benefitsModal, setBenefitsModal] = useState(false);

  function openBenefitsModal() {
    setBenefitsModal(!benefitsModal);
  }

  const {
    //Motor Premium
    premium_Sum_Insured,
    premium_Excess,
    premium_Basic_Premium,
    premium_Trailer_Schedule_Premium,
    premium_All_Rider_Percentage,
    premium_All_Rider,
    premium_Sub_Total_1,
    premium_Loading_Charge_Percentage,
    premium_Loading_Charge,
    premium_Sub_Total_2,
    premium_NCD_Percentage,
    premium_NCD,
    premium_Service_Tax_Percentage,
    premium_Service_Tax,
    premium_Stamp_Duty,
    premium_Transfer_Fees,
    premium_Total_Motor_Premium,

    //Non Motor Premium
    non_motor_Basic_Premium,
    non_motor_Govt_Tax,
    non_motor_Total,
    non_motor_Stamp_Duty,
    non_motor_Other_Charges,
    non_motor_Total_Premium,

    //Sup Svc
    premium_Roadtax_Total,
    premium_License_Total,
    premium_JPJ_Renewal,
    premium_Others_Total,
    premium_Total_Supporting_Services,

    //Benefits
    premium_Extra_Benefits_Total,

    //Total
    premium_Total_Amount_Payable,
    premium_Gross_Premium,
    premium_Principal_Commission,
    premium_Net_Premium,
  } = clientVehicleInfo;

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "premium_Basic_Premium") {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        premium_Basic_Premium: e.target.value,
        premium_All_Rider: (
          parseFloat(e.target.value) *
          (parseFloat(premium_All_Rider_Percentage) / 100)
        ).toFixed(2),
      });
    }

    if (e.target.name === "premium_All_Rider") {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        premium_All_Rider: e.target.value,
        premium_All_Rider_Percentage: (
          (parseFloat(e.target.value) / parseFloat(premium_Basic_Premium)) *
          100
        ).toFixed(2),
      });
    }

    if (e.target.name === "premium_All_Rider_Percentage") {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        premium_All_Rider_Percentage: e.target.value,
        premium_All_Rider: (
          parseFloat(premium_Basic_Premium) *
          (parseFloat(e.target.value) / 100)
        ).toFixed(2),
      });
    }

    if (e.target.name === "premium_Loading_Charge") {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        premium_Loading_Charge: e.target.value,
        premium_Loading_Charge_Percentage: (
          (parseFloat(e.target.value) / parseFloat(premium_Sub_Total_1)) *
          100
        ).toFixed(2),
      });
    }

    if (e.target.name === "premium_Loading_Charge_Percentage") {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        premium_Loading_Charge_Percentage: e.target.value,
        premium_Loading_Charge: (
          parseFloat(premium_Sub_Total_1) *
          (parseFloat(e.target.value) / 100)
        ).toFixed(2),
      });
    }
  };

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      premium_Sub_Total_1: (
        parseFloat(premium_Basic_Premium) +
        parseFloat(premium_Trailer_Schedule_Premium) +
        parseFloat(premium_All_Rider)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [
    premium_Basic_Premium,
    premium_Trailer_Schedule_Premium,
    premium_All_Rider,
  ]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      premium_Sub_Total_2: (
        parseFloat(premium_Sub_Total_1) + parseFloat(premium_Loading_Charge)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [premium_Sub_Total_1, premium_Loading_Charge]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      premium_NCD: (
        parseFloat(premium_Sub_Total_2) *
        (parseFloat(premium_NCD_Percentage) / 100)
      ).toFixed(2),

      premium_Gross_Premium: (
        parseFloat(premium_Sub_Total_2) +
        parseFloat(premium_Extra_Benefits_Total) -
        parseFloat(premium_Sub_Total_2) *
          (parseFloat(premium_NCD_Percentage) / 100)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [
    premium_Sub_Total_2,
    premium_Extra_Benefits_Total,
    premium_NCD_Percentage,
  ]);

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      premium_Service_Tax: (
        parseFloat(premium_Gross_Premium) *
        (parseFloat(premium_Service_Tax_Percentage) / 100)
      ).toFixed(2),

      billing_Discount: (
        parseFloat(premium_Gross_Premium) *
        (parseFloat(clientVehicleInfo.motor_Partner_Commission_Rate) / 100)
      ).toFixed(2),

      billing_Discount_Non_Motor: (
        parseFloat(non_motor_Basic_Premium) *
        (parseFloat(clientVehicleInfo.non_motor_Partner_Commission) / 100)
      ).toFixed(2),

      premium_Total_Motor_Premium: (
        parseFloat(premium_Gross_Premium) +
        parseFloat(premium_Stamp_Duty) +
        parseFloat(premium_Transfer_Fees) +
        parseFloat(premium_Gross_Premium) *
          (parseFloat(premium_Service_Tax_Percentage) / 100)
      ).toFixed(2),

      premium_Principal_Commission: (
        parseFloat(premium_Gross_Premium) *
        (parseFloat(clientVehicleInfo.motor_Principal_Tariff) / 100)
      ).toFixed(2),

      premium_Net_Premium: (
        parseFloat(premium_Gross_Premium) +
        parseFloat(premium_Stamp_Duty) +
        parseFloat(premium_Transfer_Fees) +
        parseFloat(premium_Gross_Premium) *
          (parseFloat(premium_Service_Tax_Percentage) / 100) -
        parseFloat(premium_Gross_Premium) *
          (parseFloat(clientVehicleInfo.motor_Principal_Tariff) / 100)
      ).toFixed(2),

      premium_Total_Amount_Payable: (
        parseFloat(premium_Gross_Premium) +
        parseFloat(premium_Stamp_Duty) +
        parseFloat(premium_Transfer_Fees) +
        parseFloat(premium_Gross_Premium) *
          (parseFloat(premium_Service_Tax_Percentage) / 100) +
        parseFloat(non_motor_Total_Premium) +
        parseFloat(premium_Total_Supporting_Services)
      ).toFixed(2),

      //billing info
      billing_Subtotal: (
        parseFloat(premium_Gross_Premium) +
        parseFloat(premium_Stamp_Duty) +
        parseFloat(premium_Transfer_Fees) +
        parseFloat(premium_Gross_Premium) *
          (parseFloat(premium_Service_Tax_Percentage) / 100) +
        parseFloat(non_motor_Total_Premium) +
        parseFloat(premium_Total_Supporting_Services)
      ).toFixed(2),

      billing_Motor_Premium: (
        parseFloat(premium_Gross_Premium) +
        parseFloat(premium_Stamp_Duty) +
        parseFloat(premium_Transfer_Fees) +
        parseFloat(premium_Gross_Premium) *
          (parseFloat(premium_Service_Tax_Percentage) / 100)
      ).toFixed(2),
      billing_non_motor_Premium: parseFloat(non_motor_Total_Premium).toFixed(2),
      billing_Supporting_Services: parseFloat(
        premium_Total_Supporting_Services
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [
    premium_Gross_Premium,
    premium_Stamp_Duty,
    premium_Transfer_Fees,
    premium_Service_Tax_Percentage,
    clientVehicleInfo.motor_Principal_Tariff,
    clientVehicleInfo.motor_Partner_Commission_Rate,
    non_motor_Total_Premium,
    premium_Total_Supporting_Services,
  ]);

  return (
    <div className="overflow-auto mt-4 container_custom">
      <Container>
        <Row>
          <Col sm>
            <div className="overflow-auto">
              <table className="premium_Table" border="primary">
                <tbody>
                  <tr>
                    <td colSpan="4" className="header_Title">
                      Motor Premium
                    </td>
                  </tr>
                  <tr>
                    <td>Sum Insured</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premium_Sum_Insured"
                        value={premium_Sum_Insured}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                    <td>Excess</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premium_Excess"
                        value={premium_Excess}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Basic Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premium_Basic_Premium"
                        value={premium_Basic_Premium}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">+ Trailer Schedule Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premium_Trailer_Schedule_Premium"
                        value={premium_Trailer_Schedule_Premium}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>+ All Rider</td>
                    <td colSpan="2">
                      <div className="d-flex float-end">
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control input_percentage"
                          name="premium_All_Rider_Percentage"
                          value={premium_All_Rider_Percentage}
                          onChange={(e) => onInputChange(e)}
                        />
                        <span className="align-self-center">&nbsp;%</span>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premium_All_Rider"
                        value={premium_All_Rider}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Sub Total 1</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premium_Sub_Total_1"
                        value={premium_Sub_Total_1}
                        onChange={(e) => onInputChange(e)}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td>+ Loading Charge</td>
                    <td colSpan="2">
                      <div className="d-flex float-end">
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control input_percentage"
                          name="premium_Loading_Charge_Percentage"
                          value={premium_Loading_Charge_Percentage}
                          onChange={(e) => onInputChange(e)}
                        />
                        <span className="align-self-center">&nbsp;%</span>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premium_Loading_Charge"
                        value={premium_Loading_Charge}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Sub Total 2</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premium_Sub_Total_2"
                        value={premium_Sub_Total_2}
                        onChange={(e) => onInputChange(e)}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td>- NCD</td>
                    <td colSpan="2">
                      <div className="d-flex float-end">
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control input_percentage"
                          name="premium_NCD_Percentage"
                          value={premium_NCD_Percentage}
                          onChange={(e) => onInputChange(e)}
                        />
                        <span className="align-self-center">&nbsp;%</span>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premium_NCD"
                        value={premium_NCD}
                        onChange={(e) => onInputChange(e)}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">+ Extra Benefits</td>
                    <td className="text-end">
                      <FontAwesomeIcon
                        icon="database"
                        className="ms-2 icon"
                        onClick={openBenefitsModal}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premium_Extra_Benefits_Total"
                        value={premium_Extra_Benefits_Total}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Gross Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premium_Gross_Premium"
                        value={premium_Gross_Premium}
                        onChange={(e) => onInputChange(e)}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td>+ Service Tax</td>
                    <td colSpan="2">
                      <div className="d-flex float-end">
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control input_percentage"
                          name="premium_Service_Tax_Percentage"
                          value={premium_Service_Tax_Percentage}
                          onChange={(e) => onInputChange(e)}
                        />
                        <span className="align-self-center">&nbsp;%</span>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premium_Service_Tax"
                        value={premium_Service_Tax}
                        onChange={(e) => onInputChange(e)}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">+ Stamp Duty</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premium_Stamp_Duty"
                        value={premium_Stamp_Duty}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">+ Transfer Fees</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control"
                        name="premium_Transfer_Fees"
                        value={premium_Transfer_Fees}
                        onChange={(e) => onInputChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <p className="div_bottom_line"></p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Total Motor Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premium_Total_Motor_Premium"
                        value={premium_Total_Motor_Premium}
                        onChange={(e) => onInputChange(e)}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Principal Commission</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premium_Principal_Commission"
                        value={premium_Principal_Commission}
                        onChange={(e) => onInputChange(e)}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Net Premium</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        step={0.01}
                        className="form-control text_background_green"
                        name="premium_Net_Premium"
                        value={premium_Net_Premium}
                        onChange={(e) => onInputChange(e)}
                        readOnly
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-5"></div>
            </div>
          </Col>
          <Col sm>
            <div className="overflow-auto">
              <div className="overflow-auto">
                <table className="premium_Table_Non_Motor" border="primary">
                  <tbody>
                    <tr>
                      <td colSpan="3" className="header_Title">
                        Non Motor / Medical Premium
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">Basic Premium</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control"
                          name="non_motor_Basic_Premium"
                          value={non_motor_Basic_Premium}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>+ Govt Tax</td>
                      <td>
                        <div className="d-flex float-end">
                          <input
                            type="number"
                            min="0"
                            step={0.01}
                            className="form-control input_percentage"
                            name="non_motor_Govt_Tax"
                            value={non_motor_Govt_Tax}
                            onChange={(e) => onInputChange(e)}
                          />
                          <span className="align-self-center">&nbsp;%</span>
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="non_motor_Total"
                          value={non_motor_Total}
                          onChange={(e) => onInputChange(e)}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">+ Stamp Duty</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control"
                          name="non_motor_Stamp_Duty"
                          value={non_motor_Stamp_Duty}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">+ Other Charges</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control"
                          name="non_motor_Other_Charges"
                          value={non_motor_Other_Charges}
                          onChange={(e) => onInputChange(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <p className="div_bottom_line"></p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">Total Non-Motor Premium</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="non_motor_Total_Premium"
                          value={non_motor_Total_Premium}
                          onChange={(e) => onInputChange(e)}
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-5"></div>

              <div className="overflow-auto">
                <table className="premium_Table_Sup_Svc" border="primary">
                  <tbody>
                    <tr>
                      <td colSpan="2" className="header_Title">
                        Supporting Services
                      </td>
                    </tr>
                    <tr>
                      <td>RoadTax Total</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premium_Roadtax_Total"
                          value={premium_Roadtax_Total}
                          onChange={(e) => onInputChange(e)}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>License Total</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premium_License_Total"
                          value={premium_License_Total}
                          onChange={(e) => onInputChange(e)}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>JPJ Renewal</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premium_JPJ_Renewal"
                          value={premium_JPJ_Renewal}
                          onChange={(e) => onInputChange(e)}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Others Total</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premium_Others_Total"
                          value={premium_Others_Total}
                          onChange={(e) => onInputChange(e)}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <p className="div_bottom_line"></p>
                      </td>
                    </tr>
                    <tr>
                      <td>Total Supporting Services</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premium_Total_Supporting_Services"
                          value={premium_Total_Supporting_Services}
                          onChange={(e) => onInputChange(e)}
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-5"></div>

              <div className="overflow-auto">
                <table className="premium_Table_Sup_Svc" border="primary">
                  <tbody>
                    <tr>
                      <td className="pe-4">Total Amount Payable</td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          step={0.01}
                          className="form-control text_background_green"
                          name="premium_Total_Amount_Payable"
                          value={premium_Total_Amount_Payable}
                          onChange={(e) => onInputChange(e)}
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="overflow-auto one"></div>
      <ModalBenefits toggle={benefitsModal} action={openBenefitsModal} />
    </div>
  );
}
