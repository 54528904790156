import React, { useContext, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import ClientVehicleInfoContext from "../../../../../context/ClientVehicleProvider";

export default function SupSvcInfo(props) {
  const { clientVehicleInfo, setClientVehicleInfo } = useContext(
    ClientVehicleInfoContext
  );

  const viewOnly = props.view;

  const {
    sup_svc_Datepicker_7,
    sup_svc_Road_Tax_Months,
    sup_svc_Road_Tax_Charges,
    sup_svc_Road_Tax_JPJ_Runner,
    sup_svc_Road_Tax_Revenue,
    sup_svc_License_Years,
    sup_svc_License_Charges,
    sup_svc_License_JPJ_Runner,
    sup_svc_License_Revenue,
    sup_svc_JPJ_Renewal,
    sup_svc_JPJ_Renewal_Charges,
    sup_svc_JPJ_Renewal_JPJ_Runner,
    sup_svc_JPJ_Renewal_Revenue,
    sup_svc_Others_1,
    sup_svc_Others_1_Charges,
    sup_svc_Others_1_JPJ_Runner,
    sup_svc_Others_1_Revenue,
    sup_svc_Others_2,
    sup_svc_Others_2_Charges,
    sup_svc_Others_2_JPJ_Runner,
    sup_svc_Others_2_Revenue,
    sup_svc_Others_3,
    sup_svc_Others_3_Charges,
    sup_svc_Others_3_JPJ_Runner,
    sup_svc_Others_3_Revenue,
    sup_svc_Total_Charges,
    sup_svc_Total_JPJ_Runner,
    sup_svc_Total_Revenue,
    ssn,
  } = clientVehicleInfo;

  const onInputChange = (e) => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "sup_svc_Road_Tax_Charges") {
      setClientVehicleInfo({
        ...clientVehicleInfo,
        [e.target.name]: e.target.value,
        sup_svc_Road_Tax_JPJ_Runner: e.target.value,
      });
    }
  };

  useEffect(() => {
    setClientVehicleInfo({
      ...clientVehicleInfo,
      sup_svc_Road_Tax_Revenue: (
        parseFloat(sup_svc_Road_Tax_Charges) -
        parseFloat(sup_svc_Road_Tax_JPJ_Runner)
      ).toFixed(2),

      sup_svc_License_Revenue: (
        parseFloat(sup_svc_License_Charges) -
        parseFloat(sup_svc_License_JPJ_Runner)
      ).toFixed(2),

      sup_svc_JPJ_Renewal_Revenue: (
        parseFloat(sup_svc_JPJ_Renewal_Charges) -
        parseFloat(sup_svc_JPJ_Renewal_JPJ_Runner)
      ).toFixed(2),

      sup_svc_Others_1_Revenue: (
        parseFloat(sup_svc_Others_1_Charges) -
        parseFloat(sup_svc_Others_1_JPJ_Runner)
      ).toFixed(2),

      sup_svc_Others_2_Revenue: (
        parseFloat(sup_svc_Others_2_Charges) -
        parseFloat(sup_svc_Others_2_JPJ_Runner)
      ).toFixed(2),

      sup_svc_Others_3_Revenue: (
        parseFloat(sup_svc_Others_3_Charges) -
        parseFloat(sup_svc_Others_3_JPJ_Runner)
      ).toFixed(2),

      sup_svc_Total_Charges: (
        parseFloat(sup_svc_Road_Tax_Charges) +
        parseFloat(sup_svc_License_Charges) +
        parseFloat(sup_svc_JPJ_Renewal_Charges) +
        parseFloat(sup_svc_Others_1_Charges) +
        parseFloat(sup_svc_Others_2_Charges) +
        parseFloat(sup_svc_Others_3_Charges)
      ).toFixed(2),

      sup_svc_Total_JPJ_Runner: (
        parseFloat(sup_svc_Road_Tax_JPJ_Runner) +
        parseFloat(sup_svc_License_JPJ_Runner) +
        parseFloat(sup_svc_JPJ_Renewal_JPJ_Runner) +
        parseFloat(sup_svc_Others_1_JPJ_Runner) +
        parseFloat(sup_svc_Others_2_JPJ_Runner) +
        parseFloat(sup_svc_Others_3_JPJ_Runner)
      ).toFixed(2),

      sup_svc_Total_Revenue: (
        parseFloat(sup_svc_Road_Tax_Charges) +
        parseFloat(sup_svc_License_Charges) +
        parseFloat(sup_svc_JPJ_Renewal_Charges) +
        parseFloat(sup_svc_Others_1_Charges) +
        parseFloat(sup_svc_Others_2_Charges) +
        parseFloat(sup_svc_Others_3_Charges) -
        (parseFloat(sup_svc_Road_Tax_JPJ_Runner) +
        parseFloat(sup_svc_License_JPJ_Runner) +
        parseFloat(sup_svc_JPJ_Renewal_JPJ_Runner) +
        parseFloat(sup_svc_Others_1_JPJ_Runner) +
        parseFloat(sup_svc_Others_2_JPJ_Runner) +
        parseFloat(sup_svc_Others_3_JPJ_Runner))
      ).toFixed(2),

      //premium sup svc
      premium_Roadtax_Total: parseFloat(sup_svc_Road_Tax_Charges).toFixed(2),
      premium_JPJ_Renewal: parseFloat(sup_svc_JPJ_Renewal_Charges).toFixed(2),
      premium_License_Total: parseFloat(sup_svc_License_Charges).toFixed(2),
      premium_Others_Total: (
        parseFloat(sup_svc_Others_1_Charges) +
        parseFloat(sup_svc_Others_2_Charges) +
        parseFloat(sup_svc_Others_3_Charges)
      ).toFixed(2),
      premium_Total_Supporting_Services: (
        parseFloat(sup_svc_Road_Tax_Charges) +
        parseFloat(sup_svc_License_Charges) +
        parseFloat(sup_svc_JPJ_Renewal_Charges) +
        parseFloat(sup_svc_Others_1_Charges) +
        parseFloat(sup_svc_Others_2_Charges) +
        parseFloat(sup_svc_Others_3_Charges)
      ).toFixed(2),
    });
    // eslint-disable-next-line
  }, [
    sup_svc_Road_Tax_Charges,
    sup_svc_Road_Tax_JPJ_Runner,
    sup_svc_License_Charges,
    sup_svc_License_JPJ_Runner,
    sup_svc_JPJ_Renewal_Charges,
    sup_svc_JPJ_Renewal_JPJ_Runner,
    sup_svc_Others_1_Charges,
    sup_svc_Others_1_JPJ_Runner,
    sup_svc_Others_2_Charges,
    sup_svc_Others_2_JPJ_Runner,
    sup_svc_Others_3_Charges,
    sup_svc_Others_3_JPJ_Runner,
  ]);

  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }
  return (
    <div className="overflow-auto mt-4">
      <table className="w-50">
        <tbody>
          {ssn?.length > 0 && (
            <tr>
              <td className="width_8_em">SSN No.</td>
              <td>
                <input
                  type="text"
                  className="form-control width_12_em"
                  name="sup_svc_Road_Tax_Months"
                  value={
                    ssn[0].ss_id ? "SSN" + addLeadingZeros(ssn[0].ss_id) : 0
                  }
                  onChange={(e) => onInputChange(e)}
                  disabled
                />
              </td>
              <td>Issue Date</td>
              <td>
                <Calendar
                  id="icon"
                  name="sup_svc_Datepicker_7"
                  value={new Date(sup_svc_Datepicker_7)}
                  onChange={(e) => onInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_12_em"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  disabled={viewOnly}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <table className="sup_svc_Table">
        <tbody>
          {ssn?.length === [] && (
            <tr>
              <td>Issue Date</td>
              <td>
                <Calendar
                  id="icon"
                  name="sup_svc_Datepicker_7"
                  value={new Date(sup_svc_Datepicker_7)}
                  onChange={(e) => onInputChange(e)}
                  showIcon
                  showButtonBar
                  className="width_full"
                  inputClassName="padding-left"
                  clearButtonClassName="d-none"
                  dateFormat="dd/mm/yy"
                  disabled={viewOnly}
                />
              </td>
            </tr>
          )}
          <tr>
            <td></td>
            <td></td>
            <td>Charges (RM)</td>
            <td></td>
            <td>JPJ/ Runner (RM)</td>
            <td></td>
            <td>Revenue (RM)</td>
          </tr>
          <tr>
            <td className="width_8_em">Road Tax for</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control width_12_em"
                  name="sup_svc_Road_Tax_Months"
                  value={sup_svc_Road_Tax_Months}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">Month(s)</span>
              </div>
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_Road_Tax_Charges"
                value={sup_svc_Road_Tax_Charges}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_Road_Tax_JPJ_Runner"
                value={sup_svc_Road_Tax_JPJ_Runner}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="sup_svc_Road_Tax_Revenue"
                value={sup_svc_Road_Tax_Revenue}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td>License for</td>
            <td>
              <div className="d-flex">
                <input
                  type="number"
                  min="0"
                  className="form-control width_12_em"
                  name="sup_svc_License_Years"
                  value={sup_svc_License_Years}
                  onChange={(e) => onInputChange(e)}
                />
                <span className="align-self-center p-2">Year(s)</span>
              </div>
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_License_Charges"
                value={sup_svc_License_Charges}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_License_JPJ_Runner"
                value={sup_svc_License_JPJ_Runner}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="sup_svc_License_Revenue"
                value={sup_svc_License_Revenue}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td>JPJ Renewal</td>
            <td>
              <input
                type="text"
                className="form-control text_background_green"
                name="sup_svc_JPJ_Renewal"
                value={sup_svc_JPJ_Renewal}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_JPJ_Renewal_Charges"
                value={sup_svc_JPJ_Renewal_Charges}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_JPJ_Renewal_JPJ_Runner"
                value={sup_svc_JPJ_Renewal_JPJ_Runner}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="sup_svc_JPJ_Renewal_Revenue"
                value={sup_svc_JPJ_Renewal_Revenue}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <td>Others 1</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="sup_svc_Others_1"
                value={sup_svc_Others_1}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_Others_1_Charges"
                value={sup_svc_Others_1_Charges}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_Others_1_JPJ_Runner"
                value={sup_svc_Others_1_JPJ_Runner}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="sup_svc_Others_1_Revenue"
                value={sup_svc_Others_1_Revenue}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td>Others 2</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="sup_svc_Others_2"
                value={sup_svc_Others_2}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_Others_2_Charges"
                value={sup_svc_Others_2_Charges}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_Others_2_JPJ_Runner"
                value={sup_svc_Others_2_JPJ_Runner}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="sup_svc_Others_2_Revenue"
                value={sup_svc_Others_2_Revenue}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td>Others 3</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="sup_svc_Others_3"
                value={sup_svc_Others_3}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_Others_3_Charges"
                value={sup_svc_Others_3_Charges}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control"
                name="sup_svc_Others_3_JPJ_Runner"
                value={sup_svc_Others_3_JPJ_Runner}
                onChange={(e) => onInputChange(e)}
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="sup_svc_Others_3_Revenue"
                value={sup_svc_Others_3_Revenue}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>

          <tr>
            <td></td>
            <td className="text_align_right">TOTAL</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="sup_svc_Total_Charges"
                value={sup_svc_Total_Charges}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
            <td>-</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="sup_svc_Total_JPJ_Runner"
                value={sup_svc_Total_JPJ_Runner}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
            <td>=</td>
            <td>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control text_background_green"
                name="sup_svc_Total_Revenue"
                value={sup_svc_Total_Revenue}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
