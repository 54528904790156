import axios from "axios";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./css/print.css";

export default function PrintDN() {
  const [companyProfile, setCompanyProfile] = useState([]);
  const [bizPartner, setBizPartner] = useState([]);
  const [clientVehicleInfo, setClientVehicleInfo] = useState([]);

  const {
    name,
    address,
    phone_number_1,
    phone_number_2,
    phone_number_3,
    vehicle_no,
    motor_Cover_Note,
    motor_Select_Cover_Type,
    non_motor_Cover_Note,
    non_motor_Sum_Insured_RM,
    non_motor_Description,
    non_motor_Total_Premium,
    premium_Sum_Insured,
    premium_Extra_Benefits_Windscreen_Cover_Total,
    premium_Extra_Benefits_Windscreen_Cover_Value,
    premium_Extra_Benefits_Legal_Liability_to_Passengers_Total,
    premium_Extra_Benefits_Act_of_Negligence_Total,
    premium_Extra_Benefits_Strike_Commotion_Cover_Total,
    premium_Extra_Benefits_All_Drivers_Total,
    premium_Extra_Benefits_All_Drivers_Inv_Total,
    premium_Extra_Benefits_Convulsion_of_Nature_Total,
    premium_Extra_Benefits_Additional_Driver_Total,
    premium_Extra_Benefits_RadioCasette_Total,
    premium_Extra_Benefits_Insurance_Protection_Total,
    premium_Extra_Benefits_Inclusion_of_Damage_Total,
    premium_Extra_Benefits_Inclusion_of_Accidental_Total,
    premium_Extra_Benefits_Extension_of_Cover_Total,
    premium_Extra_Benefits_Generali_MCPA_Total,
    premium_Extra_Benefits_Unlimited_Towing_Total,
    premium_Extra_Benefits_Other_1_Total,
    premium_Extra_Benefits_Other_2_Total,
    premium_Extra_Benefits_Other_3_Total,
    premium_Extra_Benefits_Other_1_Value,
    premium_Extra_Benefits_Other_2_Value,
    premium_Extra_Benefits_Other_3_Value,
    premium_Extra_Benefits_Total,
    premium_Total_Motor_Premium,
    sup_svc_Others_1,
    sup_svc_Others_1_Charges,
    sup_svc_Others_2,
    sup_svc_Others_2_Charges,
    sup_svc_Others_3,
    sup_svc_Others_3_Charges,
    sup_svc_Road_Tax_Charges,
    sup_svc_Road_Tax_Months,
    sup_svc_Total_Charges,
    billing_Subtotal,
    debit_notes,
    ssn,
  } = clientVehicleInfo;

  // Load Data to Datatable
  useEffect(() => {
    loadCompanyProfile();
    loadClientVehicle();
    // eslint-disable-next-line
  }, []);

  const loadCompanyProfile = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/company_profile`
    );
    setCompanyProfile(result.data);
  };

  const { id } = useParams();
  const loadClientVehicle = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/enquires/get_id_enquires/${id}`
    );
    let bP = result.data.select_biz_partner;

    const result_bp = await axios.get(
      `${process.env.REACT_APP_API_URL}/biz_partner/get_code_biz_partner/${bP}`
    );
    setBizPartner(result_bp.data[0]);
    setClientVehicleInfo(result.data);
  };

  function addLeadingZeros(num) {
    var totalLength = num.toString().length + 3;
    return String(num).padStart(totalLength, "100");
  }

  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="main_table">
      <div className="container p-0">
        <div className="row">
          <div className="col">
            <b className="toUpperCase custom_font_25px margin_right_10px">
              {companyProfile.name}
            </b>
            ({companyProfile.business_registration_no})
          </div>
        </div>
        <div className="row">
          <div className="col">{companyProfile.address}</div>
        </div>
        <div className="row">
          <div className="col">
            {companyProfile.postcode} {companyProfile.city}
          </div>
        </div>
        <div className="row">
          <div className="col">{companyProfile.state}</div>
        </div>
        <div className="row row-cols-auto">
          <div className="col">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td>Office No</td>
                  <td className="pe-4">: {companyProfile.phone_number_1}</td>
                  <td>Tel No 1</td>
                  <td>: {companyProfile.phone_number_2}</td>
                </tr>
                <tr>
                  <td>E-mail To</td>
                  <td className="pe-4">: {companyProfile.email}</td>
                  <td>Tel No 2</td>
                  <td>: {companyProfile.phone_number_3}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="break_line"></div>
        </div>
        <div className="row">
          <div className="col">
            {(bizPartner.over_the_counter === "true") === true && (
              <table className="padding-0">
                <tbody>
                  <tr>
                    <td className="width_5_em">
                      <b>Name</b>
                    </td>
                    <td>
                      <div className="d-flex">
                        :&nbsp;
                        <span>{bizPartner.name}</span>
                      </div>
                    </td>
                  </tr>
                  {bizPartner.address !== "" && (
                    <tr>
                      <td className="align-top">
                        <b>Address</b>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex">
                          :&nbsp;
                          <span>{bizPartner.address}</span>
                        </div>
                      </td>
                    </tr>
                  )}
                  {bizPartner.phone_number_1 !== "" && (
                    <tr>
                      <td>
                        <b>Tel No 1</b>
                      </td>
                      <td>: {bizPartner.phone_number_1}</td>
                    </tr>
                  )}
                  {bizPartner.phone_number_2 !== "" && (
                    <tr>
                      <td>
                        <b>Tel No 2</b>
                      </td>
                      <td>: {bizPartner.phone_number_2}</td>
                    </tr>
                  )}
                  {bizPartner.phone_number_3 !== "" && (
                    <tr>
                      <td>
                        <b>Tel No 3</b>
                      </td>
                      <td>: {bizPartner.phone_number_3}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {(bizPartner.over_the_counter === "true") !== true && (
              <table className="padding-0">
                <tbody>
                  <tr>
                    <td className="width_5_em">
                      <b>Name</b>
                    </td>
                    <td>
                      <div className="d-flex">
                        :&nbsp;
                        <span>{name}</span>
                      </div>
                    </td>
                  </tr>
                  {address !== null && (
                    <tr>
                      <td className="align-top">
                        <b>Address</b>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex">
                          :&nbsp;
                          <span>{address}</span>
                        </div>
                      </td>
                    </tr>
                  )}
                  {phone_number_1 !== null && (
                    <tr>
                      <td>
                        <b>Tel No 1</b>
                      </td>
                      <td>: {phone_number_1}</td>
                    </tr>
                  )}
                  {phone_number_2 !== null && (
                    <tr>
                      <td>
                        <b>Tel No 2</b>
                      </td>
                      <td>: {phone_number_2}</td>
                    </tr>
                  )}
                  {phone_number_3 !== null && (
                    <tr>
                      <td>
                        <b>Tel No 3</b>
                      </td>
                      <td>: {phone_number_3}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="col-3 text-end">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <b className="custom_font_20px">DEBIT NOTE</b>
                  </td>
                </tr>
                <tr>
                  <td className="text-start">
                    <b>No:</b>
                  </td>
                  <td>
                    DN {debit_notes ? addLeadingZeros(debit_notes[0].dn_id) : 0}
                  </td>
                </tr>
                <tr>
                  <td className="text-start">
                    <b>Date:</b>
                  </td>
                  <td>
                    {debit_notes
                      ? format(parseISO(debit_notes[0].created), "dd/MM/yyyy")
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <table className="table-bordered border border-2 border-dark text-center padding-0">
              <thead>
                <tr>
                  <th className="w_10">
                    <b>Item</b>
                  </th>
                  <th>
                    <b>Particulars</b>
                  </th>
                  <th className="w_15">
                    <b>Amount(RM)</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Motor Cover Note */}
                {motor_Cover_Note !== null && (
                  <tr className="border-bottom-0">
                    <td className="align-top pt-3">1</td>
                    <td className="text-start ps-3 pt-3">
                      <table className="padding-0">
                        <tbody>
                          <tr>
                            <td className="width_9_em">Cover Note No:</td>
                            <td>{motor_Cover_Note}</td>
                          </tr>
                          <tr>
                            <td>Vehicle No:</td>
                            <td>{vehicle_no}</td>
                          </tr>
                          <tr>
                            <td>Cover Type:</td>
                            <td>{motor_Select_Cover_Type}</td>
                          </tr>
                          <tr>
                            <td>Sum Insured:</td>
                            <td>
                              RM &nbsp;
                              {premium_Sum_Insured
                                ? numberWithCommas(
                                    parseFloat(premium_Sum_Insured).toFixed(2)
                                  )
                                : 0}
                            </td>
                          </tr>
                          <tr>
                            {premium_Extra_Benefits_Total > 0 && (
                              <td className="align-top">
                                Extra Benfits Cover:
                              </td>
                            )}
                            <td className="pe-5">
                              {premium_Extra_Benefits_Windscreen_Cover_Total >
                                0 && (
                                <span>
                                  Windscreen Cover (RM
                                  {premium_Extra_Benefits_Windscreen_Cover_Value
                                    ? numberWithCommas(
                                        parseFloat(
                                          premium_Extra_Benefits_Windscreen_Cover_Value
                                        ).toFixed(2)
                                      )
                                    : 0}
                                  )
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Legal_Liability_to_Passengers_Total >
                                0 && (
                                <span>
                                  Legal Liability to Passengers
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Act_of_Negligence_Total >
                                0 && (
                                <span>
                                  Legal Liability of Passengers for Acts of
                                  Negligence <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Strike_Commotion_Cover_Total >
                                0 && (
                                <span>
                                  Strike, Riot & Civil Commotion Cover <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_All_Drivers_Total > 0 && (
                                <span>
                                  All Drivers (Organization Policy Only) <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_All_Drivers_Inv_Total >
                                0 && (
                                <span>
                                  All Drivers (Individual Policy Only) <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Convulsion_of_Nature_Total >
                                0 && (
                                <span>
                                  Convulsion of Nature <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Additional_Driver_Total >
                                0 && (
                                <span>
                                  Additional Driver
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_RadioCasette_Total >
                                0 && (
                                <span>
                                  Radio Casette/Tape Recorder/Air Conditioner
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Insurance_Protection_Total >
                                0 && (
                                <span>
                                  Insurance Protection for Learner Drivers
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Inclusion_of_Damage_Total >
                                0 && (
                                <span>
                                  Inclusion of Damage by Overturning <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Inclusion_of_Accidental_Total >
                                0 && (
                                <span>
                                  Inclusion of Accidental Damage to the Boom
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Extension_of_Cover_Total >
                                0 && (
                                <span>
                                  Extension of Cover to the Kingdom of Thailand
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Generali_MCPA_Total >
                                0 && (
                                <span>
                                  Generali MCPA / MPA
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Unlimited_Towing_Total >
                                0 && (
                                <span>
                                  Unlimted Towing
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Other_1_Total > 0 && (
                                <span>
                                  Other ({premium_Extra_Benefits_Other_1_Value})
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Other_2_Total > 0 && (
                                <span>
                                  Other ({premium_Extra_Benefits_Other_2_Value})
                                  <br />
                                </span>
                              )}

                              {premium_Extra_Benefits_Other_3_Total > 0 && (
                                <span>
                                  Other ({premium_Extra_Benefits_Other_3_Value})
                                  <br />
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-end pe-3 pb-4">
                              <b>Motor Premium</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="align-bottom pb-4 text-end pe-3">
                      <b>
                        {premium_Total_Motor_Premium
                          ? numberWithCommas(
                              parseFloat(premium_Total_Motor_Premium).toFixed(2)
                            )
                          : 0}
                      </b>
                    </td>
                  </tr>
                )}
                {/* End of Motor Cover Note */}

                {/* Non Motor Cover Note */}
                {non_motor_Cover_Note !== null && (
                  <tr className="border-top-0 border-bottom-0">
                    <td className="align-top pt-3">
                      {motor_Cover_Note !== null ? 2 : 1}
                    </td>
                    <td className="text-start ps-3 pt-3">
                      <table className="padding-0">
                        <tbody>
                          <tr>
                            <td className="width_9_em">Cover Note No:</td>
                            <td>{non_motor_Cover_Note}</td>
                          </tr>
                          <tr>
                            <td>Sum Insured</td>
                            <td>
                              RM &nbsp;
                              {non_motor_Sum_Insured_RM
                                ? numberWithCommas(
                                    parseFloat(
                                      non_motor_Sum_Insured_RM
                                    ).toFixed(2)
                                  )
                                : 0}
                            </td>
                          </tr>
                          <tr>
                            <td className="align-top">Description:</td>
                            <td>{non_motor_Description}</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-end pe-3 pb-4">
                              <b>Non Motor Premium</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="align-bottom pb-4 text-end pe-3">
                      <b>
                        {non_motor_Total_Premium
                          ? numberWithCommas(
                              parseFloat(non_motor_Total_Premium).toFixed(2)
                            )
                          : 0}
                      </b>
                    </td>
                  </tr>
                )}
                {/* End of Non Motor Cover Note */}

                {/* Sup Svc */}
                {sup_svc_Total_Charges > 0 && (
                  <tr className="border-top-0">
                    <td className="align-top">
                      {(() => {
                        if (
                          motor_Cover_Note !== null &&
                          non_motor_Cover_Note !== null
                        ) {
                          return <div>3</div>;
                        } else if (
                          motor_Cover_Note === null &&
                          non_motor_Cover_Note === null
                        ) {
                          return <div>1</div>;
                        } else {
                          return <div>2</div>;
                        }
                      })()}
                    </td>
                    <td className="text-start ps-3">
                      <table className="padding-0">
                        <tbody>
                          <tr>
                            <td className="width_9_em">SS No:</td>
                            <td>
                              SSN
                              {ssn ? addLeadingZeros(ssn[0].ss_id) : 0}
                            </td>
                          </tr>
                          {(() => {
                            if (
                              motor_Cover_Note === null &&
                              non_motor_Cover_Note !== null &&
                              sup_svc_Road_Tax_Charges > 0
                            ) {
                              return (
                                <tr>
                                  <td className="width_9_em">Vehicle No:</td>
                                  <td>{vehicle_no}</td>
                                </tr>
                              );
                            } else if (
                              motor_Cover_Note === null &&
                              non_motor_Cover_Note === null
                            ) {
                              return (
                                <tr>
                                  <td className="width_9_em">Vehicle No:</td>
                                  <td>{vehicle_no}</td>
                                </tr>
                              );
                            }
                          })()}
                          <tr>
                            <td className="align-top">Description:</td>
                            <td className="pe-5">
                              {sup_svc_Road_Tax_Charges > 0 && (
                                <span>
                                  Road Tax
                                  <br />
                                  JPJ Matters: ROAD TAX RENEWAL CHARGE (
                                  {sup_svc_Road_Tax_Months}M)
                                  <br />
                                </span>
                              )}
                              {sup_svc_Others_1_Charges > 0 && (
                                <span>
                                  Others : {sup_svc_Others_1}
                                  <br />
                                </span>
                              )}
                              {sup_svc_Others_2_Charges > 0 && (
                                <span>
                                  Others : {sup_svc_Others_2}
                                  <br />
                                </span>
                              )}
                              {sup_svc_Others_3_Charges > 0 && (
                                <span>
                                  Others : {sup_svc_Others_3}
                                  <br />
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-end pe-3 pb-4">
                              <b>Support Services Premium</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="align-bottom pb-4 text-end pe-3">
                      <b>
                        {sup_svc_Total_Charges
                          ? numberWithCommas(
                              parseFloat(sup_svc_Total_Charges).toFixed(2)
                            )
                          : 0}
                      </b>
                    </td>
                  </tr>
                )}
                {/* End of Sup Svc */}
                <tr>
                  <td colSpan={2} className="text-end p-2 pe-3">
                    <b>Grand Total RM</b>
                  </td>
                  <td className="text-end pe-3">
                    <b>
                      {billing_Subtotal
                        ? numberWithCommas(
                            parseFloat(billing_Subtotal).toFixed(2)
                          )
                        : 0}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            This is a computer generated document. Signature is not required.
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            For Online Banking, kindly remit payment to :-
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <table className="padding-0">
              <tbody>
                <tr>
                  <td className="w_15">Bank Name:</td>
                  <td>
                    <b>{companyProfile.bank_name}</b>
                  </td>
                </tr>
                <tr>
                  <td>Acct Name:</td>
                  <td>
                    <b>{companyProfile.account_name}</b>
                  </td>
                </tr>
                <tr>
                  <td>Bank No.:</td>
                  <td>
                    <b>{companyProfile.bank_no}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
}
