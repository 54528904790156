import axios from "axios";
import { format, formatISO, parseISO } from "date-fns";
import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import "../Enquires/ExistingEnquires/datatable.css";

export default function CustRecord() {
  const [custRecord, setCustRecord] = useState([]);
  const [dialogTitle, setdialogTitle] = useState([]);
  const [addCustRecordModal, setAddCustRecordModal] = useState(false);
  const dt = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const toast = useRef(null);
  const formRef = useRef();
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [loading, setLoading] = useState(true);

  const [crTotal, setCRTotal] = useState({
    road_tax: 0,
    service_charge: 0,
  });

  // Start of Search filter Value
  const [dateStart, setDateStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [dateEnd, setDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Load Data to Datatable
  useEffect(() => {
    loadCustRecord();
    initFilters1();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadCustRecord();
    initFilters1();
    // eslint-disable-next-line
  }, [dateStart, dateEnd]);

  const loadCustRecord = async () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/cust_record/get_all_cust_record`, {
        params: {
          dateStart: formatISO(dateStart),
          dateEnd: formatISO(dateEnd),
        },
      })
      .then((result) => {
        let result_data = result.data;
        let total_road_tax = 0;
        let total_service_charge = 0;

        for (let i = 0; i < result_data.length; i++) {
          result_data[i].created = format(
            parseISO(result_data[i].created),
            "dd/MM/yyyy"
          );

          total_road_tax += parseFloat(result_data[i].road_tax);
          total_service_charge += parseFloat(result_data[i].service_charge);

          result_data[i].road_tax = numberWithCommas(result_data[i].road_tax);
          result_data[i].service_charge = numberWithCommas(
            result_data[i].service_charge
          );
        }
        setCRTotal({
          road_tax: numberWithCommas(total_road_tax),
          service_charge: numberWithCommas(total_service_charge),
        });

        setCustRecord(result_data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // End of Load Data to Datatable

  // Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  // Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // Filter Datatable Global
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value.trim();

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  // Initialize Filter Values
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      test: {
        value: null,
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue1("");
  };

  // Datatable Header
  const header = (
    <div>
      <div className="overflow-auto mb-3">
        {/* Start of 1st Row */}
        <div className="container ms-0 ps-0">
          <div className="row row-cols-auto mt-3">
            <div className="d-flex col-12">
              <Calendar
                id="icon"
                showIcon
                iconPos="left"
                showButtonBar
                className="width_12_em"
                inputClassName="padding-left"
                clearButtonClassName="d-none"
                dateFormat="dd/mm/yy"
                name="dateStart"
                value={dateStart}
                onChange={(e) => {
                  setDateStart(e.value);
                }}
              />
              <span className="d-flex align-self-center px-2">to</span>
              <Calendar
                id="icon"
                showIcon
                iconPos="left"
                showButtonBar
                className="width_12_em"
                inputClassName="padding-left"
                clearButtonClassName="d-none"
                dateFormat="dd/mm/yy"
                name="dateEnd"
                value={dateEnd}
                onChange={(e) => {
                  setDateEnd(e.value);
                }}
              />
            </div>
          </div>
        </div>
        {/* End of 1st Row */}
      </div>
      <div className="table-header">
        <Button
          label="New"
          icon="pi pi-plus"
          className="p-button-success p-button-rounded p-button-sm mx-0 my-1"
          onClick={() => {
            openAddCustRecordModal();
            reset();
            setdialogTitle("Add Customer Record");
          }}
        />
        <div className="table-header-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Keyword Search"
            />
          </span>

          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
            onClick={exportCSV}
          />
        </div>
      </div>
    </div>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={8}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={crTotal.road_tax} />
        <Column footer={crTotal.service_charge} />
        <Column />
        <Column />
      </Row>
    </ColumnGroup>
  );

  // Modal's Footer
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Save"
          icon="pi pi-check"
          type="submit"
          form="form1"
          ref={formRef}
        />
      </div>
    );
  };

  // Datatable action column field
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success me-2"
          onClick={() => {
            reset();
            editCustRecord(rowData);
            setdialogTitle("Update CustRecord Record");
          }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setValue("login", rowData.login);
            setValue("cr_id", rowData.cr_id);
            deleteCustRecord();
          }}
        />
      </div>
    );
  };

  // Open Modal for Add Record
  function openAddCustRecordModal() {
    setAddCustRecordModal(!addCustRecordModal);
  }

  // Form's Default Value
  const defaultValues = {
    login: "",
    pass: "",
    email: "",
    phone_number: "",
    siri: "",
    plate: "",
    bank: "AM",
    road_tax: "",
    service_charge: "",
    security_questions: "Malaysia",
  };

  // Form's Controller
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  // Submit Form
  const onSubmit = async (data) => {
    // Post Method
    if (data.cr_id === undefined) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/cust_record/create_new_cust_record`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        const err = response?.data.error;
        if (err === undefined) {
          loadCustRecord();
          openAddCustRecordModal();
          toast.current.show({
            severity: "success",
            summary: "Customer Record Created",
            detail: (
              <span>
                Added Customer Record Number:-&nbsp;
                <b>"{getValues("login")}"</b>
              </span>
            ),
            life: 3000,
          });
          reset();
        } else {
          toast.current.show({
            severity: "error",
            summary: "Failed to Create Customer Record",
            detail: (
              <span>
                <b>{err}</b>
              </span>
            ),
            life: 3000,
          });
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to create Customer's Record",
        });
      }
    }

    // Put Method
    else {
      try {
        // eslint-disable-next-line
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/cust_record/update_id_cust_record/${data.cr_id}`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        loadCustRecord();
        openAddCustRecordModal();
        toast.current.show({
          severity: "success",
          summary: "Customer Record Updated",
          detail: (
            <span>
              Updated Customer Record:-&nbsp;
              <b>"{getValues("login")}"</b>
            </span>
          ),
          life: 3000,
        });
        setTimeout(() => {
          reset();
        }, 200);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to update Customer's Record",
        });
      }
    }
  };

  // Form's Controller for validation
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  // Edit Vehicle Record
  const editCustRecord = async (custRecord) => {
    setValue("login", custRecord.login);
    setValue("pass", custRecord.pass);
    setValue("email", custRecord.email);
    setValue("phone_number", custRecord.phone_number);
    setValue("siri", custRecord.siri);
    setValue("plate", custRecord.plate);
    setValue("bank", custRecord.bank);
    setValue("security_questions", custRecord.security_questions);
    setValue("road_tax", custRecord.road_tax);
    setValue("service_charge", custRecord.service_charge);
    setValue("cr_id", custRecord.cr_id);
    openAddCustRecordModal();
  };

  // Delete Vehicle Record

  // onClick "Yes" button
  const accept = async () => {
    try {
      // eslint-disable-next-line
      const response = await axios.delete(
        `${
          process.env.REACT_APP_API_URL
        }/cust_record/delete_id_cust_record/${getValues("cr_id")}`,
        JSON.stringify(),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      loadCustRecord();
      toast.current.show({
        severity: "info",
        summary: "Customer Record Deleted",
        detail: (
          <span>
            Deleted Customer Record:-&nbsp;
            <b>"{getValues("login")}"</b>
          </span>
        ),
        life: 3000,
      });
      reset();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Failed to delete Customer's Record",
      });
    }
  };

  // onClick "No" button
  const reject = () => {
    reset();
  };

  // Open Delete Vehicle Modal
  const deleteCustRecord = () => {
    confirmDialog({
      message: (
        <span>
          Do you want to delete Customer Record:-&nbsp;
          <b>"{getValues("login")}"</b>
        </span>
      ),
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  // End of Delete Vehicle Record

  return (
    <section className="pt-2 pb-5 bg-theme">
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Customer Records</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={custRecord}
              loading={loading}
              dataKey="cr_id"
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              onPage={onCustomPage}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              filters={filters1}
              header={header}
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              size="small"
              globalFilterFields={[
                "login",
                "pass",
                "email",
                "phone_number",
                "siri",
                "plate",
                "bank",
                "security_questions",
                "road_tax",
                "service_charge",
                "created",
              ]}
              footerColumnGroup={footerGroup}
            >
              <Column header="Login" field="login" sortable></Column>
              <Column field="pass" header="Pass" sortable></Column>
              <Column field="email" header="E-mail" sortable></Column>
              <Column field="phone_number" header="Phone" sortable></Column>
              <Column field="siri" header="Siri" sortable></Column>
              <Column field="plate" header="Plate" sortable></Column>
              <Column field="bank" header="Bank" sortable></Column>
              <Column
                field="security_questions"
                header="Security"
                sortable
              ></Column>
              <Column field="road_tax" header="Road Tax" sortable></Column>
              <Column
                field="service_charge"
                header="Service Charge"
                sortable
              ></Column>
              <Column field="created" header="Created" sortable></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                exportable={false}
                style={{ width: "10%" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="form1">
        <Dialog
          onKeyUp={(e) => {
            const ENTER = 13;

            if (e.keyCode === ENTER) {
              e.preventDefault();
              formRef.current.click();
            }
          }}
          header={dialogTitle}
          visible={addCustRecordModal}
          style={{ width: "35vw" }}
          onHide={openAddCustRecordModal}
          footer={renderFooter()}
        >
          <div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="login"
                  control={control}
                  rules={{ required: "Login is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="login"
                  className={classNames({ "p-error": errors.name })}
                >
                  Login *
                </label>
              </span>
              {getFormErrorMessage("login")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="pass"
                  control={control}
                  rules={{ required: "Pass is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="pass"
                  className={classNames({ "p-error": errors.name })}
                >
                  Pass *
                </label>
              </span>
              {getFormErrorMessage("pass")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address. E.g. example@email.com",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="email">E-mail</label>
              </span>
              {getFormErrorMessage("email")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phone_number"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phone_number">Phone Number</label>
              </span>
              {getFormErrorMessage("phone_number")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="siri"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="siri">Siri</label>
              </span>
              {getFormErrorMessage("siri")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="plate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="plate">Plate</label>
              </span>
              {getFormErrorMessage("plate")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="bank"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="bank">Bank</label>
              </span>
              {getFormErrorMessage("bank")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="road_tax"
                  control={control}
                  rules={{ required: "Road Tax is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="road_tax">Road Tax *</label>
              </span>
              {getFormErrorMessage("road_tax")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="service_charge"
                  control={control}
                  rules={{ required: "Service Charge is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="service_charge">Service Charge *</label>
              </span>
              {getFormErrorMessage("service_charge")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="security_questions"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="security_questions">Security Questions</label>
              </span>
              {getFormErrorMessage("security_questions")}
            </div>
          </div>
        </Dialog>
      </form>
    </section>
  );
}
