import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  // const roles = localStorage.getItem("Roles");
  // const roles = auth.roles;

  const roles = localStorage
    .getItem("Roles")
    .replace("[", "")
    .replace("]", "")
    .split(",");

  return roles.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
