import axios from "axios";
import { format, formatISO, parseISO } from "date-fns";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Row } from "primereact/row";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function RoadTaxByType() {
  // Start of datatable props
  const [roadTax, setRoadTax] = useState([]);
  const dt = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [chargesTotal, setChargesTotal] = useState(0);
  // End of datatable props

  // Start of Search filter Value
  const [dateStart, setDateStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 1)
  );
  const [dateEnd, setDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)
  );
  const buttonSearch = useRef(null);
  const [lazyState, setlazyState] = useState({
    sortField: "sup_svc_Datepicker_7",
    sortOrder: -1,
  });
  // End of Search filter Value

  // Start of numberWithCommas function
  function numberWithCommas(x) {
    let number = parseFloat(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // End of numberWithCommas function

  // Start of onSort -> set value and retrigger get method
  const onSort = (event) => {
    setlazyState(event);
  };

  useEffect(() => {
    loadReport();
    // eslint-disable-next-line
  }, [lazyState]);
  // End of onSort -> set value and retrigger get method

  // Start of Load Data to Datatable with filter search
  useEffect(() => {
    loadReport();
    // eslint-disable-next-line
  }, [first, rows]);

  const loadReport = async () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/reports/road_tax_by_type`, {
        params: {
          page: page,
          size: rows,
          dateStart: formatISO(dateStart),
          dateEnd: formatISO(dateEnd),
          sort: `${lazyState.sortField},${
            lazyState.sortOrder === -1 ? "desc" : "asc"
          }`,
        },
      })
      .then((result) => {
        let result_data = result.data.content;
        let totalCharges = 0;
        for (let i = 0; i < result_data.length; i++) {
          totalCharges += parseFloat(result_data[i].sup_svc_Road_Tax_Charges);
          result_data[i].sup_svc_Road_Tax_Charges = numberWithCommas(
            result_data[i].sup_svc_Road_Tax_Charges
          );

          result_data[i].sup_svc_Datepicker_7 = format(
            parseISO(result_data[i].sup_svc_Datepicker_7),
            "dd/MM/yyyy"
          );
        }
        setChargesTotal(numberWithCommas(totalCharges));
        setRoadTax(result_data);
        setTotalRecords(result.data.totalElements);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // End of Load Data to Datatable

  // Start of funtion on key press enter and reset search function
  const handleKeyPress = useCallback((event) => {
    if (event.key === "Enter") {
      buttonSearch.current.click();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const resetSearch = () => {
    window.location.reload();
  };
  // End of funtion on key press enter and reset search function

  // Start of Datatable Options
  // -- Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // -- Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  // -- Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of{" "}
          {options.totalRecords}
        </span>
      );
    },
  };

  // -- Datatable Header
  const header = (
    <div className="overflow-auto">
      {/* Start of 1st Row */}
      <div className="container ms-0 ps-0">
        <div className="row row-cols-auto mt-3">
          <div className="d-flex col-12">
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="dateStart"
              value={dateStart}
              onChange={(e) => {
                setDateStart(e.value);
              }}
            />
            <span className="d-flex align-self-center px-2">to</span>
            <Calendar
              id="icon"
              showIcon
              iconPos="left"
              showButtonBar
              className="width_12_em"
              inputClassName="padding-left"
              clearButtonClassName="d-none"
              dateFormat="dd/mm/yy"
              name="dateEnd"
              value={dateEnd}
              onChange={(e) => setDateEnd(e.value)}
            />
          </div>
        </div>
      </div>
      {/* End of 1st Row */}
      {/* Start of 2nd Row */}
      <div className="table-header mt-3">
        <div className="table-header-2">
          <div className="container ms-0 ps-0">
            <Button
              label="Search"
              icon="pi pi-search-plus"
              className="search_clear_button me-4"
              onClick={loadReport}
              ref={buttonSearch}
            />
            <Button
              label="Clear"
              icon="pi pi-replay"
              className="search_clear_button mt-2"
              onClick={resetSearch}
            />
          </div>
        </div>
        <div>
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button width_8_em"
            onClick={exportCSV}
          />
        </div>
      </div>
      {/* End of 2nd Row */}
    </div>
  );

  // -- Datatable Footer
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={8}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={chargesTotal} />
      </Row>
    </ColumnGroup>
  );

  // -- Datatable Column Header
  let roadTaxGroup = (
    <ColumnGroup>
      <Row>
        <Column
          field="vehicle_no"
          header="Vehicle No."
          sortable
          rowSpan={2}
        ></Column>
        <Column
          field="motor_Make_Model"
          header="Make/Model"
          sortable
          rowSpan={2}
        ></Column>
        <Column field="motor_CC" header="CC" rowSpan={2}></Column>
        <Column
          field="motor_Type_Of_Body"
          header="Body"
          sortable
          rowSpan={2}
        ></Column>
        <Column
          field="motor_Select_Class"
          header="Class"
          sortable
          rowSpan={2}
        ></Column>
        <Column field="motor_Year" header="Year" sortable rowSpan={2}></Column>
        <Column header="Road Tax" colSpan={3} />
      </Row>
      <Row>
        <Column field="sup_svc_Datepicker_7" header="Issued" sortable />
        <Column field="sup_svc_Road_Tax_Months" header="Months" />
        <Column field="sup_svc_Road_Tax_Charges" header="Charges" />
      </Row>
    </ColumnGroup>
  );

  // - Start of Datatable custom body template for
  const vehicleBody = (rowData) => {
    let id = rowData.cv_id;
    let vn = rowData.vehicle_no;
    return (
      <React.Fragment>
        <Link to={`/eias/enquires/${id}/view`} target={"_blank"}>
          {vn ? vn : ""}
        </Link>
      </React.Fragment>
    );
  };

  // End of Datatable Options

  return (
    <section className="pt-2 pb-5 bg-theme">
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Road Tax by Type</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              dataKey="v_id"
              ref={dt}
              value={roadTax}
              lazy={true}
              loading={loading}
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              header={header}
              onPage={onCustomPage}
              onSort={onSort}
              sortField={lazyState.sortField}
              sortOrder={lazyState.sortOrder}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              size="small"
              headerColumnGroup={roadTaxGroup}
              footerColumnGroup={footerGroup}
            >
              <Column
                field="vehicle_no"
                header="Vehicle No."
                body={vehicleBody}
                sortable
              ></Column>
              <Column
                field="motor_Make_Model"
                header="Make/Model"
                sortable
              ></Column>
              <Column field="motor_CC" header="CC" sortable></Column>
              <Column
                field="motor_Type_Of_Body"
                header="Body"
                sortable
              ></Column>
              <Column
                field="motor_Select_Class"
                header="Class"
                sortable
              ></Column>
              <Column field="motor_Year" header="Year" sortable></Column>
              <Column field="sup_svc_Datepicker_7" header="Issued" sortable />
              <Column
                field="sup_svc_Road_Tax_Months"
                header="Months"
                sortable
              />
              <Column
                field="sup_svc_Road_Tax_Charges"
                header="Charges"
                sortable
              />
            </DataTable>
          </div>
        </div>
      </div>
    </section>
  );
}
