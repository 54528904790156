import axios from "axios";
import "primeicons/primeicons.css";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import "../Enquires/ExistingEnquires/datatable.css";

export default function Principal() {
  const [principal, setPrincipal] = useState([]);
  const [dialogTitle, setdialogTitle] = useState([]);
  const [addPrincipalModal, setAddPrincipalModal] = useState(false);
  const dt = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const toast = useRef(null);
  const formRef = useRef();
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [loading, setLoading] = useState(true);

  // Load Data to Datatable
  useEffect(() => {
    loadPrincipal();
    initFilters1();
  }, []);

  const loadPrincipal = async () => {
    setLoading(true);
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/principal/get_all_principal_with_total`
    );
    setPrincipal(result.data);
    setLoading(false);
  };

  // End of Load Data to Datatable

  // Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  // Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  // Filter Datatable Global
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value.trim();

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  // Initialize Filter Values
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  // Datatable Header
  const header = (
    <div className="table-header">
      <Button
        label="New"
        icon="pi pi-plus"
        className="p-button-success p-button-rounded p-button-sm mx-0 my-1"
        onClick={() => {
          openAddPrincipalModal();
          reset();
          setdialogTitle("Add Principal Record");
        }}
      />
      <div className="table-header-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>

        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
          onClick={exportCSV}
        />
      </div>
    </div>
  );

  // Modal's Footer
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Save"
          icon="pi pi-check"
          type="submit"
          form="form1"
          ref={formRef}
        />
      </div>
    );
  };

  // Datatable action column field
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success me-2"
          onClick={() => {
            reset();
            editPrincipal(rowData);
            setdialogTitle("Update Principal Record");
          }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setValue("code", rowData.code);
            setValue("p_id", rowData.p_id);
            deletePrincipal();
          }}
        />
      </div>
    );
  };

  const totalOutstandingTemplate = (rowData) => {
    let outstanding = rowData.total_outstanding;
    let principal_code = rowData.code;
    return (
      <React.Fragment>
        <Link to={`/eias/settings/principal_payment/${principal_code}`}>
          {outstanding}
        </Link>
      </React.Fragment>
    );
  };

  // Open Modal for Add Record
  function openAddPrincipalModal() {
    setAddPrincipalModal(!addPrincipalModal);
  }

  // Dropdown Options
  const p_status_select = [{ name: "Enabled" }, { name: "Disabled" }];

  // Form's Default Value
  const defaultValues = {
    code: "",
    name: "",
    address: "",
    agency_name: "",
    agency_code: "",
    phone_number_1: "",
    phone_number_2: "",
    phone_number_3: "",
    email: "",
    tariff_motor: 0,
    tariff_non_motor: 0,
    p_status: "Enabled",
  };

  // Form's Controller
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  // Submit Form
  const onSubmit = async (data) => {
    // Post Method
    if (data.p_id === undefined) {
      try {
        // eslint-disable-next-line
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/principal/create_new_principal`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        loadPrincipal();
        openAddPrincipalModal();
        toast.current.show({
          severity: "success",
          summary: "Principal Created",
          detail: (
            <span>
              Added Principal Number:-&nbsp;
              <b>"{getValues("code").toLocaleUpperCase()}"</b>
            </span>
          ),
          life: 3000,
        });
        reset();
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to create Principal's Record",
        });
      }
    }

    // Put Method
    else {
      try {
        // eslint-disable-next-line
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/principal/update_id_principal/${data.p_id}`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        loadPrincipal();
        openAddPrincipalModal();
        toast.current.show({
          severity: "success",
          summary: "Principal Updated",
          detail: (
            <span>
              Updated Principal Number:-&nbsp;
              <b>"{getValues("code").toLocaleUpperCase()}"</b>
            </span>
          ),
          life: 3000,
        });
        setTimeout(() => {
          reset();
        }, 200);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to update Principal's Record",
        });
      }
    }
  };

  // Form's Controller for validation
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  // Edit Vehicle Record
  const editPrincipal = async (principalRecord) => {
    setValue("code", principalRecord.code);
    setValue("name", principalRecord.name);
    setValue("address", principalRecord.address);
    setValue("agency_name", principalRecord.agency_name);
    setValue("agency_code", principalRecord.agency_code);
    setValue("phone_number_1", principalRecord.phone_number_1);
    setValue("phone_number_2", principalRecord.phone_number_2);
    setValue("phone_number_3", principalRecord.phone_number_3);
    setValue("email", principalRecord.email);
    setValue("tariff_motor", principalRecord.tariff_motor);
    setValue("tariff_non_motor", principalRecord.tariff_non_motor);
    setValue("p_status", principalRecord.p_status);
    setValue("p_id", principalRecord.p_id);
    openAddPrincipalModal();
  };

  // Delete Vehicle Record

  // onClick "Yes" button
  const accept = async () => {
    try {
      // eslint-disable-next-line
      const response = await axios.delete(
        `${
          process.env.REACT_APP_API_URL
        }/principal/delete_id_principal/${getValues("p_id")}`,
        JSON.stringify(),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      loadPrincipal();
      toast.current.show({
        severity: "info",
        summary: "Principal Deleted",
        detail: (
          <span>
            Deleted Principal:-&nbsp;
            <b>"{getValues("code").toLocaleUpperCase()}"</b>
          </span>
        ),
        life: 3000,
      });
      reset();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Failed to delete Principal's Record",
      });
    }
  };

  // onClick "No" button
  const reject = () => {
    reset();
  };

  // Open Delete Vehicle Modal
  const deletePrincipal = () => {
    confirmDialog({
      message: (
        <span>
          Do you want to delete Principal:-&nbsp;
          <b>"{getValues("code").toLocaleUpperCase()}"</b>
        </span>
      ),
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  // End of Delete Vehicle Record

  return (
    <section className="pt-2 pb-5 bg-theme">
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Principal Management</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={principal}
              loading={loading}
              dataKey="p_id"
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              onPage={onCustomPage}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              filters={filters1}
              header={header}
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              size="small"
            >
              <Column field="code" header="Code" filter sortable></Column>
              <Column
                field="agency_name"
                header="Agency Name"
                sortable
              ></Column>
              <Column
                field="agency_code"
                header="Agency Code"
                sortable
              ></Column>
              <Column
                body={totalOutstandingTemplate}
                field="total_outstanding"
                header="Oustanding Amount (RM)"
                sortable
              ></Column>
              <Column field="phone_number_1" header="HP 1" sortable></Column>
              <Column field="p_status" header="Status" sortable></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                exportable={false}
                style={{ width: "10%" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="form1">
        <Dialog
          onKeyUp={(e) => {
            const ENTER = 13;

            if (e.keyCode === ENTER) {
              e.preventDefault();
              formRef.current.click();
            }
          }}
          header={dialogTitle}
          visible={addPrincipalModal}
          style={{ width: "35vw" }}
          onHide={openAddPrincipalModal}
          footer={renderFooter()}
        >
          <div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="code"
                  control={control}
                  rules={{ required: "Code is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="code"
                  className={classNames({ "p-error": errors.name })}
                >
                  Code *
                </label>
              </span>
              {getFormErrorMessage("code")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Name is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="name"
                  className={classNames({ "p-error": errors.name })}
                >
                  Name *
                </label>
              </span>
              {getFormErrorMessage("name")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="address">Address</label>
              </span>
              {getFormErrorMessage("address")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="agency_name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="agency_name">Agency Name</label>
              </span>
              {getFormErrorMessage("agency_name")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="agency_code"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="agency_code">Agency Code</label>
              </span>
              {getFormErrorMessage("agency_code")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phone_number_1"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phone_number_1">Phone Number 1</label>
              </span>
              {getFormErrorMessage("phone_number_1")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phone_number_2"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phone_number_2">Phone Number 2</label>
              </span>
              {getFormErrorMessage("phone_number_2")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phone_number_3"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phone_number_3">Phone Number 3</label>
              </span>
              {getFormErrorMessage("phone_number_3")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address. E.g. example@email.com",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="email">E-mail</label>
              </span>
              {getFormErrorMessage("email")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="tariff_motor"
                  control={control}
                  rules={{
                    required: "Tariff Motor is required.",
                    min: 0,
                    max: 100,
                  }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                      ref={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      mode="decimal"
                      minFractionDigits={2}
                      showButtons
                      suffix=" %"
                      min={0}
                      max={100}
                    />
                  )}
                />
                <label
                  htmlFor="tariff_motor"
                  className={classNames({ "p-error": errors.name })}
                >
                  Tariff Motor *
                </label>
              </span>
              {getFormErrorMessage("tariff_motor")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="tariff_non_motor"
                  control={control}
                  rules={{ required: "Tariff Non Motor is required." }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                      ref={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      mode="decimal"
                      minFractionDigits={2}
                      showButtons
                      suffix=" %"
                      min={0}
                      max={100}
                    />
                  )}
                />
                <label
                  htmlFor="tariff_non_motor"
                  className={classNames({ "p-error": errors.name })}
                >
                  Tariff Non Motor *
                </label>
              </span>
              {getFormErrorMessage("tariff_non_motor")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="p_status"
                  control={control}
                  rules={{ required: "Status is required." }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      options={p_status_select}
                      optionLabel="name"
                      optionValue="name"
                      id={field.name}
                      {...field}
                      className={classNames("width_full", "padding-normal", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="p_status"
                  className={classNames({ "p-error": errors.name })}
                >
                  Status *
                </label>
              </span>
              {getFormErrorMessage("p_status")}
            </div>
          </div>
        </Dialog>
      </form>
    </section>
  );
}
