import axios from "axios";
import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "../Enquires/ExistingEnquires/datatable.css";

export default function Client() {
  const [client, setClient] = useState([]);
  const [dialogTitle, setdialogTitle] = useState([]);
  const [addClientModal, setAddClientModal] = useState(false);
  const dt = useRef(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const toast = useRef(null);
  const formRef = useRef();
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [loading, setLoading] = useState(true);

  // Load Data to Datatable
  useEffect(() => {
    loadClient();
    initFilters1();
  }, []);

  const loadClient = async () => {
    setLoading(true);
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/get_all_clients`
    );
    setClient(result.data);
    setLoading(false);
  };

  // End of Load Data to Datatable

  // Export Datatable to CSV
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  // Get Datatable Page
  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  // Datatable Template
  const template = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Show:
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of{" "}
          {options.totalRecords}
        </span>
      );
    },
  };

  // Filter Datatable Global
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value.trim();

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  // Initialize Filter Values
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      test: {
        value: null,
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue1("");
  };

  // Datatable Header
  const header = (
    <div className="table-header">
      <Button
        label="New"
        icon="pi pi-plus"
        className="p-button-success p-button-rounded p-button-sm mx-0 my-1"
        onClick={() => {
          openAddClientModal();
          reset();
          setdialogTitle("Add Client Record");
        }}
      />
      <div className="table-header-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>

        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help ms-2 p-button-sm p-button-rounded align-baseline custom_button"
          onClick={exportCSV}
        />
      </div>
    </div>
  );

  // Modal's Footer
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Save"
          icon="pi pi-check"
          type="submit"
          form="form1"
          ref={formRef}
        />
      </div>
    );
  };

  // Datatable action column field
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success me-2"
          onClick={() => {
            reset();
            editClient(rowData);
            setdialogTitle("Update Client Record");
          }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setValue("name", rowData.name);
            setValue("c_id", rowData.c_id);
            deleteClient();
          }}
        />
      </div>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.name.toUpperCase()} ({rowData.nric})
      </div>
    );
  };

  // Open Modal for Add Record
  function openAddClientModal() {
    setAddClientModal(!addClientModal);
  }

  // Form's Default Value
  const defaultValues = {
    name: "",
    nric: "",
    address: "",
    phone_number_1: "",
    phone_number_2: "",
    phone_number_3: "",
    email: "",
  };

  // Form's Controller
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  // Submit Form
  const onSubmit = async (data) => {
    // Post Method
    if (data.c_id === undefined) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/clients/create_new_clients`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        const err = response?.data.error;
        if (err === undefined) {
          loadClient();
          openAddClientModal();
          toast.current.show({
            severity: "success",
            summary: "Client Created",
            detail: (
              <span>
                Added Client Number:-&nbsp;
                <b>"{getValues("name").toLocaleUpperCase()}"</b>
              </span>
            ),
            life: 3000,
          });
          reset();
        } else {
          toast.current.show({
            severity: "error",
            summary: "Failed to Create Client",
            detail: (
              <span>
                <b>{err}</b>
              </span>
            ),
            life: 3000,
          });
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to create Client's Record",
        });
      }
    }

    // Put Method
    else {
      try {
        // eslint-disable-next-line
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/clients/update_id_clients/${data.c_id}`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        loadClient();
        openAddClientModal();
        toast.current.show({
          severity: "success",
          summary: "Client Updated",
          detail: (
            <span>
              Updated Client Number:-&nbsp;
              <b>"{getValues("name").toLocaleUpperCase()}"</b>
            </span>
          ),
          life: 3000,
        });
        setTimeout(() => {
          reset();
        }, 200);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Failed to update Client's Record",
        });
      }
    }
  };

  // Form's Controller for validation
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  // Edit Vehicle Record
  const editClient = async (clientRecord) => {
    setValue("name", clientRecord.name);
    setValue("nric", clientRecord.nric);
    setValue("address", clientRecord.address);
    setValue("phone_number_1", clientRecord.phone_number_1);
    setValue("phone_number_2", clientRecord.phone_number_2);
    setValue("phone_number_3", clientRecord.phone_number_3);
    setValue("email", clientRecord.email);
    setValue("c_id", clientRecord.c_id);
    openAddClientModal();
  };

  // Delete Vehicle Record

  // onClick "Yes" button
  const accept = async () => {
    try {
      // eslint-disable-next-line
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/clients/delete_id_clients/${getValues(
          "c_id"
        )}`,
        JSON.stringify(),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      loadClient();
      toast.current.show({
        severity: "info",
        summary: "Client Deleted",
        detail: (
          <span>
            Deleted Client:-&nbsp;
            <b>"{getValues("name").toLocaleUpperCase()}"</b>
          </span>
        ),
        life: 3000,
      });
      reset();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Failed to delete Client's Record",
      });
    }
  };

  // onClick "No" button
  const reject = () => {
    reset();
  };

  // Open Delete Vehicle Modal
  const deleteClient = () => {
    confirmDialog({
      message: (
        <span>
          Do you want to delete Client:-&nbsp;
          <b>"{getValues("name").toLocaleUpperCase()}"</b>
        </span>
      ),
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  // End of Delete Vehicle Record

  return (
    <section className="pt-2 pb-5 bg-theme">
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="card m-4 bg-theme-light">
        <div className="card-body">
          <div className="card-title">
            <h2 className="text-start">Client Management</h2>
          </div>
          <div className="card-text text-start">
            <DataTable
              ref={dt}
              value={client}
              loading={loading}
              dataKey="c_id"
              paginator
              paginatorTemplate={template}
              first={first}
              rows={rows}
              onPage={onCustomPage}
              paginatorClassName="justify-content-end"
              responsiveLayout="scroll"
              filters={filters1}
              header={header}
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              size="small"
              globalFilterFields={[
                "name",
                "nric",
                "phone_number_1",
                "phone_number_2",
                "phone_number_3",
                "email",
              ]}
            >
              <Column
                header="Name (NRIC)"
                body={nameBodyTemplate}
                sortable
              ></Column>
              <Column field="phone_number_1" header="HP 1" sortable></Column>
              <Column field="phone_number_2" header="HP 2" sortable></Column>
              <Column field="phone_number_3" header="HP 3" sortable></Column>
              <Column field="email" header="E-mail" sortable></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                exportable={false}
                style={{ width: "10%" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="form1">
        <Dialog
          onKeyUp={(e) => {
            const ENTER = 13;

            if (e.keyCode === ENTER) {
              e.preventDefault();
              formRef.current.click();
            }
          }}
          header={dialogTitle}
          visible={addClientModal}
          style={{ width: "35vw" }}
          onHide={openAddClientModal}
          footer={renderFooter()}
        >
          <div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Name is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="name"
                  className={classNames({ "p-error": errors.name })}
                >
                  Name *
                </label>
              </span>
              {getFormErrorMessage("name")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="nric"
                  control={control}
                  rules={{ required: "NRIC is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="nric"
                  className={classNames({ "p-error": errors.name })}
                >
                  NRIC *
                </label>
              </span>
              {getFormErrorMessage("nric")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="address">Address</label>
              </span>
              {getFormErrorMessage("address")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phone_number_1"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phone_number_1">Phone Number 1</label>
              </span>
              {getFormErrorMessage("phone_number_1")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phone_number_2"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phone_number_2">Phone Number 2</label>
              </span>
              {getFormErrorMessage("phone_number_2")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="phone_number_3"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="phone_number_3">Phone Number 3</label>
              </span>
              {getFormErrorMessage("phone_number_3")}
            </div>
            <div className="field mt-4">
              <span className="p-float-label">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address. E.g. example@email.com",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames("width_full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label htmlFor="email">E-mail</label>
              </span>
              {getFormErrorMessage("email")}
            </div>
          </div>
        </Dialog>
      </form>
    </section>
  );
}
