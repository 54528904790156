import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link, Outlet } from "react-router-dom";
import ClientVehicleInfoContext from "../../context/ClientVehicleProvider";
import "./navbar.css";

export default function NavbarLayout() {
  const { resetState } = useContext(ClientVehicleInfoContext);

  const handleReset = () => {
    resetState();
  };

  return (
    <React.Fragment>
      <Navbar bg="dark" expand="lg" variant="dark">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <FontAwesomeIcon icon="car" className="me-2" />
            MYEIAS
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">
                <FontAwesomeIcon icon="house-chimney" className="me-1" />
                HOME
              </Nav.Link>

              <NavDropdown
                title={
                  <span>
                    <FontAwesomeIcon icon="briefcase" className="me-1" />
                    ENQUIRES
                  </span>
                }
                id="basic-nav-dropdown"
                menuVariant="dark"
                variant="secondary"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/eias/enquires/create"
                  onClick={handleReset}
                >
                  New Client/Vehicle
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/eias/enquires/existing_client_vehicle"
                >
                  Existing Client/Vehicle
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/">
                  Quick Quotation
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/settings">
                <FontAwesomeIcon icon="envelope" className="me-1" />
                REMINDERS
              </Nav.Link>

              <NavDropdown
                title={
                  <span>
                    <FontAwesomeIcon icon="file-lines" className="me-1" />
                    REPORTS
                  </span>
                }
                id="basic-nav-dropdown"
                menuVariant="dark"
                variant="secondary"
              >
                <NavDropdown.Item as={Link} to="/eias/reports/saved_draft">
                  Saved Draft
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/reports/road_tax_by_type">
                  Road Tax By Type
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/reports/production">
                  Production
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/eias/reports/supporting_services"
                >
                  Supporting Services
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/eias/reports/submission_receipts"
                >
                  Submission Receipts
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/eias/reports/collection_by_business_partner"
                >
                  Collection By Business Partner
                </NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/eias/reports/total_collection">
                  Total Collection
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/eias/reports/total_other_payments"
                >
                  Total Other Payments
                </NavDropdown.Item> */}
                <NavDropdown.Item as={Link} to="/eias/reports/debit_notes">
                  Debit Notes
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/reports/credit_notes">
                  Credit Notes
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/reports/statement">
                  Statement
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={
                  <span>
                    <FontAwesomeIcon icon="tools" className="me-1" />
                    SETTINGS
                  </span>
                }
                id="basic-nav-dropdown"
                menuVariant="dark"
                variant="secondary"
              >
                <NavDropdown.Item as={Link} to="/eias/settings/vehicle">
                  Vehicles
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/eias/settings/business_partners_view"
                >
                  Business Partners
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/settings/principals_view">
                  Principals
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/settings/clients">
                  Clients
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/settings/cust_record">
                  Customer Records
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/settings/users">
                  Users
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/settings/my_profile">
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/eias/settings/company_profile">
                  Company Profile
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/logout">
                <FontAwesomeIcon icon="sign-out" className="me-1" />
                LOGOUT
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet></Outlet>
    </React.Fragment>
  );
}
