import React, { useEffect, useRef, useState, useContext } from "react";
import { useImmer } from "use-immer";
import axios from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import "./Login.css";
import { useLocation, useNavigate } from "react-router-dom";

const LOGIN_URL = "/login";

export default function Login() {
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();
  localStorage.clear();

  const [user, setUser] = useImmer({
    company_code: "",
    email: "admin@test.com",
    password: "test123",
  });

  const [errMsg, setErrMsg] = useState("");

  const { company_code, email, password } = user;

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password }, { withCredentials: true })
      );
      console.log(JSON.stringify(response?.data));
      const accessToken = response?.data?.auth.token;
      const refreshToken = response?.data?.auth.refreshToken;
      const roles = response?.data?.role
        .replace("[", "")
        .replace("]", "")
        .split(", ");

      // console.log(roles);
      setAuth({ email, password, roles, accessToken });
      setUser({ ...user });
      console.log(accessToken, roles);
      // navigate(from, { replace: true });
      localStorage.setItem("Token", accessToken);
      localStorage.setItem("Roles", roles);
      localStorage.setItem("refreshToken", refreshToken);
      navigate("/home");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    // Section: Design Block
    <section className="pt-5 pb-5 background-radial-gradient">
      <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
        <div className="row gx-lg-5 align-items-center mb-5">
          <div className="col-lg-6 mb-5 mb-lg-0 div_z-index">
            <h1 className="my-5 display-5 fw-bold ls-tight h1_color">
              The best offer <br />
              <span className="span_color">for your business</span>
            </h1>
            <p className="mb-4 opacity-70 p_color">
              Copyright © MyEIAS 2021
              <br></br>Privacy Policy · Terms & Conditions
            </p>
          </div>

          <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
            <div
              id="radius-shape-1"
              className="position-absolute rounded-circle shadow-5-strong"
            ></div>
            <div
              id="radius-shape-2"
              className="position-absolute shadow-5-strong"
            ></div>

            <div className="card bg-glass">
              <div className="card-body px-4 py-5 px-md-5">
                <h1 className="text-center mb-4">Login</h1>
                <p
                  className="text-center mb-4 fw-bold text-danger"
                  ref={errRef}
                >
                  {errMsg}
                </p>
                <div className="box">
                  <form onSubmit={handleSubmit}>
                    <div className="inputBox">
                      <input
                        type="text"
                        name="company_code"
                        // required
                        // onKeyUp="this.setAttribute('value', this.value);"
                        value={company_code}
                        onChange={(e) => onInputChange(e)}
                      />
                      <label>Company Code</label>
                    </div>
                    <div className="inputBox">
                      <input
                        type="text"
                        name="email"
                        ref={userRef}
                        required
                        value={email}
                        onChange={(e) => onInputChange(e)}
                      />
                      <label>E-mail</label>
                    </div>
                    <div className="inputBox">
                      <input
                        type="password"
                        name="password"
                        required
                        value={password}
                        onChange={(e) => onInputChange(e)}
                      />
                      <label>Password</label>
                    </div>
                    <div>
                      <label className="forget_password">
                        Forget Password?
                      </label>
                      <input
                        type="submit"
                        name="sign-in"
                        value="Sign In"
                        className="align-center"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // Section: Design Block
  );
}
