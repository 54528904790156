import React, { createContext, useState } from "react";

const ClientVehicleInfoContext = createContext({});

export const ClientVehicleProvider = ({ children }) => {
  const [clientVehicleInfo, setClientVehicleInfo] = useState({
    // Clients Detail
    name: "",
    nric: "",
    address: "",
    phone_number_1: "",
    phone_number_2: "",
    email: "",
    phone_number_3: "",
    select_ins_principal: "LIBERTY",
    select_biz_partner: "YST",
    vehicle_no: "",

    // Motor Detail
    motor_Cover_Note: "",
    motor_Datepicker_1: new Date(),
    motor_Select_Action: "NEW",
    motor_Datepicker_2: new Date(),
    motor_Datepicker_3: new Date(
      new Date().setDate(new Date().getDate() + 364)
    ),
    motor_Select_Cover_Type: "COMPREHENSIVE",
    motor_Partner_Commission_Rate: "0",
    motor_Select_Class: "PRIVATE CAR",
    motor_CC: "",
    motor_Year: "",
    motor_Seating: "",
    motor_Principal_Tariff: "0",
    motor_Make_Model: "",
    motor_Type_Of_Body: "",
    motor_HP_Co: "",
    motor_Remarks: "",

    // Non-Motor Detail
    non_motor_Cover_Note: "",
    non_motor_Datepicker_4: new Date(),
    non_motor_Select_Action: "NEW",
    non_motor_Datepicker_5: new Date(),
    non_motor_Datepicker_6: new Date(
      new Date().setDate(new Date().getDate() + 364)
    ),
    non_motor_Class_of_Ins: "",
    non_motor_Principal_Tariff: "0.00",
    non_motor_Partner_Commission: "0.00",
    non_motor_Description: "",
    non_motor_RM_Per_Room: "0.00",
    non_motor_Sum_Insured_RM: "0.00",
    non_motor_Basic_Premium: "0.00",
    non_motor_Govt_Tax: "8",
    non_motor_Total: "0.00",
    non_motor_Stamp_Duty: "0.00",
    non_motor_Remarks: "",
    non_motor_Other_Charges: "0.00",
    non_motor_Total_Premium: "0.00",
    non_motor_Principal_Commission: "0.00",
    non_motor_Gross_Premium: "0.00",

    // Supporting Services
    sup_svc_Datepicker_7: new Date(),
    sup_svc_Road_Tax_Months: "12",
    sup_svc_Road_Tax_Charges: "0.00",
    sup_svc_Road_Tax_JPJ_Runner: "0.00",
    sup_svc_Road_Tax_Revenue: "0.00",
    sup_svc_License_Years: "1",
    sup_svc_License_Charges: "0.00",
    sup_svc_License_JPJ_Runner: "0.00",
    sup_svc_License_Revenue: "0.00",
    sup_svc_JPJ_Renewal: "ROAD TAX RENEWAL CHARGE",
    sup_svc_JPJ_Renewal_Charges: "0.00",
    sup_svc_JPJ_Renewal_JPJ_Runner: "0.00",
    sup_svc_JPJ_Renewal_Revenue: "0.00",
    sup_svc_Others_1: "",
    sup_svc_Others_1_Charges: "0.00",
    sup_svc_Others_1_JPJ_Runner: "0.00",
    sup_svc_Others_1_Revenue: "0.00",
    sup_svc_Others_2: "",
    sup_svc_Others_2_Charges: "0.00",
    sup_svc_Others_2_JPJ_Runner: "0.00",
    sup_svc_Others_2_Revenue: "0.00",
    sup_svc_Others_3: "",
    sup_svc_Others_3_Charges: "0.00",
    sup_svc_Others_3_JPJ_Runner: "0.00",
    sup_svc_Others_3_Revenue: "0.00",
    sup_svc_Total_Charges: "0.00",
    sup_svc_Total_JPJ_Runner: "0.00",
    sup_svc_Total_Revenue: "0.00",

    // Premium
    // Motor Premium
    premium_Sum_Insured: "0",
    premium_Excess: "0",
    premium_Basic_Premium: "0.00",
    premium_Trailer_Schedule_Premium: "0.00",
    premium_All_Rider_Percentage: "0",
    premium_All_Rider: "0.00",
    premium_Sub_Total_1: "0.00",
    premium_Loading_Charge_Percentage: "0",
    premium_Loading_Charge: "0.00",
    premium_Sub_Total_2: "0.00",
    premium_NCD_Percentage: "0",
    premium_NCD: "0.00",
    premium_Extra_Benefits: "0.00",
    premium_Service_Tax_Percentage: "8",
    premium_Service_Tax: "0.00",
    premium_Stamp_Duty: "10.00",
    premium_Transfer_Fees: "0.00",
    premium_Total_Motor_Premium: "0.00",

    // Supporting Services Premium
    premium_Roadtax_Total: "0.00",
    premium_License_Total: "0.00",
    premium_JPJ_Renewal: "0.00",
    premium_Others_Total: "0.00",
    premium_Total_Supporting_Services: "0.00",

    // Total Amount Payable
    premium_Total_Amount_Payable: "0.00",
    premium_Gross_Premium: "0.00",
    premium_Principal_Commission: "0.00",
    premium_Net_Premium: "0.00",

    //Extra Benefits
    premium_Extra_Benefits_Windscreen_Cover_Value: "0.00",
    premium_Extra_Benefits_Windscreen_Cover_Total: "0.00",
    premium_Extra_Benefits_Legal_Liability_to_Passengers_Checkbox: "",
    premium_Extra_Benefits_Legal_Liability_to_Passengers_Value: "0",
    premium_Extra_Benefits_Legal_Liability_to_Passengers_Total: "0.00",
    premium_Extra_Benefits_Act_of_Negligence_Checkbox: "",
    premium_Extra_Benefits_Act_of_Negligence_Total: "0.00",
    premium_Extra_Benefits_Strike_Commotion_Cover_Checkbox: "",
    premium_Extra_Benefits_Strike_Commotion_Cover_Total: "0.00",
    premium_Extra_Benefits_All_Drivers_Checkbox: "",
    premium_Extra_Benefits_All_Drivers_Total: "0.00",
    premium_Extra_Benefits_All_Drivers_Inv_Checkbox: "",
    premium_Extra_Benefits_All_Drivers_Inv_Total: "0.00",
    premium_Extra_Benefits_Convulsion_of_Nature_Checkbox: "",
    premium_Extra_Benefits_Convulsion_of_Nature_Total: "0.00",
    premium_Extra_Benefits_Additional_Driver_Named: "",
    premium_Extra_Benefits_Additional_Driver_Total: "0.00",
    premium_Extra_Benefits_RadioCasette_Value: "0.00",
    premium_Extra_Benefits_RadioCasette_Total: "0.00",
    premium_Extra_Benefits_Insurance_Protection_Total: "0.00",
    premium_Extra_Benefits_Inclusion_of_Damage_Total: "0.00",
    premium_Extra_Benefits_Inclusion_of_Accidental_Total: "0.00",
    premium_Extra_Benefits_Extension_of_Cover_Total: "0.00",
    premium_Extra_Benefits_Generali_MCPA_Total: "0.00",
    premium_Extra_Benefits_Unlimited_Towing_Total: "0.00",
    premium_Extra_Benefits_Other_1_Value: "",
    premium_Extra_Benefits_Other_1_Total: "0.00",
    premium_Extra_Benefits_Other_2_Value: "",
    premium_Extra_Benefits_Other_2_Total: "0.00",
    premium_Extra_Benefits_Other_3_Value: "",
    premium_Extra_Benefits_Other_3_Total: "0.00",
    premium_Extra_Benefits_Total: "0.00",

    //Billing
    billing_Motor_Premium: "0.00",
    billing_Discount: "0.00",
    billing_non_motor_Premium: "0.00",
    billing_Discount_Non_Motor: "0.00",
    billing_Supporting_Services: "0.00",
    billing_Subtotal: "0.00",
    billing_Subtotal_Discount: "0.00",
    billing_Total_Payable: "0.00",
    orpayment: "",
    status: "Draft",
    get_status: "Draft",
    ssn: [],
    third_party_premium: "0.00",
  });
  return (
    <ClientVehicleInfoContext.Provider
      value={{ clientVehicleInfo, setClientVehicleInfo }}
    >
      {children}
    </ClientVehicleInfoContext.Provider>
  );
};

export default ClientVehicleInfoContext;
