import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React from "react";

export default function Home() {
  return (
    <section className="pt-2 pb-5">
      <img src={require("./a.jpg")} alt="Home Icon" />
    </section>
  );
}
